import { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { IoArrowBackOutline } from "react-icons/io5";
import { LuEye } from "react-icons/lu";
import { Link, useSearch } from "react-location";
import Button from "@/components/buttons/button";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import businessIdeaImage from "@/assets/images/bro.png";
import { setAuth, useAppNaviagte } from "@/apollo/cache/auth";
import * as Yup from "yup";
import { GoogleAuthProvider, auth, signInWithPopup } from "@/config/firebase";
import { useFormik } from "formik";
import Show from "@/components/core/show";
import { BarLoader } from "react-spinners";
import ButtonLoader from "@/components/loaders/button";
import {
  LOGIN_USER_SOCIAL,
  SIGNUP_USER,
} from "@/apollo/graphql/mutations/auth";
import { LocationGenerics } from "@/router/location";
import {
  AuthType,
  CreateCandidateAccountMutation,
  CreateCandidateAccountMutationVariables,
  SocialAuthLoginMutation,
  SocialAuthLoginMutationVariables,
} from "@/apollo/graphql/generated/types";

const Signup = () => {
  useEffect(() => {
    document.title = "Talent | Sign up";
  }, []);

  const search = useSearch<LocationGenerics>();
  const navigate = useAppNaviagte();

  const [eyeClick, setEyeClick] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [socialAuthLoading, setSocialAuthLoading] = useState(false);

  const [signup, { loading }] = useMutation<
    CreateCandidateAccountMutation,
    CreateCandidateAccountMutationVariables
  >(SIGNUP_USER);
  const [socialAuth, { loading: authenticating }] = useMutation<
    SocialAuthLoginMutation,
    SocialAuthLoginMutationVariables
  >(LOGIN_USER_SOCIAL);

  const { handleSubmit, ...signupForm }: any = useFormik({
    initialValues: {
      email: "",
      password: "",
      fullName: "",
      termsChecked: false,
    },
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required("Fullname is required"),
      email: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be 8 characters or more")
        .required("Password is required"),
      termsChecked: Yup.bool().oneOf(
        [true],
        "You must accept the terms and conditions"
      ),
    }),
    onSubmit: async (values) => {
      await signup({
        variables: {
          input: {
            acceptedTermsAndConditions: values.termsChecked,
            email: values.email,
            fullName: values.fullName,
            password: values.password,
          },
        },
      })
        .then(({ data }) => {
          if (data?.createCandidateAccount?.token) {
            toast(
              JSON.stringify({
                type: "success",
                title: "Account created successfully",
              })
            );
            setAuth({
              token: data?.createCandidateAccount?.token,
              user: data?.createCandidateAccount,
            });

            const redirectTo = search?.redirect
              ? `/onboarding/?redirect=${search?.redirect}`
              : "/onboarding";

            navigate({ replace: true, to: redirectTo });
          }
        })
        .catch((error) => {
          toast(
            JSON.stringify({
              type: "error",
              title:
                error?.message || "An error occurred while create the account",
            })
          );
        });
    },
  });
  //

  const handleSocialAuth = async (authType: string) => {
    try {
      setSocialAuthLoading(true);

      const provider: any =
        authType === "Google" ? new GoogleAuthProvider() : null;

      const googleUser: any = await signInWithPopup(auth, provider);

      await socialAuth({
        variables: {
          input: {
            acceptedTermsAndConditions: true,
            email: googleUser.user.email,
            fullName: googleUser.user.displayName,
            password: googleUser.user.uid,
            avatar: googleUser.user.photoURL,
            authType: AuthType.Google,
          },
        },
      })
        .then(({ data }) => {
          setSocialAuthLoading(false);
          if (data?.socialAuthLogin?.token) {
            const { user, token } = data.socialAuthLogin;
            setAuth({
              token,
              user: user,
            });
            toast(
              JSON.stringify({
                type: "success",
                title: "Account created successfully",
              })
            );

            const redirectTo = search?.redirect
              ? `/onboarding/?redirect=${search?.redirect}`
              : "/onboarding";

            return navigate({ replace: true, to: redirectTo });
          }
        })
        .catch((err) => {
          setSocialAuthLoading(false);
          toast(
            JSON.stringify({
              type: "error",
              title:
                err?.message ||
                "An error occurred while signing up with Google",
            })
          );
        });
    } catch (e) {
      setSocialAuthLoading(false);
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred while signing up with Google",
        })
      );
    }
  };

  return (
    <section className="h-full w-full select-none">
      <div className="xl:max-w-[75%] mx-auto xl:mt-8 flex px-5 py-2 justify-between items-start gap-x-[4rem]">
        <Show if={socialAuthLoading}>
          <div className="h-full max-w-[29.875rem] mx-auto flex flex-1 flex-col justify-center items-center  ">
            <BarLoader color="#02733e" />
            <p className="text-center">Signing up with google </p>
          </div>
        </Show>
        <Show if={!socialAuthLoading}>
          <div className="max-w-[32rem] mx-auto flex flex-1 items-start  gap-5">
            <Link
              to={"/"}
              className="mobile:hidden w-12 h-12 bg-[#F5F6F9] rounded-full flex justify-center cursor-pointer items-center hover:shadow-md transition-all duration-150 ease-in-out"
            >
              <IoArrowBackOutline className="" aria-hidden="true" />
            </Link>
            <div className="w-full flex-1 mobile:mt-5">
              <div className="text-center xl:text-left w-full">
                <h1 className="font-montserrat font-semibold text-2xl xl:text-3xl text-black tracking-[0.006rem] mb-2">
                  Create an account ,{" "}
                  <span className="text-primary">Talent</span>
                </h1>
                <p className="font-montserrat text-sm  leading-6 text-gray-500">
                  The First Step Towards Your Future Success
                </p>
              </div>

              <div className="mt-4">
                <div className="mb-4">
                  <label
                    className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                    htmlFor="fullname"
                  >
                    Full name *
                  </label>

                  <input
                    id="fullName"
                    name="fullName"
                    onChange={(e) =>
                      signupForm.setFieldValue("fullName", e.target.value)
                    }
                    type="text"
                    value={signupForm.values.fullName}
                    placeholder="Enter your name"
                    className="input-base text-gray-900"
                    required
                  />
                  {signupForm.errors.fullName && (
                    <p className="text-xs mt-2 text-red-500">
                      {signupForm.errors.fullName}
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                    htmlFor="email"
                  >
                    Email *
                  </label>

                  <input
                    id="email"
                    placeholder="Enter your email"
                    onChange={(e) =>
                      signupForm.setFieldValue("email", e.target.value)
                    }
                    name="email"
                    value={signupForm.values.email}
                    type="email"
                    autoComplete="email"
                    className="input-base text-gray-900"
                  />
                  {signupForm.errors.email && (
                    <p className="text-xs mt-2 text-red-500">
                      {signupForm.errors.email}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                    htmlFor="password"
                  >
                    Password *
                  </label>
                  <div className="flex items-center justify-between w-full input-base overflow-hidden">
                    <input
                      type={eyeClick ? "text" : "password"}
                      id="password"
                      placeholder="Enter your password"
                      onChange={(e) =>
                        signupForm.setFieldValue("password", e.target.value)
                      }
                      name="password"
                      value={signupForm.values.password}
                      autoComplete="current-password"
                      className="border-none outline-none bg-transparent w-full input-base text-gray-900"
                    />

                    <div
                      className="cursor-pointer"
                      onClick={() => setEyeClick(!eyeClick)}
                    >
                      {eyeClick ? (
                        <LuEye className="w-4 h-4" />
                      ) : (
                        <AiOutlineEyeInvisible className="w-4 h-4" />
                      )}
                    </div>
                  </div>
                  {signupForm.errors.password && (
                    <p className="text-xs mt-2 text-red-500">
                      {signupForm.errors.password}
                    </p>
                  )}
                  <div className="mt-3">
                    <p className="font-medium font-manrope text-sm">
                      Must be at least 8 characters
                    </p>
                  </div>
                </div>

                <div className="flex items-center mt-6 mb-4 ">
                  <input
                    onChange={() => {
                      signupForm.setFieldValue("termsChecked", !termsChecked);
                      setTermsChecked(!termsChecked);
                    }}
                    type="checkbox"
                    checked={termsChecked}
                    className="peer mr-2 cursor-pointer text-primary rounded border border-gray-500 focus:ring-0"
                    required
                  />
                  <label
                    className="text-black text-xs leading-[0.9rem] font-manrope font-[400]"
                    htmlFor="remember"
                  >
                    I agree to the{" "}
                    <a href="/terms" target="_blank" className="text-primary">
                      Terms and Conditions
                    </a>
                  </label>
                </div>

                {signupForm.errors.termsChecked && (
                  <p className="text-xs mb-6 text-red-500">
                    {signupForm.errors.termsChecked}
                  </p>
                )}

                <Button
                  className="h-[3rem] rounded-lg"
                  width="full"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <ButtonLoader title="Creating account..." />
                  ) : (
                    "Create an account"
                  )}
                </Button>
                <div className="w-full flex items-center gap-x-2 my-2">
                  <div
                    style={{ content: "" }}
                    className="w-full h-[1px] bg-gray-200"
                  />
                  <p className="text-grey-600 font-[400] font-manrope text-sm">
                    OR
                  </p>
                  <div
                    style={{ content: "" }}
                    className="w-full h-[1px] bg-gray-200"
                  />
                </div>

                <div className="w-full mt-6 mb-6">
                  <button
                    onClick={() => handleSocialAuth("Google")}
                    className="w-full font-manrope flex justify-center items-center gap-x-2 shadow-sm rounded-lg border bg-white border-grey-300 py-3 text-base font-[400] text-grey-700 leading-6"
                  >
                    <FcGoogle className="w-6 h-6 text-center" />
                    Sign up with Google
                  </button>
                </div>

                <p className="text-center text-grey-600 text-sm leading-5 font-[400] font-manrope">
                  Already have an account?{" "}
                  <Link to="/login" className="text-primary">
                    Sign in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Show>

        <div className="hidden xl:flex flex-1 flex-col items-center w-[23.313rem]">
          <div className="text-center mb-6">
            <h1 className="font-montserrat font-medium  text-3xl text-center text-black  tracking-[0.006rem] mb-2">
              Seamless Registration for Endless Opportunities
            </h1>
            <p className="font-montserrat text-base text-gray-400 leading-6">
              Start Your Journey with{" "}
              <Link className="text-primary font-medium ml-1" to="/">
                Adjuma
              </Link>
            </p>
          </div>

          <img
            src={businessIdeaImage}
            alt="front picture"
            aria-hidden="true"
            className="object-contain w-full h-full"
          />
        </div>
      </div>
    </section>
  );
};

export default Signup;
