/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "@/apollo/graphql/mutations/auth";
import { useCurrentUser } from "@/apollo/cache/auth";
import toast from "react-hot-toast";
import Modal from "@/components/layouts/modal";
import { IoCalendarClearOutline } from "react-icons/io5";
import { levels } from "@/constants/regions";
import { useFormik } from "formik";
import _ from "lodash";

import ButtonLoader from "@/components/loaders/button";
import {
  ADD_REFERENCE,
  UPDATE_REFERENCE,
} from "@/apollo/graphql/mutations/profile";

const ReferenceModal = ({ visible, onClose, refetch, data }: any) => {
  const currentUser = useCurrentUser();
  const [loadingModal, setLoadingModal] = useState(false);

  useEffect(() => {
    if (data) {
      form.setValues({
        name: data?.fullName || "",
        company: data?.company || "",
        email: data?.email || "",
        phoneNumber: data?.phoneNumber || "",
        position: data?.position || "",
      });
    } else {
      form.resetForm();
    }
  }, [data]);

  const [addReference, { loading }] = useMutation(ADD_REFERENCE);
  const [updateReference, { loading: updateLoading }] =
    useMutation(UPDATE_REFERENCE);

  interface ProfessionalFormData {
    name: string;
    position: string;
    company: string;
    email: string;
    phoneNumber: string;
  }

  const form = useFormik({
    initialValues: {
      name: "",
      position: "",
      company: "",
      email: "",
      phoneNumber: "",
    },
    onSubmit: async (values: ProfessionalFormData) => {
      if (data) {
        await updateReference({
          variables: {
            input: {
              id: data?.id,
              fullName: values.name,
              company: values.company,
              email: values.email,
              phoneNumber: values.phoneNumber,
              position: values.position,
            },
          },
        })
          .then(({ data }) => {
            onClose();
            refetch();
            toast(
              JSON.stringify({
                type: "success",
                title: `Profile successfully updated`,
              })
            );
          })
          .catch((error) => {
            toast(
              JSON.stringify({
                type: "error",
                title: error?.message || "Error while updating Profile",
              })
            );
          });
        return;
      } else {
        await addReference({
          variables: {
            input: {
              fullName: values.name,
              company: values.company,
              email: values.email,
              phoneNumber: values.phoneNumber,
              position: values.position,
            },
          },
        })
          .then(({ data }) => {
            form.resetForm();
            onClose();
            refetch();
            toast(
              JSON.stringify({
                type: "success",
                title: `Profile successfully updated`,
              })
            );
          })
          .catch((error) => {
            toast(
              JSON.stringify({
                type: "error",
                title: error?.message || "Error while updating Profile",
              })
            );
          });
      }
    },
  });

  if (!visible) return null;

  return (
    <Modal
      open={visible}
      setOpen={() => {
        onClose();
      }}
      loading={loadingModal}
      hideActions={false}
      description="Reference"
      renderActions={() => (
        <>
          <button
            disabled={loading}
            className=" w-[8rem] h-[2.5rem] flex justify-center items-center font-montserrat font-normal
                 text-sm capitalize leading-5 text-center
                  bg-primary rounded-lg text-white
                  border border-primary "
            type="button"
            onClick={() => {
              form.handleSubmit();
            }}
          >
            {loading || updateLoading ? (
              <ButtonLoader title="Saving..." />
            ) : (
              " Save changes"
            )}
          </button>
        </>
      )}
    >
      {currentUser?.id ? (
        <>
          <div className="relative bg-white mt-3">
            {/* inputs come here */}

            <div className="w-full">
              <div className="mb-3">
                <label
                  className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.006rem]"
                  htmlFor="name"
                >
                  Name
                </label>

                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={form.handleChange}
                  value={form.values?.name || ""}
                  className="input-base"
                  placeholder="Enter Reference's Name"
                />
              </div>
              <div className="mb-3">
                <label
                  className="font-montserrat text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                  htmlFor="position"
                >
                  Position
                </label>

                <input
                  id="position"
                  name="position"
                  type="text"
                  onChange={form.handleChange}
                  value={form.values?.position || ""}
                  className="input-base"
                  placeholder="Enter Reference's Position"
                />
              </div>
              <div className="mb-3">
                <label
                  className="font-montserrat text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                  htmlFor="company"
                >
                  Company
                </label>

                <input
                  id="company"
                  name="company"
                  type="text"
                  onChange={form.handleChange}
                  value={form.values?.company || ""}
                  className="input-base"
                  placeholder="Enter Reference's Company"
                />
              </div>
              <div className="mb-3">
                <label
                  className="font-montserrat text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                  htmlFor="email"
                >
                  Email
                </label>

                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={form.handleChange}
                  value={form.values?.email || ""}
                  className="input-base"
                  placeholder="Enter Reference's Email"
                />
              </div>

              <div className="mb-3 lg:mb-2">
                <label
                  className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]"
                  htmlFor="phoneNumber"
                >
                  phone number
                </label>

                <PhoneInput
                  country={"gh"}
                  placeholder={"Mobile Number"}
                  value={form.values.phoneNumber}
                  onChange={(value: any) =>
                    form.setFieldValue("phoneNumber", value)
                  }
                  countryCodeEditable={false}
                  enableSearch={true}
                  inputProps={{
                    required: true,
                    title: "Enter your phone number here",
                    className: "input-base mt-2",
                  }}
                  inputStyle={{
                    width: "80%",
                    marginLeft: "20%",
                  }}
                  buttonStyle={{
                    width: "18%",
                    borderRadius: "0.5rem",
                    borderColor: "rgb(156 163 175)",
                    paddingInline: "0.5rem",
                    marginTop: "0.5rem",
                  }}
                  dropdownStyle={{
                    borderRadius: "0.5rem",
                    borderColor: "rgb(156 163 175)",
                    paddingInline: "1rem",
                  }}
                  searchStyle={{
                    width: "90%",
                    borderRadius: "0.5rem",
                    borderColor: "rgb(156 163 175)",
                    paddingInline: "1rem",
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6">
          <div>
            <h1>NO DATA</h1>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ReferenceModal;
