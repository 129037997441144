import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($filter: NotificationFilter, $pagination: Pagination) {
    getNotifications(filter: $filter, pagination: $pagination) {
      count
      rows {
        id
        title
        body
        read
        userType
        userId
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query GetNotification($getNotificationId: ID!) {
    getNotification(id: $getNotificationId) {
      id
      title
      body
      read
      userType
      userId
      createdAt
      updatedAt
    }
  }
`;
