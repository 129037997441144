// src/components/CountryList.tsx
import { getAllCountries } from "@/utils/country";
import React, { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

interface CountryListProps {
  selectedCountry: string;
  onCountrySelect: (country: string) => void;
}

const CountryList: React.FC<CountryListProps> = ({ selectedCountry, onCountrySelect }) => {
  const [countries, setCountries] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [query, setQuery] = useState("");

  useEffect(() => {
    const fetchCountries = async () => {
      setLoading(true);
      const result = await getAllCountries();
      if (result.length === 0) {
        setError("Failed to fetch countries");
      } else {
        setCountries(result);
      }
      setLoading(false);
    };

    fetchCountries();
  }, []);

  const filteredCountries =
    query === ""
      ? countries
      : countries.filter((country) => {
          return country.toLowerCase().includes(query.toLowerCase());
        });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="relative h-full w-full flex items-center">
      <Combobox
        value={selectedCountry}
        onChange={(value) => onCountrySelect(value)}
      >
        <div className="w-full flex justify-between items-center">
          <Combobox.Input
            placeholder="Select your country"
            className={clsx(
              "w-full border-none outline-none text-xs text-black",
              "focus:!outline-none focus:!ring-0 focus:!border-none "
            )}
            displayValue={(country: string) => country}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
          />
          <Combobox.Button className="group px-2.5">
            <ChevronDownIcon className="size-4 fill-black group-data-[hover]:fill-black" />
          </Combobox.Button>
        </div>

        <Combobox.Options
          className={clsx(
            "w-full rounded-xl border border-white/5 absolute top-10 !z-20 shadow-md bg-white max-h-60 overflow-y-scroll p-1 px-0 [--anchor-gap:var(--spacing-1)] empty:invisible",
            "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
          )}
        >
          {filteredCountries.map((country, index) => (
            <Combobox.Option
              key={index}
              value={country}
              className="w-full group flex cursor-default items-center gap-2 rounded-lg py-1.5 select-none data-[focus]:bg-black"
            >
              <CheckIcon className="invisible size-4 fill-primary text-primary group-data-[selected]:visible" />
              <div className="text-xs/6 text-black cursor-pointer">
                {country}
              </div>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </div>
  );
};

export default CountryList;
