/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";
//import user from "../../services/cookie-config";
import { update } from "lodash";

interface initialState {
  user: any;
}

const initialState: initialState = {
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.user = action.payload;
      // console.log("action", action);
      // console.log("action.payload", state);
      // if (action.payload) {
      //   console.log("action.payload", action.payload);
      //   state.user = action.payload.user;
      // }
    },
  },
});

export const { updateUser } = userSlice.actions;

export const selectUser = (state: { user: any }) => state.user.user;

export default userSlice.reducer;
