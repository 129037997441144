import { PiUserCirclePlusFill } from "react-icons/pi";
import { FC } from 'react';
import { Link } from 'react-location';

const UncompleteBanner:FC = () => {
  return (
    <div className='sticky top-0 z-10 w-full px-5 flex justify-center items-center bg-[#FFF6E6] h-16 text-sm/4 text-[#F28F16] space-x-2 border-b'>
      <PiUserCirclePlusFill className='h-6 w-6 ' />
      <p>
        Your profile is not complete, kindly{' '}
        <Link to='/profile' className='text-primary-400 underline'>
          click here
        </Link>{' '}
        to continue
      </p>
    </div>
  );
};

export default UncompleteBanner;
