import emailFooter from "@/assets/images/email-footer.png";
import logo from "@/assets/images/logo.png";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from "@/constants/socials";
import {
  DEV_LANDING_URL,
  PROD_LANDING_URL,
  TEST_LANDING_URL,
} from "@/constants/page-path";

interface Props {
  isFull: boolean;
}

export default function MiniFooter({ isFull }: Props) {
  return (
    <div
      className={`hidden md:block ${isFull ? "md:w-full" : "md:w-1/2"}  pt-5`}
    >
      <div className=" rounded-xl ring-1 ring-gray-300 p-3">
        <img src={emailFooter} alt="email-footer" />

        <div className="flex justify-start items-center w-full gap-3 mt-4 ">
          <a href={FACEBOOK}>
            <BiLogoFacebookCircle className="text-gray-400 w-6 h-6" />
          </a>
          <a href={TWITTER}>
            <FaXTwitter className="text-gray-400 w-5 h-5" />
          </a>
          <a href={INSTAGRAM}>
            <FaInstagram className="text-gray-400 w-5 h-5" />
          </a>
          <a href={LINKEDIN}>
            <FaLinkedin className="text-gray-400 w-5 h-5" />
          </a>
        </div>
      </div>

      <div className="flex flex-col gap-y-2 items-end  py-5 mt-6">
        <img src={logo} alt="logo" className="object-contain h-5 w-20 " />

        <a
          href={
            import.meta.env.VITE_NODE_ENV === "development"
              ? `${DEV_LANDING_URL}/contact`
              : import.meta.env.VITE_NODE_ENV === "production"
              ? `${PROD_LANDING_URL}/contact`
              : import.meta.env.VITE_NODE_ENV === "test"
              ? `${TEST_LANDING_URL}/contact`
              : "/nolink"
          }
          target="_blank"
          className="font-medium text-sm text-gray-400"
        >
          Contact Support
        </a>

        <div className="flex justify-end items-center w-full gap-5">
          <a href={FACEBOOK}>
            <BiLogoFacebookCircle className="text-gray-400 w-5 h-5" />
          </a>
          <a href={TWITTER}>
            <FaXTwitter className="text-gray-400 w-4 h-4" />
          </a>
          <a href={INSTAGRAM}>
            <FaInstagram className="text-gray-400 w-4 h-4" />
          </a>
          <a href={LINKEDIN}>
            <FaLinkedin className="text-gray-400 w-4 h-4" />
          </a>
        </div>

        <p className="font-medium text-sm text-gray-400">
          &copy; Copyright {new Date().getFullYear()}, Adjuma.io
        </p>
      </div>
    </div>
  );
}
