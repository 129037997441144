import { useEffect, useState } from "react";
import SwitchProps from "@/components/toggle";
import { Navigate, useNavigate } from "react-location";
import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  clearAuth,
  currentUserVar,
  useCurrentToken,
  useCurrentUser,
} from "@/apollo/cache/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Button from "@/components/buttons/button";
import { CHANGE_USER_PASSWORD } from "@/apollo/graphql/mutations/auth";
import Wrapper from "@/components/wrapper";
import ButtonLoader from "@/components/loaders/button";
import { UPDATE_SETTINGS } from "@/apollo/graphql/mutations/settings";
import {
  SettingsQuery,
  SettingsQueryVariables,
  UpdateSettingsMutation,
  UpdateSettingsMutationVariables,
  UserType,
} from "@/apollo/graphql/generated/types";
import { SETTINGS } from "@/apollo/graphql/queries/settings";
import ImageLoader from "@/components/loaders/ImageLoader";

const SettingsPage = () => {
  useEffect(() => {
    document.title = "Talent | Settings";
  }, []);
  const navigate = useNavigate();

  const user = useCurrentUser();

  const [changePassword, { loading }] = useMutation(CHANGE_USER_PASSWORD);

  const [showPassword, setShowPassword] = useState(
    user?.accountMeta?.authType === "EMAIL" && true
  );
  const [showNotification, setShowNotification] = useState(
    user?.accountMeta?.authType === "EMAIL" ? false : true
  );

  const [updatesEmail, setUpdatesEmail] = useState<any>(false);
  const [jobsEmail, setJobsEmail] = useState<any>(false);
  const [remindersEmail, setRemindersEmail] = useState<any>(false);

  const resetSettings = () => {
    setUpdatesEmail(false);
    setJobsEmail(false);
    setRemindersEmail(false);
  };

  interface FormValues {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  }

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Required"),
      newPassword: Yup.string()
        .min(8, "Must be 8 characters or more")
        .required("Required"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), undefined], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: async (values: FormValues) => {
      await changePassword({
        variables: {
          userId: user.id,
          confirmedPassword: values.confirmNewPassword,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
      }).then(({ data }) => {
        if (data) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Password updated successfully",
            })
          );
          clearAuth();
          formik.resetForm();
          navigate({
            to: "/login",
          });
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Password update failed",
            })
          );
        }
      });
    },
  });

  const {
    data,
    loading: loadingSettings,
    refetch,
  } = useQuery<SettingsQuery, SettingsQueryVariables>(SETTINGS, {
    variables: {
      userId: user?.id,
      userType: UserType.Talent,
    },
  });

  // console.log(data);

  useEffect(() => {
    if (data) {
      setUpdatesEmail(data?.settings?.updatesEmail);
      setJobsEmail(data?.settings?.jobsEmail);
      setRemindersEmail(data?.settings?.remindersEmail);
    }
  }, [data]);

  const [updateSettings, { loading: updating }] = useMutation<
    UpdateSettingsMutation,
    UpdateSettingsMutationVariables
  >(UPDATE_SETTINGS);

  const handleUpdateSettings = async () => {
    await updateSettings({
      variables: {
        input: {
          jobsEmail: jobsEmail,
          remindersEmail: remindersEmail,
          updatesEmail: updatesEmail,
          userId: user?.id,
          userType: "Talent",
          activitiesEmail: false,
          activitiesSms: false,
          jobsSms: false,
          remindersSms: false,
          updatesSms: false,
        },
      },
    })
      .then(({ data }) => {
        if (data?.updateSettings) {
          toast(
            JSON.stringify({
              type: "success",
              title: `Settings successfully updated`,
            })
          );
          refetch();
        }
      })
      .catch((error) => {
        toast(
          JSON.stringify({
            type: "error",
            title: error?.message || "Error while updating settings",
          })
        );
      });
  };

  const token = useCurrentToken();

  const isLoggedIn = user && token;

  if (!isLoggedIn) return <Navigate to="/login" replace />;

  if (loadingSettings) {
    return <ImageLoader />;
  }

  return (
    <Wrapper>
      <main className="max-w-[50rem] w-full h-full">
        <div className="w-full  mx-auto ">
          <h1
            className="font-semibold text-black text-lg leading-5 
          tracking-[0.1px] py-4 mb-4 border-b border-gray-300 mobile:px-5 px-10"
          >
            Settings
          </h1>

          <div className="mt-7 ">
            <header
              className="flex gap-x-8 border-b border-gray-200 text-sm font-normal leading-5 text-gray-500
             mobile:px-5 px-10 transition-all duration-150 ease-in-out "
            >
              {user?.accountMeta?.authType === "EMAIL" && (
                <button
                  onClick={() => {
                    setShowPassword(true);
                    setShowNotification(false);
                  }}
                  className={`font-montserrat border-b ${
                    showPassword
                      ? " border-primary text-primary"
                      : "border-transparent "
                  }  pb-3 transition-all duration-150 ease-in-out`}
                  type="button"
                >
                  Password
                </button>
              )}

              <button
                onClick={() => {
                  setShowPassword(false);
                  setShowNotification(true);
                }}
                className={`font-montserrat border-b  ${
                  showNotification
                    ? " border-primary text-primary"
                    : "border-transparent"
                }  pb-3 transition-all duration-150 ease-in-out`}
                type="button"
              >
                Notifications
              </button>
            </header>
          </div>

          {showPassword &&
            (user?.accountMeta?.authType === "EMAIL" ? (
              <form className="my-6 px-5 mobile:px-5">
                <div className="border-b border-gray-200 pb-5 mb-5">
                  <h4 className=" font-semibold text-lg text-gray-900 mb-2">
                    Password
                  </h4>
                  <p className="font-medium text-sm text-gray-500">
                    Please enter your current password to change your password.
                  </p>
                </div>

                <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-y-0 justify-between items-start border-b border-gray-200 pb-5 mb-5">
                  <h4 className="font-medium text-sm text-gray-600">
                    Current password
                  </h4>
                  <div className="w-full lg:w-[29.438rem]">
                    <input
                      type="password"
                      className="input-base lg:w-[29.438rem] text-base text-[#667085] placeholder:text-xs "
                      {...formik.getFieldProps("oldPassword")}
                      placeholder="Enter your current password"
                    />
                    {formik.touched.oldPassword && formik.errors.oldPassword ? (
                      <div className="text-red-600 text-xs mt-1">
                        {formik.errors.oldPassword}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-y-0 justify-between items-start border-b border-gray-200 pb-5 mb-5">
                  <h4 className="font-medium text-sm text-gray-600">
                    New password
                  </h4>
                  <div className="w-full lg:w-[29.438rem]">
                    <input
                      type="password"
                      className="input-base lg:w-[29.438rem] text-base text-[#667085] placeholder:text-xs "
                      {...formik.getFieldProps("newPassword")}
                      placeholder="Enter your new password"
                    />
                    {formik.touched.newPassword && formik.errors.newPassword ? (
                      <div className="text-red-600 text-xs mt-1">
                        {formik.errors.newPassword}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-y-0 justify-between items-start border-b border-gray-200 pb-5 mb-5">
                  <h4 className="font-medium text-sm text-gray-600">
                    Confirm new password
                  </h4>
                  <div className="w-full lg:w-[29.438rem]">
                    <input
                      type="password"
                      className="input-base lg:w-[29.438rem] text-base text-[#667085] placeholder:text-xs "
                      {...formik.getFieldProps("confirmNewPassword")}
                      placeholder="Confirm your new password"
                    />
                    {formik.touched.confirmNewPassword &&
                    formik.errors.confirmNewPassword ? (
                      <div className="text-red-600 text-xs mt-1">
                        {formik.errors.confirmNewPassword}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex justify-end items-center gap-x-5">
                  <button
                    className=" py-[10px] px-5 font-sans
                       text-sm capitalize leading-5 text-center
                        bg-white rounded-lg text-gray-700
                        border border-gray-300 shadow-sm "
                    type="button"
                    onClick={() => {
                      // clear formik fields
                      formik.resetForm();
                    }}
                  >
                    Cancel
                  </button>
                  <Button
                    className="!w-[12rem] h-10"
                    onClick={formik.handleSubmit}
                  >
                    {loading ? (
                      <ButtonLoader title="Updating password..." />
                    ) : (
                      "Update Password"
                    )}
                  </Button>
                </div>
              </form>
            ) : (
              <div className="w-full bg-red-500 p-6 mt-5">
                <h1 className="text-white">
                  You cannot change password since you signed up using OAuth.
                </h1>
              </div>
            ))}

          {showNotification && (
            <div className="my-6 px-5 ">
              <div className="border-b border-gray-200 pb-5 mb-5">
                <h4 className=" font-semibold text-lg text-grey-900 mb-2">
                  Notification settings
                </h4>
                <p className="font-medium text-sm leading-5  text-grey-500">
                  We may still send you important notifications about your
                  account outside of your notification settings.
                </p>
              </div>

              <div className="flex gap-x-2  justify-between items-start border-b border-grey-200 pb-5 mb-5">
                <div className="">
                  <h4 className="font-semibold text-[#344054] text-sm ">
                    Updates
                  </h4>
                  <p className="font-medium text-sm text-grey-600">
                    These are notifications for our future updates of Adjuma and
                    other exciting opportunities
                  </p>
                </div>

                <div className="flex flex-col gap-y-2">
                  {/* <div className="flex gap-x-2">
                    <SwitchProps
                      value={settings.updates.push}
                      onChange={() => handleToggle({category: "updates", type: "push"})}
                    />
                    <p className="font-[500] text-[#344054] text-sm">Push</p>
                  </div> */}

                  <div className="flex gap-x-2">
                    <SwitchProps
                      value={updatesEmail}
                      onChange={() => setUpdatesEmail(!updatesEmail)}
                    />
                    <p className="font-[500] text-[#344054] text-sm">Email</p>
                  </div>
                  {/* <div className="flex gap-x-2">
                    <SwitchProps
                      value={settings.updates.sms}
                      onChange={() =>
                        handleToggle({ category: "updates", type: "sms" })
                      }
                    />
                    <p className="font-[500] text-[#344054] text-sm">SMS</p>
                  </div> */}
                </div>
              </div>

              <div className="flex gap-x-2 justify-between items-start border-b border-grey-200 pb-5 mb-5">
                <div className="">
                  <h4 className="font-semibold text-[#344054] text-sm ">
                    Jobs
                  </h4>
                  <p className="font-medium text-sm text-grey-600">
                    These are notifications for jobs you may be interested in,
                    and related to your job searches
                  </p>
                </div>

                <div className="flex flex-col gap-y-2">
                  {/* <div className="flex gap-x-2">
                    <SwitchProps
                      value={settings.jobs.push}
                      onChange={() => handleToggle({category: "jobs", type: "push"})}
                    />
                    <p className="font-[500] text-[#344054] text-sm">Push</p>
                  </div> */}

                  <div className="flex gap-x-2">
                    <SwitchProps
                      value={jobsEmail}
                      onChange={() => setJobsEmail(!jobsEmail)}
                    />
                    <p className="font-[500] text-[#344054] text-sm">Email</p>
                  </div>
                  {/* <div className="flex gap-x-2">
                    <SwitchProps
                      value={settings.jobs.sms}
                      onChange={() =>
                        handleToggle({ category: "jobs", type: "sms" })
                      }
                    />
                    <p className="font-[500] text-[#344054] text-sm">SMS</p>
                  </div> */}
                </div>
              </div>

              <div className="flex gap-x-2 justify-between items-start border-b border-grey-200 pb-5 mb-5">
                <div className="">
                  <h4 className="font-semibold text-[#344054] text-sm ">
                    Reminders
                  </h4>
                  <p className="font-medium text-sm text-grey-600">
                    These are notifications to remind you of updates you might
                    have missed.
                  </p>
                </div>

                <div className="flex flex-col gap-y-2">
                  {/* <div className="flex gap-x-2">
                    <SwitchProps
                      value={settings.reminders.push}
                      onChange={() => handleToggle({category: "reminders", type: "push"})}
                    />
                    <p className="font-[500] text-[#344054] text-sm">Push</p>
                  </div> */}

                  <div className="flex gap-x-2">
                    <SwitchProps
                      value={remindersEmail}
                      onChange={() => setRemindersEmail(!remindersEmail)}
                    />
                    <p className="font-[500] text-[#344054] text-sm">Email</p>
                  </div>
                  {/* <div className="flex gap-x-2">
                    <SwitchProps
                      value={settings.reminders.sms}
                      onChange={() =>
                        handleToggle({ category: "reminders", type: "sms" })
                      }
                    />
                    <p className="font-[500] text-[#344054] text-sm">SMS</p>
                  </div> */}
                </div>
              </div>

              {/* <div className="flex gap-x-2 justify-between items-start border-b border-grey-200 pb-5 mb-5">
                <div className="">
                  <h4 className="font-semibold text-[#344054] text-sm ">
                    More activity about you
                  </h4>
                  <p className="font-medium text-sm text-grey-600">
                    These are notifications for your account’s activities
                  </p>
                </div>

                <div className="flex flex-col gap-y-2">
                  <div className="flex gap-x-2">
                    <SwitchProps
                      value={settings.activities.push}
                      onChange={() => handleToggle({category: "activities", type: "push"})}
                    />
                    <p className="font-[500] text-[#344054] text-sm">Push</p>
                  </div>

                  <div className="flex gap-x-2">
                    <SwitchProps
                      value={settings.activities.email}
                      onChange={() =>
                        handleToggle({ category: "activities", type: "email" })
                      }
                    />
                    <p className="font-[500] text-[#344054] text-sm">Email</p>
                  </div>
                  <div className="flex gap-x-2">
                    <SwitchProps
                      value={settings.activities.sms}
                      onChange={() =>
                        handleToggle({ category: "activities", type: "sms" })
                      }
                    />
                    <p className="font-[500] text-[#344054] text-sm">SMS</p>
                  </div>
                </div>
              </div> */}

              <div className="flex justify-end items-center gap-x-5">
                <button
                  className=" py-[10px] px-5 font-sans 
                       text-sm capitalize leading-5 text-center
                        bg-white rounded-lg text-gray-700
                        border border-gray-300 shadow-sm "
                  type="button"
                  onClick={resetSettings}
                >
                  Cancel
                </button>
                <Button
                  onClick={handleUpdateSettings}
                  className="!w-[12rem] h-10"
                >
                  {updating ? (
                    <ButtonLoader title="Updating..." />
                  ) : (
                    " Update Notifications"
                  )}
                </Button>
              </div>
            </div>
          )}
        </div>
      </main>
    </Wrapper>
  );
};

export default SettingsPage;
