/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "@/apollo/graphql/mutations/auth";
import { useMutation as useMutationTanstack } from "@tanstack/react-query";
import { useCurrentUser } from "@/apollo/cache/auth";
import { UPLOAD_FILE } from "@/helpers/upload-file";
import toast from "react-hot-toast";
import Modal from "@/components/layouts/modal";
import { IoCalendarClearOutline } from "react-icons/io5";
import { levels } from "@/constants/regions";
import { useFormik } from "formik";
import _ from "lodash";
import { ClipLoader } from "react-spinners";

import ButtonLoader from "@/components/loaders/button";
import { FaRegFilePdf } from "react-icons/fa";
import {
  ADD_PORTFOLIO,
  UPDATE_PORTFOLIO,
} from "@/apollo/graphql/mutations/profile";

//deleting

const PortfolioModal = ({ visible, onClose, refetch, data }: any) => {
  const [file, setFile] = useState({ fileName: "", url: "" });

  const currentUser = useCurrentUser();
  const [loadingModal, setLoadingModal] = useState(false);

  // console.log(data);

  useEffect(() => {
    if (data) {
      form.setValues({
        website: data?.website || "",
        resume: data?.resume || "",
      });
    } else {
      form.resetForm();
    }
  }, [data]);

  const [addPortfolio, { loading }] = useMutation(ADD_PORTFOLIO);
  const [updatePortfolio, { loading: updateLoading }] =
    useMutation(UPDATE_PORTFOLIO);

  interface ProfessionalFormData {
    website: string;
    resume: string;
  }

  const form = useFormik({
    initialValues: {
      website: "",
      resume: "",
    },
    onSubmit: async (values: ProfessionalFormData) => {
      if (data) {
        await updatePortfolio({
          variables: {
            input: {
              id: data?.id,
              website: values.website,
              resume: values.resume,
            },
          },
        })
          .then(({ data }) => {
            onClose();
            refetch();
            toast(
              JSON.stringify({
                type: "success",
                title: `Profile successfully updated`,
              })
            );
          })
          .catch((error) => {
            toast(
              JSON.stringify({
                type: "error",
                title: error?.message || "Error while updating Profile",
              })
            );
          });
        return;
      } else {
        await addPortfolio({
          variables: {
            input: {
              website: values.website,
              resume: values.resume,
            },
          },
        })
          .then(({ data }) => {
            onClose();
            refetch();
            toast(
              JSON.stringify({
                type: "success",
                title: `Profile successfully updated`,
              })
            );
          })
          .catch((error) => {
            toast(
              JSON.stringify({
                type: "error",
                title: error?.message || "Error while updating Profile",
              })
            );
          });
      }
    },
  });

  const { isLoading, mutate: uploadFile } = useMutationTanstack({
    mutationKey: ["uploadFile"],
    mutationFn: UPLOAD_FILE,
  });

  const handleUpload = async (file: any) => {
    const data = new FormData();
    data.append("file", file);
    uploadFile(data, {
      onSuccess: (data) => {
        setFile(data);
        form.setFieldValue("resume", data?.url);

        toast(
          JSON.stringify({
            type: "success",
            title: "File uploaded successfully",
          })
        );
      },
      onError: (error) => {
        toast(
          JSON.stringify({
            type: "error",
            title: "Failed to upload file",
          })
        );
      },
    });
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      try {
        await handleUpload(selectedFile);
      } finally {
      }
    }
  };

  if (!visible) return null;

  return (
    <Modal
      open={visible}
      setOpen={() => {
        onClose();
      }}
      loading={loadingModal}
      hideActions={false}
      description="Portfolio"
      renderActions={() => (
        <>
          <button
            disabled={loading}
            className=" w-[8rem] h-[2.5rem] flex justify-center items-center font-montserrat font-normal
                 text-sm capitalize leading-5 text-center
                  bg-primary rounded-lg text-white
                  border border-primary "
            type="button"
            onClick={() => {
              form.handleSubmit();
            }}
          >
            {loading ? <ButtonLoader title="Saving..." /> : " Save changes"}
          </button>
        </>
      )}
    >
      {currentUser?.id ? (
        <>
          <div className="relative bg-white mt-3">
            {/* inputs come here */}

            <div className="w-full">
              <div className="mb-3 mobile:border-none border-b border-gray-400 pb-4">
                <label
                  className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.006rem]"
                  htmlFor="website"
                >
                  Website
                </label>

                <input
                  id="website"
                  name="website"
                  type="url"
                  onChange={form.handleChange}
                  value={form.values?.website || ""}
                  className="input-base"
                  placeholder="Website.com"
                />
              </div>
              <div className="relative bg-white mt-4">
                <p
                  className="font-montserrat text-xs leading-[0.9rem] tracking-[0.1px]
                        text-black"
                >
                  CV/Resume
                </p>
                <p className="text-[10px] text-gray-400">
                  Supported formats:{" "}
                  <span className="text-primary-700">PDF only</span>
                </p>
                <div className="mt-4">
                  <div className="flex justify-between items-center h-14 mt-2 ring-1 ring-gray-300 py-2 px-2 rounded-xl mb-4">
                    {isLoading ? (
                      <div className="w-full flex gap-x-4 items-center justify-start">
                        <span className="text-gray-600 text-xs">
                          Uploading file...
                        </span>
                        <ClipLoader
                          color={"#02733E"}
                          loading={true}
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    ) : (
                      <>
                        <a
                          href={file?.url}
                          target="_blank"
                          rel="noreferrer"
                          className="font-montserrat font-normal hover:text-primary-700 hover:underline
                      text-xs text-grey-500 leading-[14.4px] transition-all duration-150 ease-in-out"
                        >
                          {file?.url && (
                            <span className="flex items-center gap-1 text-sm">
                              {" "}
                              <FaRegFilePdf /> click to view resume
                            </span>
                          )}
                        </a>
                        <div
                          className={`w-4 h-4 p-2 rounded-full border 
                    ${
                      file?.url !== ""
                        ? "bg-primary border-primary"
                        : "bg-gray-400 border-[#9CA3AF]"
                    } flex justify-center items-center`}
                        >
                          <span className="text-white text-xs font-bold ">
                            &#10003;
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <label
                    className="w-fit text-xs text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                    htmlFor="file"
                  >
                    <div className=" ">
                      <p
                        onClick={() => {
                          setFile({ fileName: "", url: "" });
                        }}
                        className="font-montserrat font-medium w-fit text-sm text-primary select-none
                         hover:text-primary-700 leading-[16.39px] tracking-[0.1px] cursor-pointer"
                      >
                        {file?.url === "" ? (
                          <div className="flex items-center select-none">
                            <span className="text-2xl mr-2">+</span>Click to
                            upload
                          </div>
                        ) : (
                          "Remove"
                        )}
                      </p>
                      <input
                        type="file"
                        name="file"
                        id="file"
                        accept=".pdf"
                        // accept=".pdf,.docx"
                        className="hidden"
                        onChange={handleFileChange}
                        disabled={isLoading || file?.url !== ""}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6">
          <div>
            <h1>NO DATA</h1>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PortfolioModal;
