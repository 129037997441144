import { Link } from 'react-location';
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from '@/constants/socials';
import { BiLogoFacebookCircle } from 'react-icons/bi';
import { FaInstagram, FaLinkedin, FaXTwitter } from 'react-icons/fa6';

import { clearAuth } from '@/apollo/cache/auth';
import { DEV_LANDING_URL, PROD_LANDING_URL, TEST_LANDING_URL } from '@/constants/page-path';

const Suspended = () => {
  return (
    <section className='h-full w-full'>
      <div className='xl:max-w-[75%] mx-auto xl:mt-8 flex px-5 py-2 justify-between items-start gap-x-[8rem]'>
        <div className='mx-auto flex flex-1 items-start gap-[2rem]'>
          <div className='w-full flex flex-col gap-y-10 justify-center items-center'>
            <img src='/images/svg/suspended.svg' width={300} height={300} alt='suspended image' />

            <h2
              className='md:w-[80%] font-montserrat text-base md:text-2xl leading-[19.2px] md:leading-[1.8rem]
             tracking-[0.1px] text-center text-black px-8 md:px-0'>
              Your account has been suspended
            </h2>

            <div className='max-w-[18.188rem]'>
              <p className='text-sm font-montserrat leading-[16.8px] tracking-[0.1px] text-center text-black '>
                Follow us on social media to get connected.
              </p>
              <div className='flex justify-center items-center gap-x-6 mt-5'>
                <Link to={FACEBOOK}>
                  <BiLogoFacebookCircle className='text-black w-6 h-6' />
                </Link>
                <Link to={TWITTER}>
                  <FaXTwitter className='text-black w-6 h-6' />
                </Link>
                <Link to={INSTAGRAM}>
                  <FaInstagram className='text-black w-6 h-6' />
                </Link>
                <Link to={LINKEDIN}>
                  <FaLinkedin className='text-black w-6 h-6' />
                </Link>
              </div>
            </div>
            <a
              href={
                import.meta.env.VITE_NODE_ENV === 'development'
                  ? `${DEV_LANDING_URL}`
                  : import.meta.env.VITE_NODE_ENV === 'production'
                  ? `${PROD_LANDING_URL}`
                  : import.meta.env.VITE_NODE_ENV === 'test'
                  ? `${TEST_LANDING_URL}`
                  : '/nolink'
              }
              onClick={() => {
                clearAuth();
              }}
              className='w-full lg:max-w-[23.563rem] h-[3.125rem] flex
             justify-center items-center text-white cursor-pointer font-sans text-base leading-5 tracking-[0.1px] 
             text-center rounded-lg bg-primary'>
              Contact Support
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Suspended;
