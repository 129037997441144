import List from "@/components/core/list";
import { classNames } from "@/utils";
import { FC } from "react";

type Props<T = any> = {
  list_data: T;
  title: string;
  list_disc?: boolean;
};
const DescriptionList: FC<Props> = ({ list_data, title, list_disc }) => {
  return (
    <div className="my-4">
      <h1 className="text-base font-semibold text-black">{title}</h1>
      <ul className={classNames(list_disc ? "pl-5" : "", " mt-2 description")}>
        {/* <List
          data={list_data ?? []}
          renderItem={(item) => (
            <li
              dangerouslySetInnerHTML={{ __html: item || "" }}
              className={classNames(
                list_disc ? "list-disc" : "",
                "text-sm text-black  my-2"
              )}
            />
          )}
        /> */}
        <div
          className="text-sm"
          dangerouslySetInnerHTML={{ __html: list_data || "N/A" }}
        />
      </ul>
    </div>
  );
};

export default DescriptionList;
