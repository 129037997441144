import React, { useEffect } from "react";

interface MetaTagsProps {
  title: string;
  description: string;
  imageUrl: string;
  url?: string;
}

const MetaTags: React.FC<MetaTagsProps> = ({
  title,
  description,
  imageUrl,
  url,
}) => {
  useEffect(() => {
    document.title = title;

    const metaTags = [
      { name: "description", content: description },
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:image", content: imageUrl },
      { property: "og:url", content: url || window.location.href },
      { name: "twitter:title", content: title },
      { name: "twitter:description", content: description },
      { name: "twitter:image", content: imageUrl },
    ];

    metaTags.forEach(({ name, property, content }) => {
      if (name) {
        let element = document.querySelector(`meta[name="${name}"]`);
        if (!element) {
          element = document.createElement("meta");
          element.setAttribute("name", name);
          document.head.appendChild(element);
        }
        element.setAttribute("content", content);
      }

      if (property) {
        let element = document.querySelector(`meta[property="${property}"]`);
        if (!element) {
          element = document.createElement("meta");
          element.setAttribute("property", property);
          document.head.appendChild(element);
        }
        element.setAttribute("content", content);
      }
    });
  }, [title, description, imageUrl, url]);

  return null;
};

export default MetaTags;
