import React, { useEffect } from "react";
import dp from "../../../assets/images/dp-1.svg";
import { useConversations } from "@/hooks/useConversations";
import { useUrlState } from "@/utils";
import _ from "lodash";
import { ConversationUpdatedSubscription, ConversationUpdatedSubscriptionVariables, MarkAllMessagesAsReadMutation, MarkAllMessagesAsReadMutationVariables, MessageStatus, UserType } from "@/apollo/messages/generated/types";
import moment from "moment";
import { useMutation, useSubscription } from "@apollo/client";
import { CONVERSATIONS_UPDATED, MARK_ALL_AS_READ } from "@/apollo/messages";
import { useCurrentUser } from "@/apollo/cache/auth";
import { PiCheckThin, PiChecksThin } from "react-icons/pi";
import Avatar from "@/components/core/avatar";


const ChatList: React.FC = ({}) => {
    const [selectedChat, setSelectedChat] = useUrlState("conversation_id");
    const user = useCurrentUser();


    const { conversations, fetchMore } = useConversations({ variables: { pagination: {}, userId: user?.id || "" } });
    const { data } = useSubscription<ConversationUpdatedSubscription, ConversationUpdatedSubscriptionVariables>(CONVERSATIONS_UPDATED);

    const [markAllAsRead] = useMutation<MarkAllMessagesAsReadMutation, MarkAllMessagesAsReadMutationVariables>(MARK_ALL_AS_READ, { refetchQueries: ["GetCoversations"], context: { apiName: "message" } });

    const handleOnClick = async (id: string) => {
        setSelectedChat(id);
        await markAllAsRead({
            variables: { conversationId: id, senderType: UserType.Talent },
        });
    };

    useEffect(() => {
        fetchMore({
            variables: {
                pagination: {},
                userId: user?.id || "",
            },
        });
    }, [data?.conversationUpdated]);

    return (
        <div className="h-full overflow-auto">
            {conversations && conversations?.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-full mt-12">
                    <h1 className="font-montserrat font-semibold text-base text-black">No Messages Yet!</h1>
                    <p className="text-center font-montserrat text-gray-400 text-sm mt-4">Start a conversation and connect with others! Your chat list is currently empty. Search and begin a new chat or wait for messages to appear here.</p>
                </div>
            ) : (
                conversations?.map((chat) => (
                    <div key={chat?.id} className={`flex justify-between items-center space-x-2  py-3 px-2 border-b ${chat?.id === selectedChat ? " mobile:bg-transparent bg-[#EBFFEB]" : ""}`} onClick={() => handleOnClick(chat?.id || "")}>
                        <div className="w-10 h-10 rounded-full bg-[#B1B1B1]">
                            <Avatar src={chat?.employer?.profilePicture || ""} alt={chat?.employer?.fullName || ""} size="sm" />
                        </div>
                        <div className="flex flex-1 overflow-hidden justify-between  border-gray-300">
                            <div className="w-full overflow-hidden">
                                <p className="font-montserrat font-semibold text-sm leading-4 ">{chat?.employer?.fullName} </p>
                                <p className="font-montserrat text-sm text-gray-400 whitespace-nowrap overflow-hidden">{chat?.lastMessageDetails?.message}</p>
                            </div>

                            <div className="flex flex-col items-end pr-4">
                                <p className="text-[10px] whitespace-nowrap mb-1 text-gray-400 ">{moment(chat?.lastMessageDetails?.createdAt).fromNow()}</p>
                                {(chat?.numberOfUnreadMessagesByEmployer || 0) > 0 ? (
                                    <div className="font-manrope font-medium w-5 h-5 rounded-full bg-primary-500 flex justify-center items-center text-white text-[10px] leading-3">{chat?.numberOfUnreadMessagesByTalent || 0}</div>
                                ) : chat?.lastMessageDetails?.status === MessageStatus.Sent ? (
                                    <PiCheckThin className="h-4 w-4 text-gray-500" />
                                ) : chat?.lastMessageDetails?.status === MessageStatus.Delivered ? (
                                    <PiChecksThin className="h-4 w-4 text-gray-500" />
                                ) : chat?.lastMessageDetails?.status === MessageStatus.Read ? (
                                    <PiChecksThin className="h-4 w-4 text-primary-500" />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default ChatList;
