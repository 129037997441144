import { Navigate, Outlet } from 'react-location';
import { FC } from 'react';
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from '@/constants/socials';
import { BiLogoFacebookCircle } from 'react-icons/bi';
import { FaInstagram, FaLinkedin, FaXTwitter } from 'react-icons/fa6';
import UnauthNav from '../components/unauth-nav';
import { useCurrentToken, useCurrentUser } from '@/apollo/cache/auth';
import whiteLogo from '@/assets/images/logo-white.png';
//
const UnAuthLayout: FC = () => {
  const currentUser = useCurrentUser();
  const currentToken = useCurrentToken();

  // if (currentToken && currentUser?.id) {
  //   return <Navigate to={'/'} replace />;
  // }

  return (
    <div className='h-screen  w-screen relative overflow-auto'>
      <UnauthNav />
      <div className='pt-14 mb-24 w-full '>
        <Outlet />
      </div>
      <footer
        className=' bg-primary-500 w-full fixed z-40 bottom-0 flex justify-between py-5  lg:px-10 px-5 items-center flex-col sm:flex-row '
        aria-labelledby='footer-heading'>
        <div className=' '>
          <img src={whiteLogo} alt='logo' className='w-24' />
        </div>
        <p className=' text-sm text-white '>&copy; 2024, Adjuma.io</p>
        <div className=' mt-5 sm:mt-0'>
          <a
            href={'https://www.adjuma.io/contact'}
            className=' text-sm font-medium text-white mb-5'>
            Contact Support
          </a>
          <div className='flex justify-center space-x-2 items-center mt-2'>
            <a href={FACEBOOK}>
              <BiLogoFacebookCircle className='text-white w-4 h-4' />
            </a>
            <a href={TWITTER}>
              <FaXTwitter className='text-white w-4 h-4' />
            </a>
            <a href={INSTAGRAM}>
              <FaInstagram className='text-white w-4 h-4' />
            </a>
            <a href={LINKEDIN}>
              <FaLinkedin className='text-white w-4 h-4' />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UnAuthLayout;
