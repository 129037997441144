import { FC, ReactElement, useState, useEffect } from "react";
import UncompleteBanner from "./components/uncomplete-banner";
import Show from "@/components/core/show";
import Wrapper from "@/components/wrapper";
import List from "../core/list";
import DetailSliderContainer from "./components/detail-slider-container";
import useWidth from "@/hooks/useWidth";
import AppliedJobDetails from "./components/applied-job-detail";
import emailFooter from "@/assets/images/email-footer.png";
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from "@/constants/socials";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { profileComplete } from "@/utils/profile-complete";

interface Props<T = any> {
  title: string;
  data: T[];
  renderItem: (data: T) => ReactElement;
  renderEmptyComponent?: () => ReactElement;
  selectedJob: T;
  setSelectedJob: (job: T | null) => void;
  refetch: () => void;
  handleWithdrawJob?: (jobId: string) => void;
  moreJobs?: () => void;
  fetchPrevious?: () => void;
  disableFetch?: boolean;
  offset?: any;
  loading?: boolean;
  isFetchingMore?: boolean;
  isFetchingPrevious?: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;
}

const JobWrapper: FC<Props> = ({
  title,
  data,
  renderItem,
  renderEmptyComponent,
  selectedJob,
  setSelectedJob,
  refetch,
  moreJobs,
  fetchPrevious,
  disableFetch,
  handleWithdrawJob = () => {},
  offset,
  loading,
  isFetchingMore,
  isFetchingPrevious,
  open,
  setOpen,
}) => {
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [results, setResults] = useState(data);
  const [mobile] = useWidth();

  useEffect(() => {
    setResults(data);
  }, [data]);

  const handleSearch = () => {
    if (!jobTitle && !location) {
      setResults(data);
      return;
    }

    const filteredResults = data.filter((job) => {
      const matchesJobTitle = jobTitle
        ? job.title.toLowerCase().includes(jobTitle.toLowerCase())
        : true;
      const matchesLocation = location
        ? job.location.toLowerCase().includes(location.toLowerCase())
        : true;
      return matchesJobTitle && matchesLocation;
    });

    setResults(filteredResults);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const showUncompletedBanner = profileComplete();

  return (
    <>
      <Wrapper>
        <div className="relative w-full flex justify-center items-start h-full ">
          {/* left side */}
          <div className="md:w-1/2 w-full h-full border-r border-gray-300 overflow-scroll overflow-y-scroll no-scrollbar">
            <Show if={showUncompletedBanner}>
              <UncompleteBanner />
            </Show>
            <div
              className={`sticky ${
                showUncompletedBanner ? "top-16" : "top-0"
              }  z-10  bg-white border-b border-gray-300 w-full h-16 py-2.5 px-5`}
            >
              <p className="">Applied Jobs </p>
              <p className="">{results?.length} </p>
            </div>

            <div className="w-full flex flex-col items-center px-6 py-5">
              {fetchPrevious && (
                <button
                  onClick={fetchPrevious}
                  disabled={offset <= 0 || isFetchingPrevious}
                >
                  {isFetchingPrevious ? "Loading..." : "Load Previous Jobs"}
                </button>
              )}
              <List
                data={results}
                renderItem={(item) => (
                  <div onClick={() => setSelectedJob(item)}>
                    {renderItem(item)}
                  </div>
                )}
                renderEmptyComponent={renderEmptyComponent}
              />
              {moreJobs && (
                <button
                  onClick={moreJobs}
                  disabled={isFetchingMore || disableFetch}
                >
                  {isFetchingMore ? "Loading..." : "Load More Jobs"}
                </button>
              )}
            </div>
          </div>
          <div className="flex-1 w-1/2 h-full overflow-scroll overflow-y-scroll no-scrollbar ">
            <div className="w-full px-5">
              <Show if={title === "Applied Jobs"}>
                <>
                  <div className=" mt-5 ">
                    <div className="w-full rounded-xl ring-1 ring-gray-300 p-5 mb-5">
                      <img src={emailFooter} alt="email-footer" />
                      <div className="flex justify-start items-center w-full gap-3 mt-4 ">
                        <a href={FACEBOOK}>
                          <BiLogoFacebookCircle className="text-gray-400 w-6 h-6" />
                        </a>
                        <a href={TWITTER}>
                          <FaXTwitter className="text-gray-400 w-5 h-5" />
                        </a>
                        <a href={INSTAGRAM}>
                          <FaInstagram className="text-gray-400 w-5 h-5" />
                        </a>
                        <a href={LINKEDIN}>
                          <FaLinkedin className="text-gray-400 w-5 h-5" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {data?.length > 0 && selectedJob && (
                    <AppliedJobDetails
                      refetch={refetch}
                      job={selectedJob}
                      handleWithdrawJob={handleWithdrawJob}
                    />
                  )}
                </>
              </Show>
            </div>
          </div>
        </div>
        <Show if={mobile}>
          <DetailSliderContainer
            handleWithdrawJob={handleWithdrawJob}
            refetch={refetch}
            job={selectedJob}
            open={open}
            setOpen={setOpen}
          />
        </Show>
      </Wrapper>
    </>
  );
};

export default JobWrapper;
