import { ApolloLink, from, HttpLink, split } from "@apollo/client";
import activityMiddleware from "./activity";
import analyticsMiddleware from "./analytics";
import authMiddleware from "./auth";
//import errorMiddleware from "./error";
// import persistedQueriesMiddleware from "./pq";
import retryMiddleware from "./retry";
// import { RestLink } from "apollo-link-rest";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import config from "../../config";
import { getMainDefinition } from "@apollo/client/utilities";

const wsLink = new GraphQLWsLink(
  createClient({
      url: config.message.ws,
  })
);

const httpLink1 = new HttpLink({ uri: config.apollo.uri });

const httpLink2 = new HttpLink({ uri: config.message.uri });

const httpLink = ApolloLink.split((operation) => operation.getContext().apiName === "message", httpLink2, httpLink1);

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
);

// const httpLink = new RestLink({ endpoints: { message: config.message.uri }, uri: config.apollo.uri });

const link = from([
    authMiddleware,
    activityMiddleware,
    analyticsMiddleware,
    // errorMiddleware,
    retryMiddleware,
    // persistedQueriesMiddleware,
    splitLink,
]);

export default link;
