import React from "react";
import rafmoney from "@/assets/images/pana.png";
import { Link } from "react-location";

const EmptyState = () => {
  return (
    <div className="flex flex-col  items-center">
      <img
        src={rafmoney}
        alt="company-logo"
        className="w-64 h-auto object-contain py-5 "
      />
      <h4 className="font-bold text-center text-black py-3">
        You haven't applied for a job yet
      </h4>
      <p className="text-sm text-gray-400 text-center">
        Apply for jobs on the{" "}
        <Link className="text-primary underline font-medium" to={"/"}>
          Job Hunt page
        </Link>{" "}
        to view applied jobs
      </p>
    </div>
  );
};

export default EmptyState;
