import { IoCloseSharp, IoMenu } from "react-icons/io5";
import { Link } from "react-location";
import { useState } from "react";

import logo from "@/assets/images/logo.png";
import { PROD_LANDING_URL, TEST_LANDING_URL } from "@/constants/page-path";

const UnauthNav = () => {
  const [showNav, setShowNav] = useState(false);

  return (
    <nav
      className="fixed z-40 top-0 left-0 right-0 w-full flex items-center  h-14
          sm:border-b border-grey-300 transition-all  animate-nav-show shadow
         bg-white "
    >
      <div
        className="px-3 xl:px-0 w-full flex justify-between items-center font-sans font-normal
          text-lg text-grey-600 leading-[1.35rem] relative
             sm:mt-0 sm:justify-between xl:max-w-[83%] mx-auto"
      >
        <div className="flex items-center justify-between gap-x-6">
          <Link
            to={
              import.meta.env.VITE_NODE_ENV === "development"
                ? `/`
                : import.meta.env.VITE_NODE_ENV === "production"
                ? `${PROD_LANDING_URL}`
                : import.meta.env.VITE_NODE_ENV === "test"
                ? `${TEST_LANDING_URL}`
                : "/nolink"
            }
          >
            <img
              src={logo}
              alt="Adjuma Logo"
              className="max-w-[133.1px] max-h-[45.14px] object-contain"
            />
          </Link>
        </div>

        <div className="flex items-center md:gap-x-4 xl:gap-x-6 ">
          <Link
            to={"/login"}
            className="md:flex justify-center items-center rounded-lg px-2 py-1
               border bg-white border-primary-500 text-primary-500 text-center
                font-montserrat sm:text-sm text-xs text-nowrap hidden "
            type="button"
          >
            Log in
          </Link>
          <Link
            to={"/signup"}
            className=" md:flex justify-center items-center rounded-lg px-2 py-1
               border bg-primary-500 border-primary-500 text-white text-center
                font-montserrat  sm:text-sm text-xs text-nowrap  hidden"
            type="button"
          >
            Sign up
          </Link>
          {showNav ? (
            <IoCloseSharp
              className="text-gray-700 w-7 h-7 md:hidden"
              onClick={() => setShowNav(!showNav)}
            />
          ) : (
            <IoMenu
              className="text-gray-700 w-7 h-7 md:hidden"
              onClick={() => setShowNav(!showNav)}
            />
          )}
        </div>
      </div>
      {showNav && (
        <div
          onClick={() => setShowNav(false)}
          className="w-full absolute h-screen z-40 top-14 right-0 left-0 bg-gray-100 py-10 px-5 flex flex-col items-center gap-y-5"
        >
          <>
            <Link
              to={"/login"}
              className="w-full h-[2.875rem] rounded-lg p-[10px]
              border bg-white border-primary-500 text-primary-500 text-center
               font-montserrat text-lg leading-[21.6px] "
            >
              Log in
            </Link>
            <a
              href={"/signup"}
              className="w-full h-[2.875rem] rounded-lg p-[10px]
              border bg-primary-500 border-primary-500 text-white text-center
               font-montserrat text-lg leading-[21.6px]"
            >
              Sign up
            </a>
          </>
        </div>
      )}
    </nav>
  );
};

export default UnauthNav;
