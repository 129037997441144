import { useEffect, useRef, useState } from "react";

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

const FeedAd: React.FC = () => {
  const adRef = useRef<HTMLDivElement | null>(null); // Reference to the ad container

  useEffect(() => {
    // Initialize ads if adsbygoogle is available and this ad hasn't been initialized
    if (typeof window !== "undefined" && window.adsbygoogle && adRef.current) {
      try {
        // Push ads only if the element hasn't been initialized yet
        if (!adRef.current.hasAttribute("data-ads-initialized")) {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
          adRef.current.setAttribute("data-ads-initialized", "true"); // Mark ad as initialized
        }
      } catch (e) {
        console.error("Adsbygoogle push error:", e);
      }
    }
  }, []);

  return (
    <div className="ad-container w-full min-w-[250px] my-6" ref={adRef}>
      <ins
        className="adsbygoogle"
        style={{ display: "block", textAlign: "center" }}
        data-ad-format="fluid"
        data-ad-layout="in-feed"
        data-ad-layout-key="-6q+ed+2g-1n-4q"
        data-ad-client="ca-pub-2232552652668708"
        data-ad-slot="4585135589"
      ></ins>
    </div>
  );
};

export default FeedAd;
