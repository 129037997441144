import { useSearch } from "react-location";
import { LocationGenerics } from "@/router/location";
import logo from "@/assets/images/logo-orange.png";
import { useAppNaviagte } from "@/apollo/cache/auth";

const OnBoardingModal = ({ visible, onClose }: any) => {
  const search = useSearch<LocationGenerics>();
  const navigate = useAppNaviagte();

  if (!visible) return null;

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-60  flex justify-center items-center">
      <div
        className="
        w-[90%] md:w-[40.278%] py-8  bg-white z-30 
       rounded-xl bg-opacity-90 backdrop-blur-lg shadow-lg flex flex-col justify-center items-center gap-y-3"
      >
        <div className="w-16 h-16 bg-primary rounded-full flex justify-center items-center">
          <img src={logo} alt="logo" className="w-11 h-auto object-contain" />
        </div>
        <h3
          className=" font-montserrat font-semibold
         text-lg leading-[1.886rem] tracking-[0.1px]
          text-center px-3 md:px-0
      "
        >
          Congratulations 🎉{" "}
        </h3>
        <p
          className="w-[21rem] font-montserrat text-gray-400 
       text-sm leading-[1.602rem] tracking-[0.1px] text-center px-3 md:px-0 mb-4 md:mb-0 "
        >
          Congratulations on signing up. Now let's help you find the job you are
          looking for.
        </p>

        <button
          className="w-[173px] py-3 font-montserrat text-sm capitalize leading-5 tracking-[0.1px] text-center bg-primary rounded-lg text-[#D9D9D9]"
          type="button"
          onClick={() => {
            const redirectTo = search?.redirect ?? "/";
            navigate({ replace: true, to: redirectTo });
            onClose();
          }}
        >
          browse jobs
        </button>
      </div>
    </div>
  );
};

export default OnBoardingModal;
