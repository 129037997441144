import React from "react";
import JobHuntPage from ".";
import { useLocation, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "@/router/location";
import {
  BanknotesIcon,
  BriefcaseIcon,
  CheckIcon,
  CloudArrowUpIcon,
  DocumentIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { FC, useEffect, useState } from "react";
import LogoLize from "@/assets/images/logoLize.webp";
import { classNames, wrapClick } from "@/utils";
import Show from "@/components/core/show";
import TextInput from "@/components/core/text-input";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button } from "@/components/buttons";
import {
  useAppNaviagte,
  useCurrentToken,
  useCurrentUser,
} from "@/apollo/cache/auth";
import { useMutation as useMutationTanstack } from "@tanstack/react-query";
import { UPLOAD_FILE } from "@/helpers/upload-file";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { APPLY_JOB } from "@/apollo/graphql/mutations/job";
import { FaRegFile } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import { AiOutlineCloudUpload } from "react-icons/ai";
import moment from "moment";
import { TbBuildingCottage } from "react-icons/tb";
import { GET_JOB } from "@/apollo/graphql/queries/job";

import DescriptionList from "@/components/job-wrapper/components/description-list";
import { BiArrowBack } from "react-icons/bi";
import JobDetailLoader from "@/components/loaders/job-detail";
import notify from "@/assets/audio/notify.wav";
import errorSound from "@/assets/audio/error.wav";
import ButtonLoader from "@/components/loaders/button";
import RedirectModal from "./components/modal";
import {
  ApplyJobMutation,
  ApplyJobMutationVariables,
  GetJobQuery,
  GetJobQueryVariables,
} from "@/apollo/graphql/generated/types";
import MetaTags from "@/components/meta-tags";
import {
  DEV_TALENT_URL,
  PROD_TALENT_URL,
  TEST_TALENT_URL,
} from "@/constants/page-path";

const JobDetail = () => {
  const searchParams = useSearch<LocationGenerics>();

  const location = useLocation();
  const path = location.current.href;

  const fullPath = `${
    import.meta.env.VITE_NODE_ENV === "development"
      ? `${DEV_TALENT_URL}`
      : import.meta.env.VITE_NODE_ENV === "production"
      ? `${PROD_TALENT_URL}`
      : import.meta.env.VITE_NODE_ENV === "test"
      ? `${TEST_TALENT_URL}`
      : "/nolink"
  }${path}`;

  const user = useCurrentUser();
  const token = useCurrentToken();
  const navigate = useAppNaviagte();

  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || "");
  const [file, setFile] = useState({ fileName: "", url: "" });
  const [fileError, setFileError] = useState("");
  const [fileNames, setFileNames] = useState("");
  const [valid, setValid] = useState(true);
  const [redirectModal, setRedirectModal] = useState(false);

  const [toggleApply, setToggleApply] = useState<boolean>(false);
  const [selectResume, setSelectResume] = useState(false);

  const {
    data,
    loading: jobLoading,
    refetch,
  } = useQuery<GetJobQuery, GetJobQueryVariables>(GET_JOB, {
    variables: {
      jobId: searchParams?.id || "",
    },
  });

  const [job, setJob] = useState<any>(data?.getJob);

  const handleChange = (value: any): void => {
    setPhoneNumber(`+${value}`);
    setValid(validatePhoneNumber(value));
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneNumberPattern = /^\d{12}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  useEffect(() => {
    if (data?.getJob)
      // refetch();
      setJob(data?.getJob);
  }, [data]);

  useEffect(() => {
    document.title = job?.title || "Job Detail";
  }, [job]);

  const [applyForJob, { loading, error }] = useMutation<
    ApplyJobMutation,
    ApplyJobMutationVariables
  >(APPLY_JOB);

  // file upload
  const { isLoading, mutate: uploadFile } = useMutationTanstack({
    mutationKey: ["uploadFile"],
    mutationFn: UPLOAD_FILE,
  });

  const handleUpload = async (file: any) => {
    const data = new FormData();
    data.append("file", file);
    uploadFile(data, {
      onSuccess: (data) => {
        setFile(data);

        toast(
          JSON.stringify({
            type: "success",
            title: "File uploaded successfully",
          })
        );
      },
      onError: (error) => {
        toast(
          JSON.stringify({
            type: "error",
            title: "Failed to upload file",
          })
        );
      },
    });
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    setFileNames(selectedFile?.name || "");
    if (selectedFile) {
      try {
        await handleUpload(selectedFile);
        setFileError("");
      } finally {
      }
    }
  };

  interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    linkedin: string;
    portfolio: string;
    resume: string;
  }

  useEffect(() => {
    if (user?.portfolio && user?.portfolio.length > 0 && selectResume) {
      setFile({
        fileName: "",
        url: user?.portfolio ? user?.portfolio[0]?.resume : "",
      });
      setFileError("");
    }

    if (selectResume === false) {
      setFile({
        fileName: "",
        url: "",
      });
    }
  }, [selectResume]);

  const form = useFormik({
    initialValues: {
      firstName: user?.fullName?.split(" ")[0] || "",
      lastName: user?.fullName?.split(" ")[1] || "",
      email: user?.email || "",
      phoneNumber: user?.phoneNumber || "",
      linkedin: "",
      portfolio: "",
      resume: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      phoneNumber: Yup.string().required("Required"),
      // resume: Yup.string().required(
      //   "Your Resume is required to continue your application"
      // ),
      linkedin: Yup.string(),
      portfolio: Yup.string(),
    }),
    onSubmit: async (values: FormValues) => {
      if (file.url === "") {
        setFileError(
          "Please select/upload your resume to continue your application*"
        );
        return;
      }
      const likeSound = new Audio(notify);
      const errorNotify = new Audio(errorSound);
      await applyForJob({
        variables: {
          input: {
            email: values?.email,
            firstName: values?.firstName,
            jobId: searchParams.id || "",
            lastName: values?.lastName,
            linkdln: values?.linkedin,
            phoneNumber: values?.phoneNumber,
            portfolio: values?.portfolio,
            resume: file.url,
          },
        },
      })
        .then(({ data, errors }) => {
          if (data?.applyJob) {
            likeSound.volume = 0.5;
            likeSound.play();
            toast(
              JSON.stringify({
                type: "success",
                title: "Job Applied Successfully",
              })
            );
          }
        })
        .catch((error) => {
          errorNotify.volume = 0.5;
          errorNotify.play();
          toast(
            JSON.stringify({
              type: "error",
              title: error?.message || "Failed to apply for this job",
            })
          );
        });
    },
  });

  return (
    <div>
      <MetaTags
        title={job?.title ?? "Job Detail"}
        description={job?.about ?? "Job Description"}
        imageUrl="https://firebasestorage.googleapis.com/v0/b/clients-adjuma.appspot.com/o/profilePic%2Fadjuma.jpg?alt=media&token=76c81314-668d-49c7-aa00-6689072beca9"
        url={fullPath}
      />
      <JobHuntPage>
        <div className="mobile:relative">
          <div className="mobile:fixed mobile:inset-0 mobile:z-30 mobile:overflow-hidden">
            {jobLoading ? (
              <div className="mobile:absolute mobile:inset-0 mobile:overflow-y-scroll mobile:h-full mobile:z-30 bg-white mobile:pt-16 px-4 ">
                <JobDetailLoader />
              </div>
            ) : (
              <div className="mobile:absolute mobile:inset-0 mobile:overflow-y-scroll mobile:min-h-screen mobile:z-30 bg-white mobile:py-16">
                <div className="hidden mobile:block px-4 py-4 sticky top-0 bg-white z-10 right-0 left-0 border-b border-gray-300 ">
                  <div className="flex items-center gap-3">
                    <div className="flex h-7 items-center">
                      <button
                        type="button"
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        onClick={() => navigate({ to: "/", replace: true })}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <BiArrowBack
                          className="h-6 w-6 text-black"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                    <div className="text-sm font-medium leading-6 text-black">
                      Job Details
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="mobile:px-4">
                    <div className=" bg-white border border-gray-300 rounded-xl px-5 py-4 w-full cursor-pointer mt-3">
                      <div className="w-full">
                        <div className="flex items-center gap-2 mb-2">
                          <div className="w-10 h-10 rounded flex-center bg-white">
                            <img
                              src={job?.company?.companyLogo ?? LogoLize}
                              alt="company-logo"
                              className="w-full h-full object-cover "
                            />
                          </div>
                          <h1 className="text-base text-black sm:text-lg font-semibold">
                            {job?.title ?? "N/A🚫"}
                          </h1>
                        </div>

                        <div className="flex justify-start gap-2.5 items-center font-medium text-xs text-gray-500 ">
                          <p className="truncate">
                            {job?.company?.name ?? "Company's Name N/A"}
                          </p>
                          <p className="flex items-center gap-1 truncate ">
                            <span className="">
                              <MapPinIcon className="h-4 w-4" />
                            </span>{" "}
                            <span className="flex-1">
                              {job?.location ?? "N/A🚫"}
                            </span>
                          </p>
                          <p className="flex items-center gap-1 text-nowrap">
                            <span className="h-1 w-1 border border-b rounded-full bg-gray-500"></span>
                            <span className="flex-1 text-primary-500 text-center text-nowrap">
                              {/* {moment(job?.createdAt).startOf("day").fromNow()} */}
                              {moment(job?.createdAt).fromNow()}
                            </span>
                          </p>
                        </div>
                        <div className="flex justify-start flex-wrap gap-2 font-medium text-xs text-black mt-3">
                          <span className="bg-gray-100 px-2 flex gap-1 justify-start items-center py-1 rounded">
                            <BanknotesIcon className="h-4 w-4" />{" "}
                            <span className="text-center text-nowrap">
                              {!job?.salary || job?.salary?.maximum === "0" ? (
                                "Negotiable"
                              ) : (
                                <>
                                  {job?.salary?.currency ?? "N/A"}{" "}
                                  {job?.salary?.minimum ?? "N/A"} -{" "}
                                  {job?.salary?.maximum ?? "N/A"}{" "}
                                  {job?.salary?.frequency ?? "N/A"}
                                </>
                              )}
                            </span>
                          </span>
                          <span className="bg-gray-100 px-2 flex gap-1 justify-start items-center py-1 rounded">
                            {" "}
                            <BriefcaseIcon className="h-4 w-4" />
                            <span className="whitespace-nowrap">
                              {job?.mode ?? "N/A🚫"}
                            </span>
                          </span>
                          <span className="bg-gray-100 px-2 flex gap-1 justify-start items-center py-1 rounded">
                            {" "}
                            <TbBuildingCottage className="h-4 w-4" />{" "}
                            {job?.type ?? "N/A🚫"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sticky mobile:top-[3.75rem] top-0 z-30 bg-white w-full mobile:px-2 sm:px-5 py-3 my-5 sm:border border-gray-300 sm:rounded-xl ">
                    <div className="w-full rounded-full bg-gray-100 flex-between h-10 p-1">
                      <button
                        onClick={() => setToggleApply(false)}
                        className={classNames(
                          toggleApply
                            ? "text-gray-500"
                            : " bg-white shadow text-black",
                          "flex-1 text-center text-sm flex-center rounded-full h-full transition-all duration-100 ease-in-out"
                        )}
                      >
                        About the job
                      </button>{" "}
                      <button
                        onClick={() => setToggleApply(true)}
                        className={classNames(
                          toggleApply
                            ? " bg-white shadow text-black"
                            : "text-gray-500",
                          "flex-1 text-center text-sm flex-center rounded-full h-full transition-all duration-100 ease-in-out"
                        )}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <div className="mobile:px-4">
                    <div className="w-full sm:px-5 sm:pt-2 pb-5 sm:border border-gray-300 my-5 rounded-xl ">
                      <Show if={!toggleApply}>
                        <DescriptionList
                          list_data={[job?.about]}
                          title={"Job Description"}
                        />
                        <DescriptionList
                          list_data={job?.requirements}
                          title={"Requirements/Qualifications"}
                          list_disc={true}
                        />
                        <DescriptionList
                          list_data={[job?.responsibilities]}
                          title={"Responsibilities"}
                          list_disc={true}
                        />
                        {job && job?.benefits !== "" && (
                          <DescriptionList
                            list_data={[job?.benefits]}
                            title={"Benefits"}
                            list_disc={true}
                          />
                        )}
                        <DescriptionList
                          list_data={[
                            job?.company?.about ??
                              "Company's description not available🚫",
                          ]}
                          title={"Company Description"}
                        />
                      </Show>

                      <Show if={toggleApply}>
                        <>
                          <h1 className="font-medium text-base text-black mt-2">
                            Apply for this Job
                          </h1>
                          <form
                            onSubmit={form.handleSubmit}
                            className="mt-5 mobile:pb-16"
                          >
                            <div className="mb-4">
                              <label
                                className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                                htmlFor="firstName"
                              >
                                First name
                              </label>

                              <input
                                id="firstName"
                                type="text"
                                placeholder="Enter your first name"
                                className="input-base"
                                {...form.getFieldProps("firstName")}
                              />
                              {form.touched.firstName &&
                              form.errors.firstName ? (
                                <div className="text-red-600 text-xs mt-1">
                                  {form.errors.firstName}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-4">
                              <label
                                className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                                htmlFor="lastName"
                              >
                                Last name
                              </label>

                              <input
                                id="lastName"
                                {...form.getFieldProps("lastName")}
                                type="text"
                                placeholder="Enter your last name"
                                className="input-base"
                              />
                              {form.touched.lastName && form.errors.lastName ? (
                                <div className="text-red-600 text-xs mt-1">
                                  {form.errors.lastName}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-4">
                              <label
                                className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                                htmlFor="email"
                              >
                                Email
                              </label>

                              <input
                                id="email"
                                placeholder="Enter your email"
                                {...form.getFieldProps("email")}
                                type="email"
                                autoComplete="email"
                                className="input-base"
                                title="Please enter a valid email address"
                              />
                              {form.touched.email && form.errors.email ? (
                                <div className="text-red-600 text-xs mt-1">
                                  {form.errors.email}
                                </div>
                              ) : null}
                            </div>

                            <div className="mb-3 lg:mb-2">
                              <label
                                className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                                htmlFor="phoneNumber"
                              >
                                phone number
                              </label>

                              <PhoneInput
                                country={"gh"}
                                placeholder={"Mobile Number"}
                                value={form.values.phoneNumber}
                                onChange={(value: any) =>
                                  form.setFieldValue("phoneNumber", value)
                                }
                                countryCodeEditable={false}
                                enableSearch={true}
                                inputProps={{
                                  required: true,
                                  title: "Enter your phone number here",
                                  className: "input-base",
                                }}
                                inputStyle={{
                                  width: "80%",
                                  marginLeft: "20%",
                                }}
                                buttonStyle={{
                                  width: "18%",
                                  borderRadius: "0.5rem",
                                  borderColor: "rgb(156 163 175)",
                                  paddingInline: "0rem",
                                }}
                                dropdownStyle={{
                                  borderRadius: "0.5rem",
                                  borderColor: "rgb(156 163 175)",
                                  paddingInline: "1rem",
                                }}
                                searchStyle={{
                                  width: "90%",
                                  borderRadius: "0.5rem",
                                  borderColor: "rgb(156 163 175)",
                                  paddingInline: "1rem",
                                }}
                              />
                              {form.touched.phoneNumber &&
                              form.errors.phoneNumber ? (
                                <div className="text-red-600 text-xs mt-1">
                                  {form.errors.phoneNumber}
                                </div>
                              ) : null}
                            </div>

                            <div className="mb-4">
                              <label
                                className="w-full text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                                htmlFor="file"
                              >
                                Add Resume/CV
                              </label>
                              {user?.portfolio &&
                                user?.portfolio.length > 0 && (
                                  <>
                                    <p
                                      className="font-montserrat text-xs leading-[14.4px]
         tracking-[0.1px] text-grey-400 mb-2"
                                    >
                                      Choose uploaded resume or upload a new
                                      resume
                                    </p>

                                    <div
                                      onClick={() =>
                                        setSelectResume(!selectResume)
                                      }
                                      className={`flex items-center w-full h-[60px] px-4 border cursor-pointer
           ${
             selectResume ? "border-primary" : "border-[#9CA3AF]"
           }  rounded-xl mb-3 transition-all duration-100 ease-in-out`}
                                    >
                                      <div className="w-full flex justify-between items-center">
                                        <div className="flex items-center gap-x-3">
                                          <div
                                            className={`w-8 h-8 rounded-full bg-[#DDDDDD] flex justify-center items-center
                ${
                  selectResume ? "bg-primary" : "bg-[#DDDDDD]"
                } transition-all duration-100 ease-in-out`}
                                          >
                                            <FaRegFile
                                              className={`${
                                                selectResume
                                                  ? "text-white"
                                                  : "text-grey-900"
                                              } transition-all duration-100 ease-in-out`}
                                            />
                                          </div>
                                          <p className="font-manrope font-medium text-sm leading-5 text-gray-700 ">
                                            {user?.fullName?.split(" ")[0]}'s
                                            Resume
                                          </p>
                                        </div>

                                        <div
                                          className={`w-4 h-4 p-2 rounded-full border border-[#9CA3AF]
          flex justify-center items-center ${
            selectResume ? "border-primary bg-primary" : "bg-[#9CA3AF]"
          } transition-all duration-100 ease-in-out`}
                                        >
                                          <span className="text-white text-xs font-bold ">
                                            &#10003;
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    {!selectResume && (
                                      <div className="w-full flex items-center gap-x-2 mb-3">
                                        <div
                                          style={{ content: "" }}
                                          className="w-full h-[1px] bg-[#D9D9D9]"
                                        />
                                        <p className="text-grey-600 font-[400] font-manrope text-sm">
                                          or
                                        </p>
                                        <div
                                          style={{ content: "" }}
                                          className="w-full h-[1px] bg-[#D9D9D9]"
                                        />
                                      </div>
                                    )}
                                  </>
                                )}

                              {!selectResume && (
                                <div className="mb-4">
                                  <label
                                    className="flex items-center justify-center w-full h-[60px] px-4 border border-dashed border-[#9CA3AF]
         rounded-xl mb-3  text-xs font-[400] cursor-pointer
            text-black-2 leading-[0.9rem] tracking-[0.006rem]"
                                    htmlFor="file"
                                  >
                                    {isLoading ? (
                                      <div className="w-full flex gap-x-4 items-center justify-center">
                                        <span className="text-gray-600 text-xs">
                                          Uploading file...
                                        </span>
                                        <ClipLoader
                                          color={"#02733E"}
                                          loading={true}
                                          size={20}
                                          aria-label="Loading Spinner"
                                          data-testid="loader"
                                        />
                                      </div>
                                    ) : (
                                      <div className="w-full flex justify-between items-center">
                                        <div
                                          className="w-full font-montserrat font-normal 
             text-xs text-grey-500 leading-[14.4px]"
                                        >
                                          {fileNames !== "" ? (
                                            <>{fileNames}</>
                                          ) : (
                                            <div className="w-full flex items-center justify-center gap-x-3">
                                              <AiOutlineCloudUpload className="w-8 h-8 text-grey-400" />
                                              <div className="">
                                                <p
                                                  className="font-montserrat font-normal
              text-sm text-grey-400 leading-[16.8px]
               tracking-[0.1px] cursor-pointer"
                                                >
                                                  Click to upload Resume/CV
                                                </p>
                                                <p className="text-[10px] text-gray-400">
                                                  Supported formats:{" "}
                                                  <span className="text-primary-700">
                                                    PDF only
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {file?.fileName !== "" && (
                                          <div
                                            className={`w-4 h-4 p-2 rounded-full border border-[#9CA3AF]
             ${
               file?.fileName !== "" ? "bg-primary" : "bg-gray-400"
             } flex justify-center items-center`}
                                          >
                                            <span className="text-white text-xs font-bold ">
                                              &#10003;
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}

                                    <input
                                      type="file"
                                      name="file"
                                      id="file"
                                      accept=".pdf"
                                      // accept=".pdf,.docx"
                                      className="hidden"
                                      onChange={handleFileChange}
                                    />
                                  </label>
                                </div>
                              )}

                              <div className="font-medium text-red-600 text-xs mt-1">
                                {fileError}
                              </div>
                            </div>

                            <div className="mb-4">
                              <label
                                className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                                htmlFor="linkedin"
                              >
                                LinkedIn Profile URL (Optional)
                              </label>

                              <input
                                id="linkedin"
                                {...form.getFieldProps("linkedin")}
                                type="url"
                                autoComplete="url"
                                className="input-base"
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                                htmlFor="portfolio"
                              >
                                Portfolio link (Optional)
                              </label>

                              <input
                                id="portfolio"
                                {...form.getFieldProps("portfolio")}
                                type="url"
                                autoComplete="url"
                                className="input-base"
                              />
                            </div>

                            <button
                              type={user && token ? "submit" : "button"}
                              onClick={
                                user && token
                                  ? () => {}
                                  : () => setRedirectModal(true)
                              }
                              disabled={loading}
                              className="w-full h-[3rem] rounded-lg bg-primary text-white flex justify-center items-center text-base"
                            >
                              {loading ? (
                                <ButtonLoader title="Submitting..." />
                              ) : (
                                " Submit Application"
                              )}
                            </button>
                          </form>
                        </>
                      </Show>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </JobHuntPage>
      <RedirectModal open={redirectModal} setOpen={setRedirectModal} />
    </div>
  );
};

export default JobDetail;
