import { gql } from "@apollo/client";

export const GET_JOBS = gql`
  query GetJobs(
    $filter: JobFilter
    $pagination: Pagination
    $sort: JobSort
    $condition: Condition
  ) {
    getJobs(
      filter: $filter
      pagination: $pagination
      sort: $sort
      condition: $condition
    ) {
      rows {
        companyId
        company {
          id
          name
          address
          companyType
          about
          coverPhoto
          companyLogo
          createdAt
          updatedAt
        }
        id
        title
        type
        about
        mode
        salary {
          maximum
          frequency
          minimum
          currency
        }
        requirements
        skills
        responsibilities
        benefits
        location
        status
        openDate
        closeDate
        urgent
        applicationCount
        likes {
          id
          userId
          jobId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_JOB = gql`
  query GetJob($jobId: ID!) {
    getJob(jobId: $jobId) {
      companyId
      company {
        id
        name
        phone
        address
        companyType
        about
        industry
        coverPhoto
        companyLogo
        createdAt
        updatedAt
      }
      id
      title
      type
      about
      mode
      salary {
        maximum
        frequency
        minimum
        currency
      }
      requirements
      skills
      responsibilities
      benefits
      location
      status
      vacancies
      openDate
      closeDate
      urgent
      applicationCount
      likes {
        id
        userId
        jobId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_SAVED_JOBS = gql`
  query GetAllSavedJobs(
    $candidateId: ID!
    $pagination: Pagination
    $sort: SavedJobSort
  ) {
    getAllSavedJobs(
      candidateId: $candidateId
      pagination: $pagination
      sort: $sort
    ) {
      count
      rows {
        id
        savedJob {
          companyId
          company {
            id
            name
            email
            phone
            address
            companyType
            about
            industry
            coverPhoto
            companyLogo
            createdAt
            updatedAt
          }
          id
          title
          type
          about
          mode
          salary {
            maximum
            frequency
            minimum
            currency
          }
          requirements
          skills
          responsibilities
          benefits
          location
          status
          vacancies
          openDate
          closeDate
          urgent
          applicationCount
          likes {
            id
            userId
            jobId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        candidateId
        savedAt
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_SAVED_JOB = gql`
  query GetSavedJob($getSavedJobId: ID!, $candidateId: ID!) {
    getSavedJob(id: $getSavedJobId, candidateId: $candidateId) {
      id
      savedJob {
        companyId
        company {
          id
          name
          email
          phone
          address
          companyType
          about
          industry
          coverPhoto
          companyLogo
          createdAt
          updatedAt
        }
        id
        title
        type
        about
        mode
        salary {
          maximum
          frequency
          minimum
          currency
        }
        requirements
        skills
        responsibilities
        benefits
        location
        status
        vacancies
        openDate
        closeDate
        urgent
        applicationCount
        likes {
          id
          userId
          jobId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      candidateId
      savedAt
      createdAt
      updatedAt
    }
  }
`;

export const GET_APPLIED_JOBS = gql`
  query GetAllAppliedJobs(
    $candidateId: ID!
    $pagination: Pagination
    $sort: AppliedJobSort
  ) {
    getAllAppliedJobs(
      candidateId: $candidateId
      pagination: $pagination
      sort: $sort
    ) {
      rows {
        id
        appliedJob {
          companyId
          company {
            id
            name
            email
            phone
            address
            companyType
            about
            industry
            coverPhoto
            companyLogo
            createdAt
            updatedAt
          }
          id
          title
          type
          about
          mode
          salary {
            maximum
            frequency
            minimum
            currency
          }
          requirements
          skills
          responsibilities
          benefits
          location
          status
          vacancies
          openDate
          closeDate
          urgent
          applicationCount
          likes {
            id
            userId
            jobId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        candidateId
        companyId
        appliedAt
        evaluation {
          id
          applicationId
          rating
          comment
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      count
    }
  }
`;
