export interface Option {
  id: number;
  label: string;
  value?: string;
}

export const eduLevel: Option[] = [
  { id: 1, value: 'High School Diploma', label: 'High School Diploma' },
  { id: 2, value: "Associate's Degree", label: "Associate's Degree" },
  {
    id: 3,
    value: 'GED (General Educational Development)',
    label: 'GED (General Educational Development)',
  },
  { id: 4, value: "Bachelor's Degree", label: "Bachelor's Degree" },
  { id: 5, value: "Master's Degree", label: "Master's Degree" },
  {
    id: 6,
    value: 'Doctorate (Ph.D.) Degree',
    label: 'Doctorate (Ph.D.) Degree',
  },
  {
    id: 7,
    value: 'Professional Certifications',
    label: 'Professional Certifications',
  },
  { id: 8, value: 'Vocational Training', label: 'Vocational Training' },
  { id: 9, value: 'Diploma', label: 'Diploma' },
  { id: 10, value: 'Certificate', label: 'Certificate' },
  {
    id: 11,
    value: 'High School Certificate',
    label: 'High School Certificate',
  },
  {
    id: 12,
    value: 'Trade School Certification',
    label: 'Trade School Certification',
  },
  {
    id: 13,
    value: 'Industry-Specific Certifications',
    label: 'Industry-Specific Certifications',
  },
  {
    id: 14,
    value: 'Online Courses and Certifications',
    label: 'Online Courses and Certifications',
  },
  { id: 15, value: 'Apprenticeship', label: 'Apprenticeship' },
  { id: 16, value: 'Continuing Education', label: 'Continuing Education' },
  { id: 17, value: 'Other', label: 'Other' },
];

export const jobOptions: Option[] = [
  { id: 1, label: 'Human Resources' },
  { id: 2, label: 'Marketing' },
  { id: 4, label: 'Sales' },
  { id: 5, label: 'Finance' },
  { id: 6, label: 'Information Technology' },
  { id: 7, label: 'Customer Service' },
  { id: 8, label: 'Research and Development' },
  { id: 9, label: 'Operations' },
  { id: 10, label: 'Administration' },
  { id: 11, label: 'Legal' },
  { id: 12, label: 'Engineering' },
  { id: 13, label: 'Manufacturing' },
  { id: 14, label: 'Quality Assurance' },
  { id: 15, label: 'Logistics' },
  { id: 16, label: 'Supply Chain' },
  { id: 17, label: 'Product Management' },
  { id: 18, label: 'Design' },
  { id: 19, label: 'Public Relations' },
  { id: 20, label: 'Health and Safety' },
  { id: 21, label: 'Facilities Management' },
];
