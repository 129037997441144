import { useEffect, useState } from "react";

import {
  Achievement,
  Education,
  Portfolio,
  Profession,
  Profile,
  Reference,
} from "./components/tab-contents";
import Wrapper from "@/components/wrapper";
import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import { Link, Navigate, useSearch } from "react-location";
import { LocationGenerics } from "@/router/location";

const ProfilePage = () => {
  useEffect(() => {
    document.title = "Talent | Profile";
  }, []);
  const tabs = [
    "Profile",
    "Profession",
    "Education",
    "Achievements",
    "Portfolio",
    "Reference",
  ];

  const searchParams = useSearch<LocationGenerics>();

  function TabContent() {
    switch (searchParams.sort) {
      case "Profile":
        return <Profile />;
      case "Profession":
        return <Profession />;
      case "Education":
        return <Education />;
      case "Achievements":
        return <Achievement />;
      case "Portfolio":
        return <Portfolio />;
      case "Reference":
        return <Reference />;
      default:
        return <Profile />;
    }
  }

  const token = useCurrentToken();
  const user = useCurrentUser();

  const isLoggedIn = user && token;

  if (!isLoggedIn) return <Navigate to="/login" replace />;

  return (
    <Wrapper>
      <main className="w-full h-full relative pb-5 ">
        <div className="h-full  overflow-scroll overflow-y-scroll no-scrollbar">
          <div className="bg-white sticky top-0 z-20">
            <h1
              className="font-semibold text-black text-lg leading-5 
          tracking-[0.1px] py-5 border-b border-gray-200 px-5"
            >
              My Profile
            </h1>
          </div>
          <div className="mobile:py-0 py-5 md:px-5">
            <div className="md:w-40 w-full bg-white fixed mobile:sticky mobile:top-[3.8rem] top-[7.8rem] md:top-36 z-10 md:h-full">
              <div className="flex flex-row overflow-x-auto px-5 pb-2 mobile:py-4 md:flex-col grow gap-2 overflow-y-auto justify-start md:my-5  w-full max-w-[100vw] no-scrollbar">
                {tabs.map((tab) => (
                  <Link
                    key={tab}
                    className={`text-left text-sm p-2.5 rounded-md ${
                      tab === searchParams.sort
                        ? "text-black bg-[#F5F6F9] font-medium"
                        : "text-gray-600"
                    }`}
                    to={`/profile/?sort=${tab}`}
                  >
                    {tab}
                  </Link>
                ))}
              </div>
            </div>

            <div className="md:pl-48 mobile:w-full mobile:mt-5 mt-20 px-4 pb-16 md:mt-0 md:px-0 ">
              <div className="md:max-w-[47.5rem] mobile:w-full overflow-y-scroll no-scrollbar">
                {TabContent()}
              </div>
            </div>
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default ProfilePage;
