import { gql } from "@apollo/client";

export const SETTINGS = gql`
  query Settings($userId: ID!, $userType: UserType!) {
    settings(userId: $userId, userType: $userType) {
      id
      userId
      userType
      updatesEmail
      updatesSms
      jobsEmail
      jobsSms
      remindersEmail
      remindersSms
      activitiesEmail
      activitiesSms
      createdAt
      updatedAt
    }
  }
`;
