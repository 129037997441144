/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "@/apollo/graphql/mutations/auth";
import { useCurrentUser } from "@/apollo/cache/auth";
import toast from "react-hot-toast";
import Modal from "@/components/layouts/modal";
import { IoCalendarClearOutline } from "react-icons/io5";
import { levels } from "@/constants/regions";
import { useFormik } from "formik";
import _ from "lodash";

import ButtonLoader from "@/components/loaders/button";
import {
  ADD_ACHIEVEMENT,
  UPDATE_ACHIEVEMENT,
} from "@/apollo/graphql/mutations/profile";

const AchievementModal = ({ visible, onClose, refetch, data }: any) => {
  const currentUser = useCurrentUser();
  const [loadingModal, setLoadingModal] = useState(false);
  const datePickerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (data) {
      const start = data?.date
        ? new Date(data.date).toISOString().split("T")[0]
        : "";
      form.setValues({
        title: data?.title || "",
        description: data?.description || "",
        date: start,
      });
    } else {
      form.resetForm();
    }
  }, [data]);

  const [addAchievement, { loading }] = useMutation(ADD_ACHIEVEMENT);
  const [updateAchievement, { loading: updateLoading }] =
    useMutation(UPDATE_ACHIEVEMENT);

  interface ProfessionalFormData {
    title: string;
    description: string;
    date: string;
  }

  const form = useFormik({
    initialValues: {
      title: "",
      description: "",
      date: "",
    },
    onSubmit: async (values: ProfessionalFormData) => {
      const input = {
        title: values.title,
        date: values.date,
        description: values.description,
      };
      if (data) {
        await updateAchievement({
          variables: {
            input: {
              ...input,
              id: data?.id,
            },
          },
        })
          .then(({ data }) => {
            onClose();
            refetch();
            toast(
              JSON.stringify({
                type: "success",
                title: `Profile successfully updated`,
              })
            );
          })
          .catch((error) => {
            toast(
              JSON.stringify({
                type: "error",
                title: error?.message || "Error while updating Profile",
              })
            );
          });
        return;
      } else {
        await addAchievement({
          variables: {
            input,
          },
        })
          .then(({ data }) => {
            form.resetForm();
            onClose();
            refetch();
            toast(
              JSON.stringify({
                type: "success",
                title: `Profile successfully updated`,
              })
            );
          })
          .catch((error) => {
            toast(
              JSON.stringify({
                type: "error",
                title: error?.message || "Error while updating Profile",
              })
            );
          });
      }
    },
  });

  if (!visible) return null;

  return (
    <Modal
      open={visible}
      setOpen={() => {
        onClose();
      }}
      loading={loadingModal}
      hideActions={false}
      description="Achievements and Awards"
      renderActions={() => (
        <>
          <button
            disabled={loading}
            className=" w-[8rem] h-[2.5rem] flex justify-center items-center font-montserrat font-normal
                 text-sm capitalize leading-5 text-center
                  bg-primary rounded-lg text-white
                  border border-primary "
            type="button"
            onClick={() => {
              form.handleSubmit();
            }}
          >
            {loading || updateLoading ? (
              <ButtonLoader title="Saving..." />
            ) : (
              " Save changes"
            )}
          </button>
        </>
      )}
    >
      {currentUser?.id ? (
        <>
          <div className="relative bg-white mt-3">
            {/* inputs come here */}

            <div className="w-full">
              <div className="mb-3">
                <label
                  className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.006rem]"
                  htmlFor="title"
                >
                  Title
                </label>

                <input
                  id="title"
                  name="title"
                  type="text"
                  onChange={form.handleChange}
                  value={form.values?.title || ""}
                  className="input-base"
                  placeholder="Enter Achievement/Award Title"
                />
              </div>
              <div className="mb-3">
                <label
                  className="font-montserrat text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                  htmlFor="description"
                >
                  Description
                </label>

                <input
                  id="description"
                  name="description"
                  type="text"
                  onChange={form.handleChange}
                  value={form.values?.description || ""}
                  className="input-base"
                  placeholder="Type Description of Award or Achievement"
                />
              </div>

              <div className="mb-3 flex justify-between items-center gap-x-6">
                <div className="w-full flex-1">
                  <label
                    className="w-full font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.006rem]"
                    htmlFor="date"
                  >
                    Date
                  </label>

                  <div className="w-full flex items-center justify-between  input-base">
                    <input
                      type="date"
                      name="date"
                      id="date"
                      value={form.values.date}
                      onChange={form.handleChange}
                      className="select-no-outline border-none cursor-pointer outline-none w-full text-xs leading-[0.9rem] tracking-[0.006rem]"
                      ref={datePickerRef}
                    />
                    {/* <IoCalendarClearOutline
                      onClick={() => datePickerRef.current?.setFocus()}
                      className="text-gray-400 w-5 h-5 cursor-pointer"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6">
          <div>
            <h1>NO DATA</h1>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AchievementModal;
