import {
  BanknotesIcon,
  BriefcaseIcon,
  FireIcon,
  HandThumbUpIcon,
  LinkIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { FC, useEffect, useRef, useState } from "react";
import { wrapClick } from "@/utils";
import Show from "@/components/core/show";
import LogoLize from "@/assets/images/logoLize.webp";
import { useMutation, useQuery } from "@apollo/client";
import {
  LIKE_UNLIKE,
  SAVE_JOB,
  UNSAVE_JOB,
} from "@/apollo/graphql/mutations/job";
import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import toast from "react-hot-toast";

import _ from "lodash";
import moment from "moment";
import { RiCheckDoubleFill } from "react-icons/ri";
import CommentModalContainer from "./comment-modal";
import { IoBookmark } from "react-icons/io5";
import { IoBookmarkOutline } from "react-icons/io5";
import { TbBuildingCottage } from "react-icons/tb";
import { RiShareForward2Fill } from "react-icons/ri";

import { GET_SAVED_JOBS } from "@/apollo/graphql/queries/job";

import { Link, useLocation, useNavigate } from "react-location";

import notify from "@/assets/audio/notify.wav";
import {
  DEV_TALENT_URL,
  PROD_TALENT_URL,
  TEST_TALENT_URL,
} from "@/constants/page-path";
import {
  SaveJobMutation,
  SaveJobMutationVariables,
  UnSaveJobMutation,
  UnSaveJobMutationVariables,
} from "@/apollo/graphql/generated/types";

interface Job {
  id: string;
  title: string;
  location: string;
  openDate: string;
  createdAt: string;
  salary: {
    maximum: string;
    minimum: string;
    currency: string;
    frequency: string;
  };
  type: string;
  mode: string;
  description: string;
  shares: number;
  activelyHiring: boolean;
  status: string;
  about: string;
  company: {
    name: string;
    companyLogo: string;
  };
  likes: {
    id: string;
    userId: string;
    jobId: string;
    createdAt: string;
    updatedAt: string;
  }[];
  comments: [];
}

interface JobComponentProps {
  id?: string;
  job: Job;
  selected: boolean;
  onClick: () => void;
  refetch?: () => void;
  handleUnsave?: (jobId: string) => void;
}

const JobComponent: FC<JobComponentProps> = ({
  id,
  job,
  selected,
  onClick,
  refetch,
  handleUnsave,
}) => {
  const [commentModal, setCommentModal] = useState(false);
  const [share, setShare] = useState<boolean>(false);
  const shareRef = useRef<HTMLButtonElement>(null);
  const [copied, setCopied] = useState(false);

  const user = useCurrentUser();
  const token = useCurrentToken();
  const pathname = useLocation();

  const navigate = useNavigate();

  // console.log(currentUser, currentToken);

  // if (!token || !user?.id) {
  //   return <Navigate to={"/login"} replace />;
  // }

  const isLoggedIn = token && user?.id;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        `${
          import.meta.env.VITE_NODE_ENV === "development"
            ? `${DEV_TALENT_URL}`
            : import.meta.env.VITE_NODE_ENV === "production"
            ? `${PROD_TALENT_URL}`
            : import.meta.env.VITE_NODE_ENV === "test"
            ? `${TEST_TALENT_URL}`
            : "/nolink"
        }/jobs/?id=${job?.id}`
      )
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
        setShare(false);
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  const { data, refetch: refetchSavedJobs } = useQuery(GET_SAVED_JOBS, {
    variables: {
      candidateId: user?.id,
      pagination: {
        limit: 50,
        offset: 0,
      },
      sort: {},
    },
  });

  const isSaved = _.some(
    data?.getAllSavedJobs?.rows,
    (saved) => saved?.savedJob?.id === job?.id
  );

  const [saveJob] = useMutation<SaveJobMutation, SaveJobMutationVariables>(
    SAVE_JOB
  );
  const [unSaveJob] = useMutation<
    UnSaveJobMutation,
    UnSaveJobMutationVariables
  >(UNSAVE_JOB);
  const [likeUnlikeJob] = useMutation(LIKE_UNLIKE);

  const handleSaveJob = async () => {
    await saveJob({
      variables: {
        jobId: job.id,
        candidateId: user.id,
      },
      refetchQueries: [
        {
          query: GET_SAVED_JOBS,
          variables: {
            candidateId: user.id,
            pagination: { limit: 20, offset: 0 },
          },
        },
      ],
    })
      .then(({ data }) => {
        if (data) {
          toast(
            JSON.stringify({
              type: "success",
              title: `Job saved successfully`,
            })
          );
          refetchSavedJobs();
        }
      })
      .catch((error) => {
        toast(
          JSON.stringify({
            type: "error",
            title: error?.message || "Error while saving job",
          })
        );
      });
  };

  const handleUnsaveJob = async () => {
    await unSaveJob({
      variables: {
        jobId: job.id,
        candidateId: user.id,
      },
      refetchQueries: [
        {
          query: GET_SAVED_JOBS,
          variables: {
            candidateId: user.id,
            pagination: { limit: 20, offset: 0 },
          },
        },
      ],
    })
      .then(({ data }) => {
        if (data?.unSaveJob) {
          toast(
            JSON.stringify({
              type: "success",
              title: `Job unsaved successfully`,
            })
          );
          refetchSavedJobs();
          if (handleUnsave) handleUnsave(job.id);
        }
      })
      .catch((error) => {
        toast(
          JSON.stringify({
            type: "error",
            title: error?.message || "An error occurred",
          })
        );
      });
  };

  const isLiked = job?.likes?.some((like) => like.userId === user.id);

  const handleLikeUnlike = async () => {
    const likeSound = new Audio(notify);

    const updatedLikes = isLiked
      ? (job.likes || []).filter((like) => like.userId !== user.id)
      : [
          ...(job.likes || []),
          {
            __typename: "Like",
            id: Date.now().toString(),
            userId: user.id,
            jobId: job.id,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          },
        ];

    await likeUnlikeJob({
      variables: {
        jobId: job?.id,
        userId: user?.id,
      },
      optimisticResponse: {
        __typename: "Mutation",
        likeOrUnlikeJob: true,
      },
      update: (cache) => {
        cache.modify({
          id: cache.identify({ __typename: "Job", id: job.id }),
          fields: {
            likes(existingLikes = []) {
              return updatedLikes;
            },
          },
        });
      },
    })
      .then(() => {
        likeSound.volume = 0.5;
        likeSound.play();
      })
      .catch((error) => {
        toast(
          JSON.stringify({
            type: "error",
            title: error?.message || "Unable to like this job",
          })
        );
      });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        shareRef.current &&
        !shareRef.current.contains(event.target as Node)
      ) {
        setShare(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  //
  return (
    <Link
      to={`${
        pathname.current.pathname === "/" ||
        pathname.current.pathname === "/jobs/"
          ? `/jobs/?id=${job?.id}`
          : `/saved/?id=${id}`
      } `}
      className={`relative  border ${
        selected ? "border-primary" : "border-gray-300"
      } hover:border-primary rounded-xl p-5 w-full max-w-[26.25rem] mx-auto cursor-default mb-5`}
    >
      <div className="flex justify-between items-center mb-5">
        <div className="w-8 h-8 bg-white rounded-sm shadow">
          <img
            src={job?.company?.companyLogo ?? LogoLize}
            alt="company-logo"
            className="w-full h-full object-cover"
          />
        </div>

        <button
          onClick={wrapClick(
            !isLoggedIn
              ? () => navigate({ to: "/login" })
              : isSaved
              ? handleUnsaveJob
              : handleSaveJob
          )}
          className={`h-8 w-8 rounded-full border ${
            isSaved ? "border-primary" : "border-gray-400"
          } flex justify-center items-center`}
        >
          {isSaved ? (
            <IoBookmark className={`h-5 w-5 text-primary`} aria-hidden="true" />
          ) : (
            <IoBookmarkOutline
              className={`h-5 w-5 text-gray-400`}
              aria-hidden="true"
            />
          )}
        </button>
      </div>
      <div className="w-full">
        <h1 className="text-base text-black font-semibold my-2 truncate">
          {job?.title ?? "N/A"}
        </h1>
        <p className="font-medium text-xs text-gray-500 w-full truncate">
          {job?.company?.name ?? "Company Name N/A"}
        </p>
        <div className="font-medium flex justify-start gap-2 items-center text-xs text-gray-500 my-2">
          <span className="flex justify-start items-center gap-1 truncate">
            <MapPinIcon className="h-4 w-4" />{" "}
            <span className="flex-1 truncate ">{job?.location ?? "N/A"}</span>
          </span>
          <span className="h-1 w-1 border border-b rounded-full bg-gray-500"></span>
          <span className="text-primary-500 text-nowrap ">
            {moment(job?.createdAt).fromNow()}
          </span>
        </div>
        <div className="font-medium flex justify-start flex-wrap gap-2 text-[10px] mt-5 mb-3 text-black">
          <span className="bg-gray-100 px-2 flex gap-1 justify-start items-center py-1 rounded">
            <BanknotesIcon className="h-4 w-4" />{" "}
            <span className="text-center text-nowrap">
              {!job?.salary || job?.salary?.maximum === "0" ? (
                "Negotiable"
              ) : (
                <>
                  {job?.salary?.currency ?? "N/A"}{" "}
                  {job?.salary?.minimum ?? "N/A"} -{" "}
                  {job?.salary?.maximum ?? "N/A"}{" "}
                  {job?.salary?.frequency ?? "N/A"}
                </>
              )}
            </span>
          </span>
          <span className="bg-gray-100 px-2 flex gap-1 justify-start items-center py-1 rounded  truncate">
            <BriefcaseIcon className="h-4 w-4" />
            <span className="text-center"> {job?.type ?? "N/A"} </span>
          </span>
          <span className="bg-gray-100 px-2 flex gap-1 justify-start items-center py-1 rounded truncate">
            <TbBuildingCottage className="h-4 w-4" />{" "}
            <span className="text-center ">{job?.mode ?? "N/A"}</span>
          </span>
        </div>
        {job?.status === "ACTIVE" && (
          <div className="flex w-full items-center justify-start my-2">
            <span className="font-medium bg-[#FFF2E6] px-2 flex gap-1 justify-start items-center py-1 rounded text-[#F28F16] text-[10px]">
              <FireIcon className="h-4 w-4" /> Actively Hiring
            </span>
          </div>
        )}
      </div>
      <p
        dangerouslySetInnerHTML={{ __html: job?.about || "" }}
        className="text-sm text-[#1E1E1E] h-24 overflow-hidden my-4"
      />

      {/* <div className="flex items-center justify-between text-xs my-2 border-y py-2 text-black">
        <span>
          {job?.likes?.length ?? "0"}{" "}
          {job?.likes?.length > 1 ? "likes" : "like"}
        </span>
        <span className="flex gap-2">
          <span>
            {job?.comments?.length ?? "0"}{" "}
            {job?.comments?.length > 1 ? "comments" : "comment"}
          </span>
          <span>.{job?.shares} </span>
        </span>
      </div> */}
      <div className="flex items-center justify-between mt-8 border-t border-gray-300 pt-4">
        <div className="flex items-center gap-2">
          <button
            onClick={wrapClick(
              !isLoggedIn ? () => navigate({ to: "/login" }) : handleLikeUnlike
            )}
            className="flex-1 text-xs flex justify-start items-center gap-1 text-gray-500 hover:text-gray-800 cursor-pointer"
          >
            {isLiked ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-5 text-primary"
                >
                  <path d="M7.493 18.5c-.425 0-.82-.236-.975-.632A7.48 7.48 0 0 1 6 15.125c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75A.75.75 0 0 1 15 2a2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23h-.777ZM2.331 10.727a11.969 11.969 0 0 0-.831 4.398 12 12 0 0 0 .52 3.507C2.28 19.482 3.105 20 3.994 20H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 0 1-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227Z" />
                </svg>
                {/* <p className="text-primary">Unlike</p> */}
              </>
            ) : (
              <>
                <HandThumbUpIcon className="h-5 w-5 text-[#8A8A8A]" />
                {/* <p className="text-[#8A8A8A]">Like</p> */}
              </>
            )}
            <span className="font-montserrat font-medium text-sm text-[#8A8A8A]">
              {job?.likes?.length ?? "0"}{" "}
              {job?.likes?.length > 1 || job?.likes?.length === 0
                ? "likes"
                : "like"}
            </span>
          </button>
        </div>

        {/* <button
          onClick={wrapClick(() => setCommentModal(true))}
          className="flex-1 text-xs flex justify-center items-center gap-1 text-gray-500 hover:text-gray-800 cursor-pointer"
        >
          <TbMessageDots className="h-5 w-5" />
          <p>Comment</p>
        </button> */}
        <button
          ref={shareRef}
          onClick={wrapClick(() => setShare(!share))}
          className="flex-1 text-xs flex justify-end items-center gap-1 text-gray-500 hover:text-gray-800 cursor-pointer transition-all duration-150 ease-in-out"
        >
          {copied ? (
            <RiCheckDoubleFill className="h-4 w-4 text-primary" />
          ) : (
            <RiShareForward2Fill className="h-4 w-4 text-[#8A8A8A]" />
          )}
          <p
            className={`font-medium ${
              copied ? "text-primary" : "text-[#8A8A8A]"
            }`}
          >
            {copied ? "Copied" : "Share"}
          </p>
        </button>
        <Show if={share}>
          <div className="absolute z-10 -bottom-10 right-12 rounded-md py-2.5 px-2 text-black bg-white shadow-xl border border-black/5">
            {/* <button
              className="hover:bg-gray-50 p-2 flex items-center justify-start gap-2 w-full border-b border-[#F0F0F0]"
              onClick={() => setShare(false)}
            >
              <ChatBubbleLeftRightIcon className="h-4 w-4" />
              <p className="text-xs text-start flex-1">
                Share via Direct Message
              </p>
            </button> */}
            <button
              className="hover:bg-gray-50 py-2 px-5 flex items-center justify-start gap-2 w-full"
              onClick={wrapClick(copyToClipboard)}
            >
              <LinkIcon className="h-4 w-4" />
              <p className="text-xs text-start flex-1">Copy link</p>
            </button>
          </div>
        </Show>
      </div>
      <CommentModalContainer
        open={commentModal}
        setOpen={setCommentModal}
        job={job}
      />
    </Link>
  );
};

export default JobComponent;
