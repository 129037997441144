import { gql } from "@apollo/client";

export const SAVE_JOB = gql`
  mutation SaveJob($jobId: ID!, $candidateId: ID!) {
    saveJob(jobId: $jobId, candidateId: $candidateId)
  }
`;

export const UNSAVE_JOB = gql`
  mutation UnSaveJob($jobId: ID!, $candidateId: ID!) {
    unSaveJob(jobId: $jobId, candidateId: $candidateId)
  }
`;

export const APPLY_JOB = gql`
  mutation ApplyJob($input: ApplyJobInput) {
    applyJob(input: $input)
  }
`;

export const WITHDRAW_APPLICATION = gql`
  mutation WithdrawApplication($applicationId: ID!, $jobId: ID!) {
    withdrawApplication(applicationId: $applicationId, jobId: $jobId)
  }
`;

export const LIKE_UNLIKE = gql`
  mutation LikeOrUnlikeJob($jobId: ID!, $userId: ID!) {
    likeOrUnlikeJob(jobId: $jobId, userId: $userId)
  }
`;

export const COMMENT_JOB = gql`
  mutation CommentJob($jobId: ID!, $userId: ID!, $comment: String!) {
    commentJob(jobId: $jobId, userId: $userId, comment: $comment)
  }
`;
