/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";
//import auth from "../../services/cookie-config";

interface initialState {
  isLoading: boolean;
  isSignout: boolean;
  user: any;
}

const initialState: initialState = {
  isLoading: true,
  isSignout: false,
  user: null, // Add user field to store user data
};

// Create an authentication slice using Redux Toolkit
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    restoreToken: (state, action) => {
      if (action.payload && action.payload.token) {
        state.user = action.payload.user; // Store the user data
      }
      state.isLoading = false; // Set isLoading to false regardless of data presence
    },
    signIn: (state, action) => {
      state.isSignout = false;
      console.log("action.payload", action.payload);
      const user = encodeURIComponent(action.payload.user); // Store the user data
      //const user = action.payload.user;
      console.log("user", user);
      state.user = user;
    },
    signUp: (state, action) => {
      state.isSignout = false;
      const user = encodeURIComponent(action.payload.user); // Store the user data
      state.user = user;
    },
    signOut: (state) => {
      state.isLoading = false;
      state.user = null; // Clear user data when signing out
      state.isSignout = true;
      //auth.clearCipher(); // Clear the cipher from the cookie
    },
  },
});

export const { restoreToken, signIn, signOut, signUp } = authSlice.actions;

// Create selectors to access the authentication state and user data
export const selectAuth = (state: { auth: any }) => state.auth;
export const selectUser = (state: { auth: { user: any } }) => state.auth.user;

// Export the reducer for use in the Redux store configuration
export default authSlice.reducer;
