import { Button } from "@/components/buttons";
import Modal from "@/components/layouts/modal";
import { FC, useEffect, useState } from "react";
import FilterChecker from "./filter-checker";
import List from "@/components/core/list";
import {
  datePostedFilter,
  jobTypeFilter,
  locationFilter,
} from "../data/filter";
import FilterRadio from "./filter-radio";
import { getDateFilterRange } from "@/utils/date-filter-range";

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  handleSubmit: any;
  onData: any;
};

const FilterModalContainer: FC<Props> = ({
  open,
  setOpen,
  onData,
  handleSubmit,
}) => {
  const [selectedJobTypes, setSelectedJobTypes] = useState<string[]>([]);
  const [selectedDatePosted, setSelectedDatePosted] = useState<string | null>(
    null
  );
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  

  const handleJobTypeChange = (id: string) => {
    setSelectedJobTypes((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleDatePostedChange = (id: string) => {
    setSelectedDatePosted(id);
  };

  const handleLocationChange = (id: string) => {
    setSelectedLocations((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // const filterId = 'thisWeek'
  // console.log();

  const applyFilters = () => {
    const selectedFilters = [
      selectedJobTypes,
      selectedDatePosted ? getDateFilterRange(selectedDatePosted) : [],
      selectedLocations,
    ];
    // console.log("Selected Filters:", selectedFilters);
    onData(selectedFilters);
    // Perform actions with selectedFilters
    // setOpen(false);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedJobTypes, selectedDatePosted, selectedLocations]);

  return (
    <Modal
      title="Filters"
      open={open}
      setOpen={setOpen}
      renderActions={() => (
        <Button
          disabled={
            selectedJobTypes.length === 0 &&
            !selectedDatePosted &&
            selectedLocations.length === 0
          }
          className="!w-[8.75rem] h-10"
          onClick={handleSubmit}
        >
          Apply Filters
        </Button>
      )}
    >
      <div className="w-full grid grid-cols-3 gap-3 mt-6">
        <fieldset>
          <legend className="text-sm font-medium text-black ">Job Type</legend>
          <div className="w-full mt-3 space-y-5">
            <List
              data={jobTypeFilter}
              renderItem={(data) => (
                <FilterChecker
                  id={data?.id}
                  name={data?.name}
                  title={data?.title}
                  value={selectedJobTypes.includes(data.id)}
                  onChange={() => handleJobTypeChange(data.id)}
                />
              )}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="text-sm font-medium text-black">
            Date Posted
          </legend>
          <div className="w-full mt-3 space-y-5">
            <List
              data={datePostedFilter}
              renderItem={(data) => (
                <FilterRadio
                  id={data?.id}
                  name="datePosted"
                  title={data?.title}
                  checked={selectedDatePosted === data.id}
                  onChange={() => handleDatePostedChange(data.id)}
                />
              )}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend className="text-sm font-medium text-black">Mode</legend>
          <div className="w-full mt-3 space-y-5">
            <List
              data={locationFilter}
              renderItem={(data) => (
                <FilterRadio
                  id={data?.id}
                  name="location"
                  title={data?.title}
                  checked={selectedLocations.includes(data.id)}
                  onChange={() => handleLocationChange(data.id)}
                />
              )}
            />
          </div>
        </fieldset>
      </div>
    </Modal>
  );
};

export default FilterModalContainer;
