import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect } from "react";
import Modal from "@/components/layouts/modal";

import ButtonLoader from "@/components/loaders/button";
import { UPDATE_ABOUT_ME } from "@/apollo/graphql/mutations/profile";
import {
  UpdateAboutMeMutation,
  UpdateAboutMeMutationVariables,
} from "@/apollo/graphql/generated/types";

const AboutMe = ({ visible, onClose, refetch, data }: any) => {
  const [updateABoutMe, { loading }] = useMutation<
    UpdateAboutMeMutation,
    UpdateAboutMeMutationVariables
  >(UPDATE_ABOUT_ME);

  const form = useFormik({
    initialValues: {
      profileSummary: data?.profileSummary || "",
    },
    validate: (values) => {
      const errors: any = {};
      const wordCount = values.profileSummary.trim().split(/\s+/).length;
      if (wordCount > 120) {
        errors.profileSummary = `Profile summary should not exceed 120 words. Currently, it has ${wordCount} words.`;
      }
      return errors;
    },
    onSubmit: async (values) => {
      const response = await updateABoutMe({
        variables: {
          profileSummary: values.profileSummary,
        },
      })
        .then(({ data }) => {
          onClose();
          refetch();
          toast(
            JSON.stringify({
              type: "success",
              title: `Profile successfully updated`,
            })
          );
        })
        .catch((error) => {
          toast(
            JSON.stringify({
              type: "error",
              title: error?.message || "Error while updating Profile",
            })
          );
        });
    },
  });

  useEffect(() => {
    form.setValues({
      profileSummary: data?.profileSummary || "",
    });
  }, [data]);

  const wordCount = form.values.profileSummary.trim().split(/\s+/).length;

  if (!visible) return null;

  return (
    <Modal
      open={visible}
      setOpen={() => {
        onClose();
      }}
      hideActions={false}
      description="About Me"
      renderActions={() => (
        <>
          <button
            disabled={loading}
            onClick={() => {
              form.handleSubmit();
            }}
            className="w-[8rem] h-[2.5rem] flex justify-center items-center font-montserrat font-normal
                   text-sm capitalize leading-5 text-center
                    bg-primary rounded-lg text-white
                    border border-primary "
            type="button"
          >
            {loading ? <ButtonLoader title="Saving..." /> : " Save changes"}
          </button>
        </>
      )}
    >
      {!data?._id ? (
        <div className="mt-5 ">
          <h4
            className="font-montserrat font-normal text-base leading-[19.2px] tracking-[0.1px]
        text-black-2 mb-4"
          >
            Details about yourself
          </h4>

          <textarea
            className="select-no-outline focus:!border-gray-400 w-full h-[12.938rem] p-4 border border-grey-400
        rounded-lg text-black font-montserrat text-xs leading-[14.4px]
        tracking-[0.1px] bg-transparent focus:!outline-none resize-none
         focus:bg-transparent "
            id="about"
            {...form.getFieldProps("profileSummary")}
          ></textarea>
          <div className="mt-2 text-right text-sm text-gray-500">
            {wordCount} / 120 words
          </div>
          {typeof form.errors.profileSummary === "string" && (
            <div className="mt-1 text-red-600 text-sm">
              {form.errors.profileSummary}
            </div>
          )}
        </div>
      ) : (
        <div className="flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6">
          <div>
            <h1>NO DATA</h1>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AboutMe;
