import { FC } from 'react';

type Props = {
  id: string;
  name: string;
  title: string;
  value: boolean;
  onChange: () => void;
};

const FilterChecker: FC<Props> = ({ id, name, title, value, onChange }) => {
  return (
    <div className='relative flex items-center'>
      <div className='flex h-6 items-center'>
        <input
          id={id}
          aria-describedby={`filter-by-${name}`}
          name={name}
          checked={value}
          onChange={onChange}
          type='checkbox'
          className='h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600'
        />
      </div>
      <div className='ml-3 text-xs'>
        <label htmlFor={id} className=' text-gray-600 font-medium '>
          {title}
        </label>
      </div>
    </div>
  );
};

export default FilterChecker;