import { FC, PropsWithChildren } from "react";
import { Router } from "react-location";
import { useReactiveVar } from "@apollo/client";
import routes from "./routes";
import location from "./location";
// import filterRoutes from "./filter";
import { currentUserVar } from "../apollo/cache/auth";

const RoutesProvider: FC<PropsWithChildren> = ({ children }) => {
  const currentUser = useReactiveVar(currentUserVar);

  return (
    <div>
      <Router location={location} routes={routes}>
        {children}
      </Router>
    </div>
  );
};

export default RoutesProvider;
