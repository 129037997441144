import { FC } from 'react';

type Props = {
  id: string;
  title: string;
  name: string;
  checked: boolean;
  onChange: () => void;
};

const FilterRadio: FC<Props> = ({ id, title, name, checked, onChange }) => {
  return (
    <div key={id} className='flex items-center'>
      <input
        id={id}
        name={name}
        type='radio'
        checked={checked}
        onChange={onChange}
        className='h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600'
      />
      <label htmlFor={id} className='ml-3 block text-xs text-gray-600 font-medium '>
        {title}
      </label>
    </div>
  );
};

export default FilterRadio;