import {
  addDays,
  format,
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";

export const datePostedFilter = [
  {
    id: "any",
    title: "Any",
  },
  {
    id: "today",
    title: "Today",
  },
  {
    id: "thisWeek",
    title: "This Week",
  },
  {
    id: "lastWeek",
    title: "Last Week",
  },
  {
    id: "lastTwoWeeks",
    title: "Last 2 Weeks",
  },
  {
    id: "lastMonth",
    title: "Last Month",
  },
];

export const getDateFilterRange = (filterId: string) => {
  const today = new Date();

  switch (filterId) {
    case "today":
      const formattedToday = format(today, "yyyy-MM-dd");
      return [formattedToday, formattedToday];

    case "thisWeek":
      const startOfThisWeek = startOfWeek(today);
      const endOfThisWeek = endOfWeek(today);
      return [
        format(startOfThisWeek, "yyyy-MM-dd"),
        format(endOfThisWeek, "yyyy-MM-dd"),
      ];

    case "lastWeek":
      const startOfLastWeek = subDays(startOfWeek(today), 7);
      const endOfLastWeek = subDays(endOfWeek(today), 7);
      return [
        format(startOfLastWeek, "yyyy-MM-dd"),
        format(endOfLastWeek, "yyyy-MM-dd"),
      ];

    case "lastTwoWeeks":
      const startOfLastTwoWeeks = subDays(startOfWeek(today), 14);
      const endOfLastTwoWeeks = subDays(endOfWeek(today), 7);
      return [
        format(startOfLastTwoWeeks, "yyyy-MM-dd"),
        format(endOfLastTwoWeeks, "yyyy-MM-dd"),
      ];

    case "lastMonth":
      const startOfLastMonth = startOfMonth(subDays(today, 30));
      const endOfLastMonth = endOfMonth(subDays(today, 30));
      return [
        format(startOfLastMonth, "yyyy-MM-dd"),
        format(endOfLastMonth, "yyyy-MM-dd"),
      ];

    case "any":
    default:
      return ["any"];
  }
};
