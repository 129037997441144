import { Route, SearchPredicate } from "react-location";
import {
  BellIcon,
  BookmarkSquareIcon,
  BriefcaseIcon,
  ChatBubbleLeftRightIcon,
  CheckCircleIcon,
  ClockIcon,
  Cog6ToothIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import Signin from "../pages/signin";
import Signup from "../pages/signup";
import Onboarding from "../pages/onboarding";
// import ForgotPassword from "../pages/forgot-password";
import Terms from "../pages/terms";
import Suspended from "../pages/suspended";

import JobHuntPage from "../pages/job-hunt";
import SavedJobsPage from "@/pages/saved-jobs";
// import MostRecentJobPage from '@/pages/most-recent';
import ProfilePage from "@/pages/profile";
import SettingsPage from "@/pages/settings";
import NotificationsPage from "@/pages/notification";
import MessagePage from "@/pages/messages";
import FeedsPage from "@/pages/feeds";
import NotificationDetail from "@/pages/notification/details";
import AppliedJobsPage from "@/pages/applied-jobs";
import { LocationGenerics } from "./location";
import JobDetail from "@/pages/job-hunt/detail";
import SavedJobDetail from "@/pages/saved-jobs/detail";
import ForgotPassword from "@/pages/forgot-password";
import NotFound from "@/pages/404/not-found";

export type RouteProps = Omit<Route, "children"> & {
  navigation?: boolean;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
  search?: SearchPredicate<LocationGenerics>;
};

const routes: RouteProps[] = [
  {
    path: "/",
    element: <JobHuntPage />,
    meta: {
      layout: "App",
    },
    sidebar: {
      label: "Job Hunt",
      icon: BriefcaseIcon,
    },
  },
  {
    path: "/jobs/:id",
    element: <JobDetail />,
    meta: {
      layout: "App",
    },
  },

  {
    path: "feeds",
    element: <FeedsPage />,
    meta: {
      layout: "Unauth",
    },
  },

  // {
  //   path: 'most-recent',
  //   element: <MostRecentJobPage />,
  //   meta: {
  //     layout: 'App',
  //   },
  //   sidebar: {
  //     label: 'Most Recent',
  //     icon: ClockIcon,
  //   },
  // },
  {
    path: "saved-jobs",
    element: <SavedJobsPage />,
    meta: {
      layout: "App",
    },
    sidebar: {
      label: "Saved Jobs",
      icon: BookmarkSquareIcon,
    },
  },
  {
    path: "/saved/:id",
    element: <SavedJobDetail />,
    meta: {
      layout: "App",
    },
  },

  {
    path: "applied-jobs",
    element: <AppliedJobsPage />,
    meta: {
      layout: "App",
    },
    sidebar: {
      label: "Applied Jobs",
      icon: CheckCircleIcon,
    },
    // search: (search: any) => ({
    //   id: search.id as string | undefined,
    // }),
  },
  {
    path: "messages",
    element: <MessagePage />,
    meta: {
      layout: "App",
    },
    sidebar: {
      label: "Messages",
      icon: ChatBubbleLeftRightIcon,
    },
  },
  {
    path: "notifications",
    element: <NotificationsPage />,
    meta: {
      layout: "App",
    },
    sidebar: {
      label: "Notifications",
      icon: BellIcon,
    },
  },

  {
    path: "settings",
    element: <SettingsPage />,
    meta: {
      layout: "App",
    },
    sidebar: {
      label: "Settings",
      icon: Cog6ToothIcon,
    },
  },
  {
    path: "profile",
    element: <ProfilePage />,
    meta: {
      layout: "App",
    },
    sidebar: {
      label: "My Profile",
      icon: UserCircleIcon,
    },
  },
  {
    path: "login",
    element: <Signin />,
    meta: {
      layout: "Auth",
    },
  },
  {
    path: "signup",
    element: <Signup />,
    meta: {
      layout: "Auth",
    },
  },
  {
    path: "terms",
    element: <Terms />,
    meta: {
      layout: "Auth",
    },
  },
  {
    path: "onboarding",
    element: <Onboarding />,
    meta: {
      layout: "Auth",
    },
  },

  {
    path: "password",
    element: <ForgotPassword />,
    meta: {
      layout: "Auth",
    },
  },
  {
    path: "suspended",
    element: <Suspended />,
    meta: {
      layout: "Auth",
    },
  },
  {
    path: ":id",
    element: <NotificationDetail />,
    meta: {
      layout: "App",
    },
  },
  {
    path: ":not",
    element: <NotFound />,
    meta: {
      layout: "App",
    },
  },
];

export default routes;
