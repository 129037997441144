import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { useCurrentUser } from "@/apollo/cache/auth";
import toast from "react-hot-toast";
import Modal from "@/components/layouts/modal";
import { useFormik } from "formik";
import ButtonLoader from "@/components/loaders/button";
import {
  ADD_PROF_BG,
  UPDATE_PROF_BG,
} from "@/apollo/graphql/mutations/profile";
import AutoCompleteInput from "../input-autocomplete";

const ProfessionalModal = ({ visible, onClose, refetch, data }: any) => {
  const currentUser = useCurrentUser();
  const [loadingModal, setLoadingModal] = useState(false);
  const [currentStudying, setCurrentStudying] = useState(false);
  const [skills, setSkills] = useState(data?.skills || [""]);
  const [errors, setErrors] = useState<string[]>([]);

  const startDatePickerRef = useRef<HTMLInputElement>(null);
  const endDatePickerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (data) {
      setSkills(data.skills || [""]);
      setCurrentStudying(data.isCurrentJob);
      const start = data?.startDate
        ? new Date(data.startDate).toISOString().split("T")[0]
        : "";
      const end = data?.endDate
        ? new Date(data.endDate).toISOString().split("T")[0]
        : "";
      form.setValues({
        profession: data?.jobTitle || "",
        company: data?.companyName || "",
        startDate: start,
        endDate: end,
        skills: data?.skills || [""],
      });
    } else {
      form.resetForm();
      setSkills([""]);
    }
  }, [data]);

  useEffect(() => {
    if (currentStudying) {
      form.setFieldValue("endDate", "");
    }
  }, [currentStudying]);

  const handleInputChange = (index: number, value: string) => {
    const values = [...skills];
    values[index] = value;
    setSkills(values);
    const newErrors = [...errors];
    newErrors[index] = "";
    setErrors(newErrors); // Clear error for this input
  };

  const handleAddInput: any = () => {
    if (skills.length === 0 || skills[skills.length - 1].trim() !== "") {
      setSkills([...skills, ""]);
      setErrors([...errors, ""]); // Add a new error field
    } else {
      const newErrors = [...errors];
      newErrors[skills.length - 1] =
        "Please fill the previous input before adding a new one.";
      setErrors(newErrors);
    }
  };

  const [addProfBg, { loading }] = useMutation(ADD_PROF_BG);
  const [updateProfBg, { loading: updateLoading }] =
    useMutation(UPDATE_PROF_BG);

  interface ProfessionalFormData {
    profession: string;
    company: string;
    startDate: string;
    endDate: string;
    skills: string[];
  }

  const form = useFormik({
    initialValues: {
      profession: "",
      company: "",
      startDate: "",
      endDate: "",
      skills: [],
    },
    onSubmit: async (values: ProfessionalFormData) => {
      const input = {
        companyName: values.company,
        endDate: currentStudying
          ? new Date().toISOString().split("T")[0]
          : values.endDate,
        isCurrentJob: currentStudying,
        jobTitle: values.profession,
        skills: skills,
        startDate: values.startDate,
      };

      if (data) {
        await updateProfBg({
          variables: {
            input: {
              ...input,
              id: data?.id,
            },
          },
        })
          .then(({ data }) => {
            onClose();
            refetch();
            toast(
              JSON.stringify({
                type: "success",
                title: `Profile successfully updated`,
              })
            );
          })
          .catch((error) => {
            toast(
              JSON.stringify({
                type: "error",
                title: error?.message || "Error while updating Profile",
              })
            );
          });
      } else {
        await addProfBg({
          variables: {
            input,
          },
        })
          .then(({ data }) => {
            form.resetForm();
            setSkills([""]);
            setErrors([]);
            onClose();
            refetch();
            toast(
              JSON.stringify({
                type: "success",
                title: `Profile successfully updated`,
              })
            );
          })
          .catch((error) => {
            toast(
              JSON.stringify({
                type: "error",
                title: error?.message || "Error while updating Profile",
              })
            );
          });
      }
    },
  });

  if (!visible) return null;

  return (
    <Modal
      open={visible}
      setOpen={() => {
        onClose();
      }}
      loading={loadingModal}
      hideActions={false}
      description="Professional Background"
      renderActions={() => (
        <>
          <button
            disabled={loading || updateLoading}
            className=" w-[8rem] h-[2.5rem] flex justify-center items-center font-montserrat font-normal
                 text-sm capitalize leading-5 text-center
                  bg-primary rounded-lg text-white
                  border border-primary "
            type="button"
            onClick={() => {
              form.handleSubmit();
            }}
          >
            {loading || updateLoading ? (
              <ButtonLoader title="Saving..." />
            ) : (
              " Save changes"
            )}
          </button>
        </>
      )}
    >
      <div className="relative bg-white mt-3">
        <div className="w-full">
          <div className="mb-3">
            <label
              className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.006rem]"
              htmlFor="profession"
            >
              Profession
            </label>

            <input
              id="profession"
              name="profession"
              type="text"
              onChange={form.handleChange}
              value={form.values?.profession || ""}
              className="input-base"
            />
          </div>
          <div className="mb-3">
            <label
              className="font-montserrat text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
              htmlFor="company"
            >
              Company
            </label>

            <input
              id="company"
              name="company"
              type="text"
              onChange={form.handleChange}
              value={form.values?.company || ""}
              className="input-base"
            />
          </div>

          <div className="mb-3 flex mobile:flex-col mobile:gap-4 justify-between items-center gap-6">
            <div className="w-full flex-1">
              <label
                className="w-full font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.006rem]"
                htmlFor="startDate"
              >
                Start Date
              </label>

              <div className="flex items-center justify-between input-base">
                <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  value={form.values.startDate}
                  onChange={form.handleChange}
                  className="select-no-outline border-none cursor-pointer outline-none w-full text-xs leading-[0.9rem] tracking-[0.006rem]"
                  ref={startDatePickerRef}
                />
                {/* <IoCalendarClearOutline
                  onClick={() => startDatePickerRef.current?.focus()}
                  className="text-gray-400 w-5 h-5 cursor-pointer"
                /> */}
              </div>
            </div>

            <div className="w-full flex-1">
              <label
                className="w-full font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.006rem]"
                htmlFor="endDate"
              >
                End Date
              </label>

              <div className="flex items-center justify-between flex-1 input-base ">
                <input
                  disabled={currentStudying}
                  type="date"
                  name="endDate"
                  id="endDate"
                  value={form.values.endDate}
                  onChange={form.handleChange}
                  className="select-no-outline border-none cursor-pointer outline-none w-full text-xs leading-[0.9rem] tracking-[0.006rem]"
                  ref={endDatePickerRef}
                />
                {/* <IoCalendarClearOutline
                  onClick={() => endDatePickerRef.current?.focus()}
                  className=" text-gray-400 w-5 h-5 cursor-pointer"
                /> */}
              </div>
            </div>
          </div>

          <div className="flex items-center mt-3 mb-5">
            <input
              onChange={() => {
                setCurrentStudying(!currentStudying);
              }}
              type="checkbox"
              checked={currentStudying}
              required
              className="peer mr-2 cursor-pointer text-primary border border-gray-500 focus:ring-0 rounded"
            />
            <label
              className="text-gray-800 text-sm  font-medium"
              htmlFor="remember"
            >
              I am currently working here
            </label>
          </div>

          <div>
            <label
              className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.006rem]"
              htmlFor="skills"
            >
              Skills
            </label>

            {skills.map((skill: string, index: number) => (
              <div key={index}>
                <AutoCompleteInput
                  value={skill}
                  onChange={(value) => handleInputChange(index, value)}
                />
                {errors[index] && (
                  <p className="font-medium text-red-600 text-xs mt-1">
                    {errors[index]}
                  </p>
                )}
              </div>
            ))}

            <button
              className="bg-[#F5FFF5] w-[7.688rem] h-[2.5rem] flex justify-center items-center font-montserrat text-sm text-primary 
              rounded-lg ring-1 ring-[#F5FFF5] mt-2"
              type="button"
              onClick={handleAddInput}
            >
              <span className="text-2xl mr-2">+</span>Add Skill
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProfessionalModal;
