//auth
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const FORGOT_PASSWORD = "/forgot-password";
export const VERIFY_EMAIL = "/auth/verify-email";
export const RESET_PASSWORD = "/auth/reset-password";
export const PASSWORD_RESET = "/auth/password-reset";
export const ONBOARDING = "/onboarding";

// for main pages
export const HOME = "/";
export const MAIN_LAYOUT = "/";
export const SAVED_JOBS = "/my-jobs";
export const JOB_DETAILS = "/job-details";
export const PROFILE = "/profile";
export const NOTIFICATION = "/notifications";
export const SETTINGS = "/settings";
export const NOTIFICATION_DETAILS = "/notification-details";

export const DEV_EMPLOYER_URL = "https://test.employer.adjuma.io";
export const PROD_EMPLOYER_URL = "https://employer.adjuma.io";
export const PROD_TALENT_URL = "https://talent.adjuma.io";
export const TEST_EMPLOYER_URL = "https://test.employer.adjuma.io";
export const TEST_TALENT_URL = "https://test.talent.adjuma.io";
export const DEV_TALENT_URL = "https://test.talent.adjuma.io";
export const DEV_LANDING_URL = "https://staging.adjuma.io";
export const TEST_LANDING_URL = "https://staging.adjuma.io";
export const PROD_LANDING_URL = "https://adjuma.io";
