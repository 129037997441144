import { gql } from "@apollo/client";

export const GET_CONVERSATIONS = gql`
  query GetConversations($userId: ID!, $pagination: Pagination!) {
    getConversations(userId: $userId, pagination: $pagination) {
      id
      talent {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      employer {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      participants {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      jobId
      numberOfUnreadMessagesByTalent
      numberOfUnreadMessagesByEmployer
      lastMessageDetails {
        id
        message
        status
        user {
          id
          userId
          onlineStatus
          userType
          fullName
          email
          profilePicture
        }
        messageType
        parentMessage {
          id
          message
          status
          messageType
          createdAt
          updatedAt
        }
        read
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_CONVERSATION = gql`
  query GetConversation($getConversationId: ID!) {
    getConversation(id: $getConversationId) {
      id
      employer {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      talent {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      jobId
      numberOfUnreadMessagesByTalent
      numberOfUnreadMessagesByEmployer
      lastMessageDetails {
        id
        message
        status
        user {
          id
          userId
          onlineStatus
          userType
          fullName
          email
          profilePicture
        }
        messageType
        parentMessage {
          id
          message
          status
          messageType

          createdAt
          updatedAt
        }
        read

        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_MESSAGES = gql`
  query GetMessages($conversationId: ID!, $pagination: Pagination) {
    getMessages(conversationId: $conversationId, pagination: $pagination) {
      id
      message
      status
      user {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      messageType
      parentMessage {
        id
        message
        status
        messageType
        read
        senderType
        createdAt
        updatedAt
      }
      read
      senderType
      createdAt
      updatedAt
    }
  }
`;

// Mutations

export const CLEAR_CONVERSATION_HISTORY = gql`
  mutation ClearChatHistory($conversationId: ID!) {
    clearChatHistory(conversationId: $conversationId)
  }
`;

export const DELETE_CONVERSATION = gql`
  mutation DeleteConversation($conversationId: ID!, $userId: ID!) {
    deleteConversation(conversationId: $conversationId, userId: $userId)
  }
`;

export const REPORT_CONVERSATION = gql`
  mutation ReportConversation($conversationId: ID!, $reason: String!) {
    reportConversation(conversationId: $conversationId, reason: $reason)
  }
`;

export const MARK_AS_READ = gql`
  mutation MarkMessageAsRead($messageId: ID!, $senderType: UserType!) {
    markMessageAsRead(messageId: $messageId, senderType: $senderType) {
      id
      message
      status
      user {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      messageType
      parentMessage {
        id
        message
        status
        messageType
        read

        createdAt
        updatedAt
      }
      read
      createdAt
      updatedAt
    }
  }
`;

export const MARK_ALL_AS_READ = gql`
  mutation MarkAllMessagesAsRead($conversationId: ID!, $senderType: UserType!) {
    markAllMessagesAsRead(
      conversationId: $conversationId
      senderType: $senderType
    )
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage(
    $conversationId: ID!
    $senderId: ID!
    $message: String!
  ) {
    sendMessage(
      conversationId: $conversationId
      senderId: $senderId
      message: $message
    ) {
      id
      message
      status
      user {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      messageType
      parentMessage {
        id
        message
        status
        messageType
        read

        createdAt
        updatedAt
      }
      read
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  mutation CreateConversation(
    $participants: [ParticipantsInput!]!
    $jobId: ID!
  ) {
    createConversation(participants: $participants, jobId: $jobId) {
      id
      employer {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      talent {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      jobId
      numberOfUnreadMessagesByTalent
      numberOfUnreadMessagesByEmployer
      lastMessageDetails {
        id
        message
        status
        user {
          id
          userId
          onlineStatus
          userType
          fullName
          email
          profilePicture
        }
        messageType
        parentMessage {
          id
          message
          status
          messageType

          createdAt
          updatedAt
        }
        read
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

// subscription

export const CONVERSATION_DELETED = gql`
  subscription ConversationDeleted {
    conversationDeleted {
      id
      employer {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      talent {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      jobId
      numberOfUnreadMessagesByTalent
      numberOfUnreadMessagesByEmployer
      lastMessageDetails {
        id
        message
        status
        user {
          id
          userId
          onlineStatus
          userType
          fullName
          email
          profilePicture
        }
        messageType
        parentMessage {
          id
          message
          status
          messageType

          createdAt
          updatedAt
        }
        read

        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const CONVERSATION_CREATED = gql`
  subscription ConversationCreated {
    conversationCreated {
      id
      employer {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      talent {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      jobId
      numberOfUnreadMessagesByTalent
      numberOfUnreadMessagesByEmployer
      lastMessageDetails {
        id
        message
        status
        user {
          id
          userId
          onlineStatus
          userType
          fullName
          email
          profilePicture
        }
        messageType
        parentMessage {
          id
          message
          status
          messageType

          createdAt
          updatedAt
        }
        read

        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const CONVERSATIONS_UPDATED = gql`
  subscription ConversationUpdated {
    conversationUpdated {
      id
      employer {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      talent {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      jobId
      numberOfUnreadMessagesByTalent
      numberOfUnreadMessagesByEmployer
      lastMessageDetails {
        id
        message
        status
        user {
          id
          userId
          onlineStatus
          userType
          fullName
          email
          profilePicture
        }
        messageType
        parentMessage {
          id
          message
          status
          messageType

          createdAt
          updatedAt
        }
        read

        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const MESSAGE_ADDED = gql`
  subscription MessageAdded {
    messageAdded {
      id
      message
      status
      user {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      messageType
      parentMessage {
        id
        message
        status
        messageType
        read

        createdAt
        updatedAt
      }
      read
      createdAt
      updatedAt
    }
  }
`;

export const MESSAGE_READ = gql`
  subscription MessageRead {
    messageRead {
      id
      message
      status
      user {
        id
        userId
        onlineStatus
        userType
        fullName
        email
        profilePicture
      }
      messageType
      parentMessage {
        id
        message
        status
        messageType
        read

        createdAt
        updatedAt
      }
      read
      createdAt
      updatedAt
    }
  }
`;
