import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation CandidateLogin($email: String!, $password: String!) {
    candidateLogin(email: $email, password: $password) {
      token
      user {
        id
        fullName
        email
        phoneNumber
        profileSummary
        avatar
        address
        socialLinks {
          id
          name
          link
        }
        education {
          id
          programme
          school
          startDate
          endDate
          isCurrentlyStudying
        }
        references {
          id
          fullName
          company
          position
          email
          phoneNumber
        }
        professionalBg {
          id
          companyName
          jobTitle
          startDate
          endDate
          isCurrentJob
          skills
        }
        portfolio {
          id
          website
          resume
        }
        skills
        achievements {
          title
          description
        }
        acceptedTermsAndConditions
        loginMeta {
          lastLoginAt
          lastLoginIp
          lastLoginLocation
          createdAt
          updatedAt
        }
        accountMeta {
          isPhoneNumberVerified
          isEmailVerified
          onBoardingStatus
          authType
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const LOGIN_USER_SOCIAL = gql`
  mutation SocialAuthLogin($input: SocialAuthInput!) {
    socialAuthLogin(input: $input) {
      user {
        id
        fullName
        email
        phoneNumber
        profileSummary
        avatar
        address
        socialLinks {
          id
          name
          link
        }
        education {
          id
          programme
          school
          startDate
          endDate
          isCurrentlyStudying
        }
        references {
          id
          fullName
          company
          position
          email
          phoneNumber
        }
        professionalBg {
          id
          companyName
          jobTitle
          startDate
          endDate
          isCurrentJob
          skills
        }
        portfolio {
          id
          website
          resume
        }
        skills
        achievements {
          id
          title
          description
          date
        }
        acceptedTermsAndConditions
        loginMeta {
          lastLoginAt
          lastLoginIp
          lastLoginLocation
          createdAt
          updatedAt
        }
        accountMeta {
          isPhoneNumberVerified
          isEmailVerified
          onBoardingStatus
          authType
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      token
    }
  }
`;

export const SIGNUP_USER = gql`
  mutation CreateCandidateAccount($input: CreateCandidateAccountInput!) {
    createCandidateAccount(input: $input) {
      token
      user {
        id
        fullName
        email
        phoneNumber
        profileSummary
        avatar
        address
        socialLinks {
          id
          name
          link
        }
        education {
          id
          programme
          school
          startDate
          endDate
          isCurrentlyStudying
        }
        references {
          id
          fullName
          company
          position
          email
          phoneNumber
        }
        professionalBg {
          id
          companyName
          jobTitle
          startDate
          endDate
          isCurrentJob
          skills
        }
        portfolio {
          id
          website
          resume
        }
        skills
        achievements {
          id
          title
          description
        }
        acceptedTermsAndConditions
        loginMeta {
          lastLoginAt
          lastLoginIp
          lastLoginLocation
          createdAt
          updatedAt
        }
        accountMeta {
          isPhoneNumberVerified
          isEmailVerified
          onBoardingStatus
          authType
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const ONBOARD_TALENT = gql`
  mutation OnBoardTalent($input: OnBoardTalentInput!) {
    onBoardTalent(input: $input)
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateCandidateAccount($input: UpdateCandidateAccountInput!) {
    updateCandidateAccount(input: $input)
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation ResetTalentPassword(
    $userId: ID!
    $confirmedPassword: String!
    $newPassword: String!
    $oldPassword: String!
  ) {
    resetTalentPassword(
      userId: $userId
      confirmedPassword: $confirmedPassword
      newPassword: $newPassword
      oldPassword: $oldPassword
    )
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation VerifyTalentExistence($email: String!) {
    verifyTalentExistence(email: $email) {
      id
    }
  }
`;

export const VERIFY_OPT = gql`
  mutation VerifyCodeForPasswordResetTalent($userId: ID!, $code: String!) {
    verifyCodeForPasswordResetTalent(userId: $userId, code: $code) {
      token
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetTalentPasswordAfterVerification(
    $newPassword: String!
    $confirmedPassword: String!
  ) {
    resetTalentPasswordAfterVerification(
      newPassword: $newPassword
      confirmedPassword: $confirmedPassword
    )
  }
`;
