import {
  JobSeekerQuery,
  JobSeekerQueryVariables,
} from "@/apollo/graphql/generated/types";
import { GET_PROFILE } from "@/apollo/graphql/queries/profile";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";

export const profileComplete = () => {
  const { data, refetch, loading } = useQuery<
    JobSeekerQuery,
    JobSeekerQueryVariables
  >(GET_PROFILE);

  useEffect(() => {
    refetch();
  }, [data]);

  const user = data?.jobSeeker;

  const isNotComplete =
    user?.profileSummary === "" ||
    user?.education?.length === 0 ||
    // user?.references?.length === 0 ||
    user?.professionalBg?.length === 0 ||
    user?.portfolio?.length === 0;
  // user?.achievements?.length === 0;

  if (!loading) {
    return isNotComplete;
  }
};
