import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const issues = [
  "It is a spam",
  "Inappropriate content",
  "Harassment or Bullying",
  "Misinformation or Fake News",
  "Inappropriate content",
  "Impersonation",
];

export default function ReportModal({ open, setOpen }: Props) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative mobile:z-20 z-40"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black mobile:bg-opacity-0 bg-opacity-40 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full mobile:items-start items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative mobile:min-h-full mobile:h-screen transform overflow-hidden mobile:rounded-none rounded-lg bg-white mobile:px-0 px-4 pb-4 mobile:pt-20 pt-5 text-left mobile:shadow-none shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div
                    className="flex mobile:justify-start mobile:gap-4 justify-between items-center 
                  mobile:border-b border-gray-300 mobile:pb-4 mobile:px-4"
                  >
                    <button
                      type="button"
                      className="hidden mobile:block"
                      onClick={() => setOpen(false)}
                    >
                      <BiArrowBack className="w-5 h-5 text-black " />
                    </button>
                    <h3 className="font-montserrat font-medium text-base text-black">
                      Report an issue
                    </h3>
                    <button
                      type="button"
                      className="mobile:hidden focus:ring-2 ring-gray-500 p-[2px] rounded-md"
                      onClick={() => setOpen(false)}
                    >
                      <AiOutlineCloseCircle className="w-5 h-5 text-[#51526C] " />
                    </button>
                  </div>

                  <div className="mobile:px-4">
                    <p
                      className="font-montserrat font-medium text-base text-gray-400 mt-2 border-b border-gray-300 pb-4 
                  mobile:pt-8"
                    >
                      What is the issue with this conversation ? Kindly assist
                      us to understand the problem.
                    </p>
                  </div>

                  <div className="mb-16 mobile:px-4">
                    {issues.map((issue) => (
                      <div
                        key={issue}
                        className="py-4 cursor-pointer hover:bg-[#F5F6F9] border-b border-gray-300"
                      >
                        <p className="font-montserrat font-medium text-sm text-black">
                          {issue}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}


// clear later