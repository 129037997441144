import { Dialog, Menu, Transition } from "@headlessui/react";
import emailFooter from "@/assets/images/email-footer.png";
import logo from "../../assets/images/logo.png";
import notificationPic from "../../assets/images/no-notif.svg";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from "@/constants/socials";
import { CgTrash } from "react-icons/cg";
import { IoCheckboxOutline } from "react-icons/io5";
import { SlOptionsVertical } from "react-icons/sl";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "@/utils";
import { Link, Navigate } from "react-location";
import Wrapper from "@/components/wrapper";
import { useMutation, useQuery } from "@apollo/client";

import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import ImageLoader from "@/components/loaders/ImageLoader";
import moment from "moment";

import toast from "react-hot-toast";
import { GET_NOTIFICATIONS } from "@/apollo/graphql/queries/notification";
import {
  DELETE_NOTIFICATION,
  READ_NOTIFICATION,
} from "@/apollo/graphql/mutations/notification";
import {
  DEV_LANDING_URL,
  PROD_LANDING_URL,
  TEST_LANDING_URL,
} from "@/constants/page-path";
import {
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
} from "@/apollo/graphql/generated/types";

const NotificationsPage = () => {
  useEffect(() => {
    document.title = "Talent | Notifications";
  }, []);
  const user = useCurrentUser();

  const { data, loading, error, refetch } = useQuery<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >(GET_NOTIFICATIONS, {
    variables: {
      pagination: {
        limit: 20,
        offset: 0,
      },
      filter: {
        userId: {
          eq: user?.id,
        },
      },
    },
  });

  const [notifications, setNotifications] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      refetch();
      setNotifications(data?.getNotifications?.rows ?? []);
    }
  }, [data]);

  const [readNotificationMutation, { loading: reading }] =
    useMutation(READ_NOTIFICATION);
  const [deleteNotificationMutation, { loading: deleting }] =
    useMutation(DELETE_NOTIFICATION);

  const readNotification = async (id: string) => {
    try {
      await readNotificationMutation({
        variables: {
          readNotificationId: id,
        },
      });
    } catch (error) {
      toast(
        JSON.stringify({
          type: "error",
          title: error || "An error occurred while trying to read notification",
        })
      );
    }
    refetch();
  };

  const deleteNotification = async (id: string) => {
    await deleteNotificationMutation({
      variables: {
        deleteNotificationId: id,
      },
    })
      .then(({ data }) => {
        refetch();
        toast(
          JSON.stringify({
            type: "success",
            title: `Notification successfully deleted`,
          })
        );
      })
      .catch((error) => {
        toast(
          JSON.stringify({
            type: "error",
            title:
              error?.message ||
              "An error occurred while trying to delete notification",
          })
        );
      });
  };

  // Create a new array and sort it by createdAt timestamp in descending order
  const sortedNotifications = [...notifications].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  const token = useCurrentToken();

  const isLoggedIn = user && token;

  if (!isLoggedIn) return <Navigate to="/login" replace />;

  if (loading) return <ImageLoader />;

  return (
    <Wrapper>
      <main className="w-full h-full flex justify-start items-start lg:pr-10 md:pr-5 space-x-5 relative">
        <div className="h-full bg-white border-r border-gray-200 w-full md:w-1/2 overflow-scroll overflow-y-scroll no-scrollbar ">
          <div className="flex flex-col justify-start relative">
            <div className="bg-white sticky top-0 z-20">
              <h1
                className="font-semibold text-black text-lg leading-5 
          tracking-[0.1px] py-5 border-b border-gray-200 px-5"
              >
                Notifications
              </h1>
            </div>
            <div className="w-full mobile:mt-0 z-10">
              {sortedNotifications?.length > 0 ? (
                <>
                  {sortedNotifications.map((item: any, index: number) => (
                    <div
                      key={index}
                      className={`flex justify-between items-center gap-x-5
                   w-full border-b border-gray-200 p-5
                   ${item.read ? "bg-white" : "bg-[#EBFFEB]"}`}
                    >
                      <Link
                        onClick={() => readNotification(item.id)}
                        to={`/notification-detail/?id=${item?.id}`}
                        className="flex flex-1 mobile:items-start items-start gap-x-4"
                      >
                        <div
                          className="flex justify-center items-center w-[3.125rem] h-[3.125rem] rounded-full
                     bg-gray-50 border border-gray-200 p-1"
                        >
                          <img
                            src={logo}
                            alt="notification logo"
                            className="object-contain"
                          />
                        </div>
                        <div className="flex-1 ">
                          <div className="">
                            <p className="font-semibold h-5 overflow-y-hidden text-gray-900 mb-1">
                              {item?.title}
                            </p>
                            <p className="w-full h-5 overflow-y-hidden font-medium text-sm text-gray-500">
                              {item?.body}
                            </p>
                          </div>

                          <p className="font-medium text-gray-400 text-[10px] leading-5">
                            {moment(
                              item?.createdAt,
                              "YYYY-MM-DD HH:mm:ss"
                            ).fromNow()}
                          </p>
                        </div>
                      </Link>

                      <div className="w-6 h-auto">
                        <Menu as="div" className="relative">
                          <Menu.Button className="-m-1.5 flex items-center p-1.5">
                            <span className="sr-only">Open user menu</span>
                            <SlOptionsVertical
                              className="text-gray-700"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              className="absolute right-0 z-10 mt-2.5 w-52  
                          origin-top-right rounded-md bg-white py-2 shadow-xl ring-1 ring-gray-900/5 focus:outline-none"
                            >
                              <Menu.Item>
                                {() => (
                                  <div
                                    className={classNames(
                                      "block  py-1 text-sm leading-6 text-gray-900"
                                    )}
                                  >
                                    <button
                                      disabled={reading || item?.read}
                                      onClick={() => readNotification(item.id)}
                                      className="flex items-center gap-x-2 border-b border-[#F0F0F0] py-2 px-3
                                      text-[#6B6B6B] disabled:text-gray-300"
                                    >
                                      <IoCheckboxOutline className="w-5 h-5  " />
                                      <span className=" text-sm">
                                        Mark as read
                                      </span>
                                    </button>
                                  </div>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block  text-xs  text-gray-900"
                                    )}
                                  >
                                    <button
                                      disabled={deleting}
                                      onClick={() =>
                                        deleteNotification(item.id)
                                      }
                                      className="flex text-red-500 cursor-pointer items-center space-x-1 py-1 px-3"
                                    >
                                      <CgTrash className="w-5 h-5" />
                                      <span className="text-sm">Delete</span>
                                    </button>
                                  </div>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex flex-col items-center w-full pt-10 gap-y-5">
                  <img
                    src={notificationPic}
                    alt="no notification picture"
                    className="w-60 h-36 object-contain"
                  />
                  <h4 className="font-semibold text-base text-black">
                    No notifications yet
                  </h4>
                  <p className="text-sm text-center text-gray-600 max-w-[23.563rem]">
                    You do not have any notifications yet, kindly check back
                    later.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="hidden md:block md:w-1/2 pt-5">
          <div className=" rounded-xl ring-1 ring-gray-300 p-3">
            <img src={emailFooter} alt="email-footer" />

            <div className="flex justify-start items-center w-full gap-3 mt-4 ">
              <a href={FACEBOOK}>
                <BiLogoFacebookCircle className="text-gray-400 w-7 h-7" />
              </a>
              <a href={TWITTER}>
                <FaXTwitter className="text-gray-400 w-6 h-6" />
              </a>
              <a href={INSTAGRAM}>
                <FaInstagram className="text-gray-400 w-6 h-6" />
              </a>
              <a href={LINKEDIN}>
                <FaLinkedin className="text-gray-400 w-6 h-6" />
              </a>
            </div>
          </div>

          <div className="flex flex-col gap-y-2 items-end  py-5 mt-6">
            <img src={logo} alt="logo" className="object-contain h-5 w-20 " />

            <a
              href={
                import.meta.env.VITE_NODE_ENV === "development"
                  ? `${DEV_LANDING_URL}/contact`
                  : import.meta.env.VITE_NODE_ENV === "production"
                  ? `${PROD_LANDING_URL}/contact`
                  : import.meta.env.VITE_NODE_ENV === "test"
                  ? `${TEST_LANDING_URL}/contact`
                  : "/nolink"
              }
              target="_blank"
              className="font-medium text-sm text-gray-400"
            >
              Contact Support
            </a>

            <div className="flex justify-end items-center w-full gap-5">
              <a href={FACEBOOK}>
                <BiLogoFacebookCircle className="text-gray-400 w-5 h-5" />
              </a>
              <a href={TWITTER}>
                <FaXTwitter className="text-gray-400 w-4 h-4" />
              </a>
              <a href={INSTAGRAM}>
                <FaInstagram className="text-gray-400 w-4 h-4" />
              </a>
              <a href={LINKEDIN}>
                <FaLinkedin className="text-gray-400 w-4 h-4" />
              </a>
            </div>

            <p className="font-medium text-sm text-gray-400">
              &copy; Copyright {new Date().getFullYear()}, Adjuma.io
            </p>
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default NotificationsPage;
