import { gql } from "@apollo/client";

export const UPDATE_TALENT_INFO = gql`
  mutation UpdateTalentInfo($input: UpdateTalentInfoInput!) {
    updateTalentInfo(input: $input)
  }
`;

export const UPDATE_ABOUT_ME = gql`
  mutation UpdateAboutMe($profileSummary: String) {
    updateAboutMe(profileSummary: $profileSummary)
  }
`;

export const ADD_PROF_BG = gql`
  mutation AddProfessionalBackground($input: AddProfessionalExperienceInput!) {
    addProfessionalBackground(input: $input)
  }
`;

export const UPDATE_PROF_BG = gql`
  mutation UpdateProfessionalBackground(
    $input: UpdateProfessionalExperienceInput!
  ) {
    updateProfessionalBackground(input: $input)
  }
`;

export const DELETE_PROF_BG = gql`
  mutation DeleteProfessionalBackground($deleteProfessionalBackgroundId: ID!) {
    deleteProfessionalBackground(id: $deleteProfessionalBackgroundId)
  }
`;

export const ADD_EDUCATION = gql`
  mutation AddEducationalBackground($input: AddEducationInput!) {
    addEducationalBackground(input: $input)
  }
`;

export const UPDATE_EDUCATION = gql`
  mutation UpdateEducationalBackground($input: UpdateEducationInput!) {
    updateEducationalBackground(input: $input)
  }
`;

export const DELETE_EDUCATION = gql`
  mutation DeleteEducationalBackground($deleteEducationalBackgroundId: ID!) {
    deleteEducationalBackground(id: $deleteEducationalBackgroundId)
  }
`;

export const ADD_ACHIEVEMENT = gql`
  mutation AddAchievements($input: AddAchievementsInput) {
    addAchievements(input: $input)
  }
`;

export const UPDATE_ACHIEVEMENT = gql`
  mutation UpdateAchievement($input: UpdateAchievementsInput) {
    updateAchievement(input: $input)
  }
`;

export const DELETE_ACHIEVEMENT = gql`
  mutation DeleteAcheivement($deleteAcheivementId: ID!) {
    deleteAcheivement(id: $deleteAcheivementId)
  }
`;

export const ADD_PORTFOLIO = gql`
  mutation AddPortfolio($input: AddPortfolioInput) {
    addPortfolio(input: $input)
  }
`;

export const UPDATE_PORTFOLIO = gql`
  mutation UpdatePortfolio($input: UpdatePortfolioInput) {
    updatePortfolio(input: $input)
  }
`;

export const DELETE_PORTFOLIO = gql`
  mutation DeletePortfolio($deletePortfolioId: ID!) {
    deletePortfolio(id: $deletePortfolioId)
  }
`;

export const ADD_REFERENCE = gql`
  mutation AddReference($input: AddReferenceInput!) {
    addReference(input: $input)
  }
`;

export const UPDATE_REFERENCE = gql`
  mutation UpdateReference($input: UpdateReferenceInput!) {
    updateReference(input: $input)
  }
`;

export const DELETE_REFERENCE = gql`
  mutation DeleteReference($deleteReferenceId: ID!) {
    deleteReference(id: $deleteReferenceId)
  }
`;
