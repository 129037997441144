import { IoCloseCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-location";
import { clearAuth } from "@/apollo/cache/auth";
import { useCallback } from "react";
import logo from "@/assets/images/logo-orange.png";

const SignoutModal = ({ visible, onClose }: any) => {
  const navigate = useNavigate();

  const handleSignOut = useCallback(() => {
    onClose();
    clearAuth(); // Ensure clearAuth completes
    navigate({ to: "/" }); // Navigate to login
  }, [onClose, clearAuth, navigate]);

  if (!visible) return null;

  return (
    <div className="fixed z-40 inset-0 bg-black bg-opacity-50  flex justify-center items-center">
      <div
        className="
        w-[90%] md:w-[40.278%] py-8  bg-white z-30 
       rounded-xl bg-opacity-90 backdrop-blur-lg shadow-2xl flex flex-col justify-center items-center gap-y-3"
      >
        <div className="flex justify-end">
          <IoCloseCircleOutline
            onClick={() => {
              onClose();
            }}
            className="absolute top-4 text-gray-500 w-6 h-6 right-6 cursor-pointer"
          />
        </div>
        <div className="w-16 h-16 bg-primary rounded-full flex justify-center items-center">
          <img src={logo} alt="logo" className="w-11 h-auto object-contain" />
        </div>
        <p
          className="max-w-[25rem] font-montserrat text-gray-400 
       text-sm leading-[1.602rem] tracking-[0.1px] text-center px-3 md:px-0 mb-4 md:mb-0 "
        >
          Thank you for visiting our website! We hope you found what you were
          looking for, and we encourage you to follow us on all social media
          platforms!
        </p>
        <h3
          className="max-w-[25rem] font-montserrat font-semibold
         text-lg leading-[1.886rem] tracking-[0.1px]
          text-center px-3 md:px-0 mb-6
      "
        >
          Adjuma - Connecting millions of Africans to millions of jobs!!
        </h3>

        <button
          className="w-[173px] py-3 font-montserrat text-sm capitalize leading-5 tracking-[0.1px] text-center bg-primary rounded-lg text-[#D9D9D9]"
          type="button"
          onClick={handleSignOut}
        >
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default SignoutModal;
