import { gql } from "@apollo/client";

export const GET_PROFILE = gql`
  query JobSeeker {
    jobSeeker {
      id
      fullName
      email
      phoneNumber
      profileSummary
      avatar
      address
      socialLinks {
        id
        name
        link
      }
      education {
        id
        programme
        school
        startDate
        endDate
        isCurrentlyStudying
      }
      references {
        id
        fullName
        company
        position
        email
        phoneNumber
      }
      professionalBg {
        id
        companyName
        jobTitle
        startDate
        endDate
        isCurrentJob
        skills
      }
      portfolio {
        id
        website
        resume
      }
      skills
      achievements {
        id
        title
        description
        date
      }
      acceptedTermsAndConditions
      loginMeta {
        lastLoginAt
        lastLoginIp
        lastLoginLocation
        createdAt
        updatedAt
      }
      accountMeta {
        isPhoneNumberVerified
        isEmailVerified
        onBoardingStatus
        authType
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
