import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Link, useNavigate, useSearch } from "react-location";

import { GoArrowLeft } from "react-icons/go";
import { useLocalStorage } from "react-use";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { useMutation } from "@apollo/client";
import { LocationGenerics } from "@/router/location";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  setMe,
  setToken,
  useCurrentToken,
  useCurrentUser,
} from "@/apollo/cache/auth";
import { message } from "antd";
import Button from "@/components/buttons/button";
import { LOGIN } from "@/constants/page-path";

import { MdOutlineAttachEmail } from "react-icons/md";
import { GoCheckCircle } from "react-icons/go";
import { PiKeyLight } from "react-icons/pi";

import toast from "react-hot-toast";
import {
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFY_OPT,
} from "@/apollo/graphql/mutations/auth";
import {
  ResetTalentPasswordAfterVerificationMutation,
  ResetTalentPasswordAfterVerificationMutationVariables,
  VerifyCodeForPasswordResetTalentMutation,
  VerifyCodeForPasswordResetTalentMutationVariables,
  VerifyTalentExistenceMutation,
  VerifyTalentExistenceMutationVariables,
} from "@/apollo/graphql/generated/types";
import ButtonLoader from "@/components/loaders/button";

function ForgotPassword() {
  useEffect(() => {
    document.title = "Talent | Forgot Password";
  }, []);
  let currentOTPIndex = 0;
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string[]>(new Array(4).fill(""));
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const currentToken = useCurrentToken();
  const inputRef = useRef<HTMLInputElement>(null);
  const search = useSearch<LocationGenerics>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [forgotStore, setForgotStore] = useLocalStorage<{
    email?: string;
    tab: string;
    token?: string;
    userId?: string;
  }>("forgot-store", { email: "", tab: "send-code", token: "", userId: "" });

  const user = useCurrentUser();

  // console.log(user);

  const [sendResetCode, { loading: loadingSend }] = useMutation<
    VerifyTalentExistenceMutation,
    VerifyTalentExistenceMutationVariables
  >(FORGOT_PASSWORD);
  const [verifyResetCode, { loading: loadingVerify }] = useMutation<
    VerifyCodeForPasswordResetTalentMutation,
    VerifyCodeForPasswordResetTalentMutationVariables
  >(VERIFY_OPT);
  const [resetPassword, { loading: loadingReset }] = useMutation<
    ResetTalentPasswordAfterVerificationMutation,
    ResetTalentPasswordAfterVerificationMutationVariables
  >(RESET_PASSWORD);

  const sendCodeForm = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
    }),
    onSubmit: async (values: any) => {
      await sendResetCode({
        variables: {
          email: values.email,
        },
      })
        .then(({ data }) => {
          if (data?.verifyTalentExistence) {
            setForgotStore({
              tab: "verify-code",
              email: values.email,
              userId: data?.verifyTalentExistence?.id ?? "",
            });
          }
        })
        .catch((err) => {
          toast(
            JSON.stringify({
              type: "error",
              title:
                err?.message ||
                "An error occurred while trying to reset password",
            })
          );
        });
    },
  });

  const verifyCodeForm = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .min(4, "Code should be 6 digits")
        .required("Enter reset code"),
    }),
    onSubmit: async (values) => {
      await verifyResetCode({
        variables: {
          userId: forgotStore?.userId ?? "",
          code: values.code,
        },
      })
        .then(({ data }) => {
          if (data?.verifyCodeForPasswordResetTalent) {
            setToken(data?.verifyCodeForPasswordResetTalent?.token ?? "");
            setForgotStore({
              tab: "reset-password",
            });
          }
        })
        .catch((err) => {
          toast(
            JSON.stringify({
              type: "error",
              title:
                err?.message || "An error occurred while trying to verify code",
            })
          );
        });
    },
  });
  const resetPasswordForm = useFormik({
    initialValues: {
      password: "",
      rPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be more than 8 characters")
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])/,
          "Must Contain at least One Lowercase Character"
        )
        .matches(
          /^(?=.*[A-Z])/,
          "Must Contain at least One Uppercase Character"
        )
        .matches(/^(?=.*[0-9])/, "Must Contain at least One Number")
        .matches(
          /^(?=.*[!@#$%^&*\\|/{}()<>:;[\]_\\-\\=?])/,
          "Must Contain at least One special case Character"
        ),
      rPassword: Yup.string()
        .oneOf([Yup.ref("password"), undefined], "Passwords do not match")
        .required("Please confirm your password"),
    }),
    onSubmit: async (values) => {
      await resetPassword({
        variables: {
          newPassword: values.password,
          confirmedPassword: values.rPassword,
        },
      })
        .then(({ data }) => {
          if (data) {
            message.success("Password reset successfully");
            setForgotStore({ tab: "send-code", email: "" });
            navigate({
              to: search?.redirect ?? "/login",
            });
          }
        })
        .catch((err) => {
          toast(
            JSON.stringify({
              type: "error",
              title:
                err?.message ||
                "An error occurred while trying to reset password",
            })
          );
        });
    },
  });

  useEffect(() => {
    setForgotStore({ tab: "send-code", email: "" });
  }, []);

  const handleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    currentOTPIndex = index;
    if (key === "Backspace") setActiveOTPIndex(currentOTPIndex - 1);
  };

  const handleOnChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const newOTP: string[] = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);
    setOtp(newOTP);
    verifyCodeForm.setFieldValue("code", newOTP.join(""));
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col  px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {forgotStore?.tab === "send-code" ? (
            <div className="mx-auto w-14 h-14 rounded-full flex justify-center items-center border-[10px] border-[#ECFDF3] bg-[#D1FADF]">
              <PiKeyLight
                className="h-6 w-6 font-bold text-primary"
                aria-hidden="true"
              />
            </div>
          ) : forgotStore?.tab === "reset-complete" ? (
            <div className="mx-auto w-14 h-14 rounded-full flex justify-center items-center border-[10px] border-[#ECFDF3] bg-[#D1FADF]">
              <GoCheckCircle
                className="h-6 w-6 font-bold text-primary"
                aria-hidden="true"
              />
            </div>
          ) : (
            <div className="mx-auto w-14 h-14 rounded-full flex justify-center items-center border-[10px] border-[#ECFDF3] bg-[#D1FADF]">
              <MdOutlineAttachEmail
                className="h-6 w-6 font-bold text-primary"
                aria-hidden="true"
              />
            </div>
          )}

          <h2 className="mt-5 text-center text-3xl font-semibold font-montserrat leading-9 tracking-tight text-black mb-2">
            {forgotStore?.tab === "send-code" ? (
              <span>Forgot Password?</span>
            ) : forgotStore?.tab === "reset-complete" ? (
              <span>Password reset</span>
            ) : forgotStore?.tab === "verify-code" ? (
              <span>Check your email</span>
            ) : null}
          </h2>
          <p className="text-center font-manrope text-[#475467]">
            {forgotStore?.tab === "send-code" ? (
              <span> No worries, we'll send you reset instructions.</span>
            ) : forgotStore?.tab === "reset-complete" ? (
              <span>
                Your password has been successfully reset. Click below to log in
                effortlessly.
              </span>
            ) : forgotStore?.tab === "reset-password" ? (
              <span>
                Your new password must be different to previously used
                passwords.
              </span>
            ) : forgotStore?.tab === "verify-code" ? (
              <span>
                We've sent a verification link to <br />
                {forgotStore?.email || "your email"}
              </span>
            ) : null}
          </p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {forgotStore?.tab === "send-code" && (
            <form className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-xs font-montserrat leading-6 text-black"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    {...sendCodeForm.getFieldProps("email")}
                    type="email"
                    placeholder="Enter your email"
                    autoComplete="email"
                    required
                    className="input-base "
                  />
                </div>
              </div>

              <div>
                <Button
                  className="h-12"
                  onClick={sendCodeForm.handleSubmit}
                  width="full"
                >
                  {loadingSend ? (
                    <ButtonLoader title="Resetting..." />
                  ) : (
                    "Reset Password"
                  )}
                </Button>
              </div>
            </form>
          )}
          {forgotStore?.tab === "verify-code" && (
            <form className="mt-2">
              <div className="flex justify-center items-center space-x-2 mb-6">
                {otp.map((_, index) => {
                  return (
                    <React.Fragment key={index}>
                      <input
                        ref={index === activeOTPIndex ? inputRef : null}
                        type="number"
                        className="w-[63.2px] h-[63.8px] xl:w-20 xl:h-20 border-2 rounded-lg bg-transparent outline-none
                   text-center font-manrope font-[400] text-[37.92px] xl:text-5xl leading-[47.4px] xl:leading-[60px] tracking-[-2%] spin-button-none border-primary
                    focus:border-primary focus:text-primary text-primary shadow-sm transition"
                        onChange={handleOnChange}
                        onKeyDown={(e) => handleOnKeyDown(e, index)}
                        value={otp[index]}
                      />
                    </React.Fragment>
                  );
                })}
              </div>

              <div>
                <Button
                  className="h-12"
                  onClick={verifyCodeForm.handleSubmit}
                  width="full"
                >
                  {loadingVerify ? (
                    <ButtonLoader title="Verifying..." />
                  ) : (
                    "Verify"
                  )}
                </Button>
              </div>
              <p className="text-center font-manrope font-[400] text-sm leading-5 text-grey-600 mt-6">
                Didn’t receive the email?{" "}
                <Link to="#" className="text-primary">
                  Click to resend
                </Link>
              </p>
            </form>
          )}
          {forgotStore?.tab === "reset-password" && (
            <form className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2 flex relative items-center">
                  <input
                    placeholder="password"
                    {...resetPasswordForm.getFieldProps("password")}
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    className="block w-full pl-4 rounded-md border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />

                  <div className="flex absolute right-0 pr-4">
                    {showPassword ? (
                      <FaRegEyeSlash
                        className="cursor-pointer text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <FaRegEye
                        className="cursor-pointer text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                </div>
                {resetPasswordForm.touched.password &&
                  resetPasswordForm.errors.password && (
                    <p className="text-red-500 text-xs mt-1">
                      {resetPasswordForm.errors.password}
                    </p>
                  )}
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm Password
                </label>
                <div className="mt-2 flex relative items-center">
                  <input
                    placeholder="confirm password"
                    {...resetPasswordForm.getFieldProps("rPassword")}
                    type={showConfirmPassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    className="block w-full pl-4 rounded-md border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />

                  <div className="flex absolute right-0 pr-4">
                    {showConfirmPassword ? (
                      <FaRegEyeSlash
                        className="cursor-pointer text-gray-500"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    ) : (
                      <FaRegEye
                        className="cursor-pointer text-gray-500"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    )}
                  </div>
                </div>
                {resetPasswordForm.touched.rPassword &&
                  resetPasswordForm.errors.rPassword && (
                    <p className="text-red-500 text-xs mt-1">
                      {resetPasswordForm.errors.rPassword}
                    </p>
                  )}
              </div>

              <div>
                <Button
                  className="h-12"
                  onClick={resetPasswordForm.handleSubmit}
                  width="full"
                >
                  {loadingReset ? (
                    <ButtonLoader title="Confirming..." />
                  ) : (
                    "Reset Password"
                  )}
                </Button>
              </div>
            </form>
          )}
          {forgotStore?.tab === "reset-complete" && (
            <div>
              <Button className="h-12" width="full">
                {" "}
                Continue
              </Button>
            </div>
          )}
          <Link to={LOGIN}>
            <div className="flex mt-10 items-center gap-x-1 justify-center">
              <GoArrowLeft className="w-5 h-5 text-[#475467]" />
              <p className=" text-center text-sm text-gray-600">
                Back to Log in
              </p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
