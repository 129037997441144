import { useEffect, useState } from 'react';
const getIsMobile = () => window.innerWidth < 1024;

const useWidth = () => {
  const [mobile, setIsMobile] = useState<boolean>(getIsMobile());
  useEffect(() => {
    function handleResize() {
      setIsMobile(getIsMobile());
    }
    window.addEventListener('resize', handleResize);
    window.removeEventListener('resize', handleResize);
  }, []);
  return [mobile];
};

export default useWidth;
