import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import JobWrapper from "@/components/job-wrapper";
import AltJobComponent from "@/components/job-wrapper/components/alt-job-component";
import ImageLoader from "@/components/loaders/ImageLoader";
import { useQuery } from "@apollo/client";
import { FC, useEffect, useState } from "react";
import EmptyState from "./empty-state";
import { GET_APPLIED_JOBS } from "@/apollo/graphql/queries/job";
import { Navigate } from "react-location";
import {
  GetAllAppliedJobsQuery,
  GetAllAppliedJobsQueryVariables,
  Sort,
} from "@/apollo/graphql/generated/types";

type Props = {};

const AppliedJobsPage: FC<Props> = () => {
  useEffect(() => {
    document.title = "Talent | Applied Jobs";
  }, []);
  const user = useCurrentUser();

  const { data, loading, error, refetch } = useQuery<
    GetAllAppliedJobsQuery,
    GetAllAppliedJobsQueryVariables
  >(GET_APPLIED_JOBS, {
    variables: {
      candidateId: user?.id,
      pagination: {
        limit: 20,
        offset: 0,
      },
      sort: {
        appliedAt: Sort.Desc,
      },
    },
  });

  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const [selectedJob, setSelectedJob] = useState<any>(null);
  const [openSlider, setOpenSlider] = useState<boolean>(false);

  const jobs = data?.getAllAppliedJobs?.rows ?? [];

  useEffect(() => {
    refetch();
  }, []);

  const handleWithdrawJob = (jobId: string) => {
    setSelectedJob(null);
    setSelectedJobId(null);
    setOpenSlider(false);
  };

  useEffect(() => {
    if (jobs?.length > 0 && !selectedJobId) {
      const initialJob = jobs[0];
      setSelectedJobId(initialJob?.id ?? "");
      setSelectedJob(initialJob);
    }
  }, [jobs]);

  const token = useCurrentToken();

  // const navigate = useNavigate();

  const isLoggedIn = user && token;

  // if (!isLoggedIn) return navigate({ to: "/login" });
  if (!isLoggedIn) return <Navigate to="/login" replace />;

  if (loading) return <ImageLoader />;

  return (
    <JobWrapper
      open={openSlider}
      setOpen={setOpenSlider}
      refetch={refetch}
      title="Applied Jobs"
      data={jobs}
      selectedJob={selectedJob}
      renderEmptyComponent={() => <EmptyState />}
      setSelectedJob={setSelectedJob}
      handleWithdrawJob={handleWithdrawJob}
      renderItem={(job) => (
        <AltJobComponent
          key={job.id}
          job={job}
          selected={selectedJobId === job.id}
          onClick={() => {
            setSelectedJobId(job.id);
            setSelectedJob(job);
            setOpenSlider(true);
          }}
          handleWithdrawJob={handleWithdrawJob}
        />
      )}
    />
  );
};

export default AppliedJobsPage;
