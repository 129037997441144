import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";

import FilterModalContainer from "@/components/job-wrapper/components/filter-modal";
import JobComponent from "@/components/job-wrapper/components/job-component";
import ImageLoader from "@/components/loaders/ImageLoader";
import { NetworkStatus, useQuery } from "@apollo/client";
import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  Children,
  PropsWithChildren,
} from "react";
import EmptyState from "./empty-state";
import { useUrlState } from "@/utils";
import useWidth from "@/hooks/useWidth";
import { useFormik } from "formik";
import Wrapper from "@/components/wrapper";
import Show from "@/components/core/show";
import UncompleteBanner from "@/components/job-wrapper/components/uncomplete-banner";
import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";

import { useSearch } from "react-location";

import _ from "lodash";
import { LocationGenerics } from "@/router/location";

import { GET_JOBS } from "@/apollo/graphql/queries/job";
import MiniFooter from "@/components/job-wrapper/components/mini-footer";
import { profileComplete } from "@/utils/profile-complete";
import {
  Condition,
  GetJobsQuery,
  GetJobsQueryVariables,
  Sort,
} from "@/apollo/graphql/generated/types";
import FeedAd from "@/components/feed-ads";

interface Filter {
  type?: {
    in: string[];
  };
  createdAt?: {
    between: string[];
  };
  mode?: {
    in: string[];
  };
}

type Props = {};

const JobHuntPage: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    document.title = "Talent | Job Hunt";
  }, []);

  const user = useCurrentUser();
  const token = useCurrentToken();
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const [hasMore, setHasMore] = useState(true);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [isFetchingPrevious, setIsFetchingPrevious] = useState(false);
  const [openSlider, setOpenSlider] = useState(false);
  // const navigate = useNavigate();
  const search = useSearch<LocationGenerics>();

  // console.log(token);

  const { loading, error, data, refetch, networkStatus, fetchMore } = useQuery<
    GetJobsQuery,
    GetJobsQueryVariables
  >(GET_JOBS, {
    variables: {
      pagination: {
        limit: 100,
        offset: 0,
      },
      filter: {},
      sort: {
        createdAt: Sort.Desc,
      },
      condition: Condition.Or,
    },
    notifyOnNetworkStatusChange: true,
  });

  // const loadMoreJobs = () => {
  //   setIsFetchingMore(true);
  //   fetchMore({
  //     variables: {
  //       pagination: {
  //         limit,
  //         offset: offset + limit,
  //       },
  //     },
  //     updateQuery: (previousResult, { fetchMoreResult }) => {
  //       setIsFetchingMore(false);
  //       if (!fetchMoreResult) return previousResult;

  //       const newRows = [
  //         ...previousResult.getJobs.rows,
  //         ...fetchMoreResult.getJobs.rows,
  //       ];
  //       setHasMore(newRows.length < fetchMoreResult.getJobs.totalCount);
  //       setHasPrevious(true);

  //       return {
  //         ...previousResult,
  //         getJobs: {
  //           ...previousResult.getJobs,
  //           rows: newRows,
  //         },
  //       };
  //     },
  //   });

  //   setOffset(offset + limit);
  // };

  // const fetchPreviousJobs = () => {
  //   if (offset <= 0) return;

  //   setIsFetchingPrevious(true);
  //   fetchMore({
  //     variables: {
  //       pagination: {
  //         limit,
  //         offset: offset - limit,
  //       },
  //     },
  //     updateQuery: (previousResult, { fetchMoreResult }) => {
  //       setIsFetchingPrevious(false);
  //       if (!fetchMoreResult) return previousResult;

  //       setHasPrevious(offset - limit > 0);

  //       return {
  //         ...previousResult,
  //         getJobs: {
  //           ...previousResult.getJobs,
  //           rows: fetchMoreResult.getJobs.rows,
  //         },
  //       };
  //     },
  //   });

  //   setOffset(offset - limit);
  // };

  const [selectedJobId, setSelectedJobId] = useState<any>("");
  const [selectedJob, setSelectedJob] = useState<any>(null);
  const [dataFromFilter, setDataFromFilter] = useState<any>([]);

  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [results, setResults] = useState<any>(data?.getJobs?.rows || []);

  const showUncompletedBanner = profileComplete();

  useEffect(() => {
    if (data) {
      setResults(data?.getJobs?.rows);
    }
  }, [data]);

  const handleSearch = () => {
    // If both jobTitle and location are empty, show all results
    if (!jobTitle && !location) {
      setResults(data?.getJobs?.rows);
      return;
    }

    // Filter jobs based on jobTitle and location
    const filteredResults = data?.getJobs?.rows?.filter((job: any) => {
      // Match job title or company name if jobTitle is provided
      const matchesJobTitleOrCompany = jobTitle
        ? job.title.toLowerCase().includes(jobTitle.toLowerCase()) ||
          job?.company?.name.toLowerCase().includes(jobTitle.toLowerCase())
        : true; // If no jobTitle is provided, return true for all

      // Match location if provided
      const matchesLocation = location
        ? job.location?.toLowerCase().includes(location.toLowerCase())
        : true; // If no location is provided, return true for all

      // Return true only if both conditions are met
      return matchesJobTitleOrCompany && matchesLocation;
    });

    // Set the filtered results
    setResults(filteredResults);
  };

  useEffect(() => {
    setResults(data?.getJobs?.rows);
  }, [jobTitle, location, data]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const [toggleApply, setToggleApply] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [withdraw, setWithdraw] = useState<boolean>(false);
  const [modal, setModal] = useUrlState("modal");
  const [mobile] = useWidth();
  const applyForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      cv: "",
      linkedIn: "",
      portfolio: "",
    },
    onSubmit: () => {},
  });

  // useEffect(() => {
  //   if (data) {
  //     let initialJob = data?.getJobs?.rows?.[0];

  //     // if (!initialJob) {
  //     //   initialJob = data?.getJobs?.rows?.[0];
  //     // }
  //     if (initialJob) {
  //       setSelectedJobId(initialJob.id);
  //       setSelectedJob(initialJob);
  //       // navigate({ search: { id: initialJob.id } }); // Update the URL with the job ID
  //     }
  //   }
  // }, [data]);

  const handleDataFromFilter = (data: any) => {
    setDataFromFilter(data);
  };

  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     if (data?.getJobs?.rows?.length === 0) return;

  //     const currentIndex = data?.getJobs?.rows?.findIndex(
  //       (job: any) => job.id === selectedJobId
  //     );

  //     if (event.key === "ArrowDown") {
  //       const nextIndex =
  //         currentIndex === data?.getJobs?.rows?.length - 1 ? 0 : currentIndex + 1;
  //       setSelectedJobId(data?.getJobs?.rows[nextIndex].id);
  //       setSelectedJob(data?.getJobs?.rows[nextIndex]);
  //       navigate({ search: { id: data?.getJobs?.rows[nextIndex].id } }); // Update URL
  //     }

  //     if (event.key === "ArrowUp") {
  //       const prevIndex =
  //         currentIndex === 0 ? data.getJobs.rows.length - 1 : currentIndex - 1;
  //       setSelectedJobId(data.getJobs.rows[prevIndex].id);
  //       setSelectedJob(data.getJobs.rows[prevIndex]);
  //       navigate({ search: { id: data.getJobs.rows[prevIndex].id } }); // Update URL
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [selectedJobId, data, navigate]);

  const handleSubmit = useCallback(() => {
    const [types, createdAt, modes] = dataFromFilter;

    // Define the conditions for refetch without filters
    const allJobs = _.includes(types, "all_jobs");
    const anyCreatedAt = _.includes(createdAt, "any");
    const allModes = _.includes(modes, "All");

    // Check for the specific cases to refetch without filters
    if (
      (types.length === 1 &&
        allJobs &&
        createdAt.length === 0 &&
        modes.length === 0) ||
      (createdAt.length === 1 &&
        anyCreatedAt &&
        types.length === 0 &&
        modes.length === 0) ||
      (modes.length === 1 &&
        allModes &&
        createdAt.length === 0 &&
        types.length === 0) ||
      (modes.length === 1 &&
        allModes &&
        createdAt.length === 1 &&
        anyCreatedAt &&
        types.length === 0) ||
      (modes.length === 1 &&
        allModes &&
        types.length === 1 &&
        allJobs &&
        createdAt.length === 0) ||
      (createdAt.length === 1 &&
        anyCreatedAt &&
        types.length === 1 &&
        allJobs &&
        modes.length === 0) ||
      (types.length === 1 &&
        allJobs &&
        createdAt.length === 1 &&
        anyCreatedAt &&
        modes.length === 1 &&
        allModes)
    ) {
      refetch({
        pagination: {
          limit: 100,
          offset: 0,
        },
        filter: {},
      });
    } else {
      // Prepare the filter object
      const filter: Filter = {};

      if (!allJobs && types.length > 0) {
        filter.type = { in: types };
      }

      if (!anyCreatedAt && createdAt.length > 0) {
        filter.createdAt = { between: createdAt };
      }

      if (!allModes && modes.length > 0) {
        filter.mode = { in: modes };
      }

      refetch({
        pagination: {
          limit: 100,
          offset: 0,
        },
        filter,
      });
    }

    setDataFromFilter([]);
    setOpenFilter(false);
  }, [dataFromFilter, refetch, setOpenFilter]);

  const handleJobClick = (job: any) => {
    setSelectedJobId(search?.id);
    setSelectedJob(job);
  };

  if (loading || networkStatus === NetworkStatus.refetch)
    return <ImageLoader />;
  if (error) return <p>Error loading jobs</p>;

  return (
    <>
      <Wrapper>
        <div className="relative w-full flex justify-center items-start h-full ">
          {/* left side */}
          <div className="md:w-1/2 w-full h-full border-r border-gray-300 overflow-scroll overflow-y-scroll no-scrollbar">
            <Show if={showUncompletedBanner}>
              <UncompleteBanner />
            </Show>
            {/* Search Component */}

            <div
              className={`sticky ${
                showUncompletedBanner ? "top-16" : "top-0"
              }  z-10  bg-white flex justify-center items-center border-b border-gray-300 w-full h-16 py-2.5 px-5`}
            >
              <div className="w-full border border-gray-300 rounded-full h-full flex justify-between items-center ">
                <input
                  type="text"
                  className="h-full pl-4 w-2/5 border-none focus:ring-0 outline-none rounded-l-full text-sm text-gray-800 placeholder:text-xs placeholder:text-gray-500 placeholder:font-light"
                  placeholder="Job Title, Company"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  onKeyPress={handleKeyPress}
                />{" "}
                <span className="border-r h-full" />
                <label htmlFor="city">
                  <MapPinIcon className="ml-1 h-4 w-4 text-primary-500" />
                </label>
                <input
                  type="text"
                  id="city"
                  placeholder="City, State"
                  className="h-full pl-0 w-2/5 text-left border-none focus:ring-0 outline-none text-sm text-gray-800 placeholder:text-xs placeholder:text-gray-500 placeholder:font-light rounded-r-full"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <button
                  onClick={handleSearch}
                  className="w-7 h-7 rounded-full bg-primary mr-1 flex justify-center items-center"
                >
                  <MagnifyingGlassIcon className="h-[1.125rem] w-[1.125rem] text-white" />
                </button>
              </div>
            </div>
            {/* Search Results */}
            <div
              className={`sticky ${
                showUncompletedBanner ? "top-32" : "top-16"
              }  z-10 bg-white w-full h-14 border-b flex-between px-5 gap-2`}
            >
              <Show if={true}>
                <div className="flex-1 ">
                  <p className="text-base font-medium">
                    Search Results for{" "}
                    <span className="text-primary-500 text-sm">
                      {jobTitle ? jobTitle : location ? location : "All Jobs"}
                    </span>
                  </p>
                  <p className="font-medium text-sm text-gray-400 ">
                    {results?.length} Results Found
                  </p>
                </div>
              </Show>
              <Show if={false}>
                <div className="flex-1 ">
                  <p className="text-sm font-medium">
                    {/* <span className="text-primary-500 text-sm">{title}</span> */}
                  </p>
                  <p className="font-medium text-sm text-gray-400 ">
                    {results?.length} Results Found
                  </p>
                </div>
              </Show>
              <button
                onClick={() => setOpenFilter(true)}
                className="h-8 w-8 rounded bg-gray-100 flex-center"
              >
                <AdjustmentsHorizontalIcon className="h-5 w-5" />
              </button>
            </div>

            <div className="w-full flex flex-col items-center px-6 pt-5 pb-16 mobile:pb-24">
              {results?.length > 0 ? (
                results?.map((job: any, index: number) => (
                  <React.Fragment key={job.id}>
                    <JobComponent
                      refetch={refetch}
                      job={job}
                      selected={selectedJobId === job.id}
                      onClick={() => handleJobClick(job)}
                    />
                    {(index + 1) % 3 === 0 && <FeedAd />}
                  </React.Fragment>
                ))
              ) : (
                <EmptyState />
              )}
            </div>
          </div>
          {/* Right side */}
          <div className="flex-1 w-1/2 h-full overflow-scroll overflow-y-scroll no-scrollbar">
            {/* job description details */}
            <div className="w-full px-5">
              <Show if={true}>
                <div className=" ">
                  {children}
                  <div className=" ">
                    <MiniFooter isFull={true} />
                  </div>
                </div>
              </Show>
            </div>
          </div>
        </div>

        {/* <Show if={mobile}> */}
        {/* <DetailSliderContainer
          job={selectedJob}
          open={openSlider}
          setOpen={setOpenSlider}
          withdraw={withdraw}
          setWithdraw={setWithdraw}
          toggleApply={toggleApply}
          setToggleApply={setToggleApply}
          title={"Available Jobs"}
          applyForm={applyForm}
        /> */}
        {/* </Show> */}
      </Wrapper>
      <FilterModalContainer
        setOpen={setOpenFilter}
        open={openFilter}
        onData={handleDataFromFilter}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default JobHuntPage;
