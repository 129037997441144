import { Fragment, useState, useEffect, ChangeEvent } from "react";
import { regions } from "@/constants/regions";
import Select from "@/components/core/select";
import { LuUploadCloud } from "react-icons/lu";
import { useMutation as useMutationTanstack } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import Button from "@/components/buttons/button";
import { updateUser } from "@/redux/user/userSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ClipLoader } from "react-spinners";
import { TopBarLoader } from "@/components/loaders";
import OnBoardingModal from "./components/onboarding";
import { UPLOAD_FILE } from "@/helpers/upload-file";
import {
  setAuth,
  setMe,
  setToken,
  useCurrentToken,
  useCurrentUser,
} from "@/apollo/cache/auth";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { eduLevel, jobOptions, Option } from "./data";
import { classNames } from "@/utils";
import toast from "react-hot-toast";
import maleImage from "@/assets/images/male.jpeg";

import ButtonLoader from "@/components/loaders/button";
import { values } from "lodash";
import { ONBOARD_TALENT } from "@/apollo/graphql/mutations/auth";
import CountryList from "./components/countryInput";
import { Navigate } from "react-location";
import {
  OnBoardTalentMutation,
  OnBoardTalentMutationVariables,
} from "@/apollo/graphql/generated/types";

const Onboarding = () => {
  useEffect(() => {
    document.title = "Talent | Onboarding";
  }, []);
  const user = useCurrentUser();
  const token = useCurrentToken();
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profilePic, setProfilePic] = useState(user?.avatar || "");
  const [imageLoading, setImageLoading] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  // console.log(user);
  // console.log(token);

  const handleCountrySelect = (country: string) => {
    setSelectedCountry(country);
  };

  const [selected, setSelected] = useState({
    value: "",
    label: "--select-option--",
  });

  const toggleOption = (option: Option) => {
    const isOptionSelected = selectedOptions.some(
      (selected) => selected.id === option.id
    );

    if (isOptionSelected) {
      setSelectedOptions((prevSelected) =>
        prevSelected.filter((selected) => selected.id !== option.id)
      );
    } else {
      if (selectedOptions.length < 3) {
        setSelectedOptions((prevSelected) => [...prevSelected, option]);
      }
    }
  };

  const removeOption = (option: Option) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.filter((selected) => selected.id !== option.id)
    );
  };

  const handleToggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isClickInside = target.closest(".multi-select");

      if (!isClickInside) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // const [onBoardUser] = useMutation(UPDATEUSER);

  const [onboardTalent, { loading: onboarding }] = useMutation<
    OnBoardTalentMutation,
    OnBoardTalentMutationVariables
  >(ONBOARD_TALENT);

  // const [sendEmailAfterOnboarding] = useMutation(SEND_EMAIL);

  const { isLoading, mutate: uploadFile } = useMutationTanstack({
    mutationKey: ["uploadFile"],
    mutationFn: UPLOAD_FILE,
  });

  const handleUpload = async (file: any) => {
    const data = new FormData();
    data.append("file", file);
    uploadFile(data, {
      onSuccess: (data) => {
        setProfilePic(data.url);
      },
    });
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      try {
        await handleUpload(selectedFile);
      } finally {
      }
    }
  };

  // phone number state
  const handleChange = (value: any): void => {
    setPhoneNumber(`+${value}`);
    setValid(validatePhoneNumber(value));
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneNumberPattern = /^\d{12}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const handleOnSubmit = async () => {
    await onboardTalent({
      variables: {
        input: {
          address: selectedCountry,
          phoneNumber: phoneNumber,
          avatar: profilePic,
          skills: selectedOptions.map((option) => option.label),
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Profile updated successfully",
            })
          );
        }
        // setMe(data.updateUser);
        setModalVisible(true);
        setAuth({ user: user, token: token ?? "" });
      })
      .catch((err) => {
        toast(
          JSON.stringify({
            type: "error",
            title:
              err?.message || "An error occurred while trying to setup profile",
          })
        );
      });
  };

  const isLoggedIn = user && token;

  if (!isLoggedIn) return <Navigate to="/login" replace />;

  return (
    <>
      {imageLoading && <TopBarLoader />}

      <section className="md:h-[88vh] w-full flex">
        <div className="hidden lg:block bg-gray w-[41.875%] h-full">
          <div className="w-[23.625rem] mt-[9.313rem]  mx-auto">
            <h1
              className="font-montserrat font-[400] text-3xl leading-[2.357rem]
             tracking-[0.1px] text-black-2 capitalize"
            >
              Welcome Aboard! Let's Complete Your Sign-Up.
            </h1>

            <p className="font-sans font-[400] text-base leading-6 text-gray-600 mt-2">
              Your journey with Adjuma is just beginning. Follow the steps below
              to personalise your experience and unlock the full potential of
              our platform.
            </p>
          </div>
        </div>
        <form className="w-full h-full bg-white px-6 lg:px-0 md:w-[58.125%] flex flex-col flex-1 justify-between">
          <div>
            <div className="w-full flex flex-col flex-1 lg:border-b pb-24 lg:border-gray-400">
              <div className="w-full flex flex-col flex-1 md:max-w-[85.185%] pt-4">
                <div className="w-full flex flex-col flex-1 justify-between md:w-[29.938rem] mx-auto">
                  <h1
                    className="font-montserrat text-center font-[400] text-lg leading-[1.414rem]
             tracking-[0.1px] text-black-2 capitalize lg:hidden mb-5 px-5"
                  >
                    Welcome Aboard! Let's Complete Your Sign-Up.
                  </h1>
                  <h4
                    className="font-montserrat font-[400]
                     text-lg leading-[22.65px] tracking-[0.1] text-black-2 mb-3 lg:mb-2"
                  >
                    Profile Information
                  </h4>

                  <div className="mb-3 lg:mb-2">
                    <label
                      className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                      htmlFor="region"
                    >
                      Country
                    </label>
                    <div className="input-base mt-1 relative w-full flex items-center h-12">
                      {/* <Select
                        options={regions}
                        onChange={handleSelectChange}
                        defaultValue={""}
                      /> */}
                      <CountryList
                        selectedCountry={selectedCountry}
                        onCountrySelect={handleCountrySelect}
                      />
                    </div>
                  </div>

                  <div className="mb-3 lg:mb-2">
                    <label
                      className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                      htmlFor="phoneNumber"
                    >
                      phone number
                    </label>

                    <PhoneInput
                      country={"gh"}
                      placeholder={"Mobile Number"}
                      value={phoneNumber}
                      onChange={handleChange}
                      countryCodeEditable={false}
                      enableSearch={true}
                      inputProps={{
                        required: true,
                        title: "Enter your phone number here",
                        className: "input-base",
                      }}
                      inputStyle={{
                        width: "80%",
                        marginLeft: "20%",
                      }}
                      buttonStyle={{
                        width: "18%",
                        borderRadius: "0.5rem",
                        borderColor: "rgb(156 163 175)",
                        paddingInline: "0rem",
                      }}
                      dropdownStyle={{
                        borderRadius: "0.5rem",
                        borderColor: "rgb(156 163 175)",
                        paddingInline: "1rem",
                      }}
                      searchStyle={{
                        width: "90%",
                        borderRadius: "0.5rem",
                        borderColor: "rgb(156 163 175)",
                        paddingInline: "1rem",
                      }}
                    />
                  </div>
                  <div className="">
                    <label
                      className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                      htmlFor="phoneNumber"
                    >
                      profile image
                    </label>

                    <div className="md:flex items-center gap-4 mt-1">
                      {isLoading ? (
                        <div
                          className={` flex justify-center items-center w-[64px] h-[64px] md:h-[58px] rounded-full overflow-hidden  mb-4 md:mb-0`}
                        >
                          <ClipLoader
                            color={"#02733E"}
                            loading={true}
                            size={30}
                            //cssOverride={{ width: "30%" }}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : profilePic ? (
                        <div className="w-16 h-16  rounded-full overflow-hidden  mb-4 md:mb-0">
                          <img
                            src={profilePic}
                            alt="profile picture"
                            className="w-full h-full object-cover mb-4 md:mb-0  "
                          />
                        </div>
                      ) : (
                        <img
                          src={user?.profilePicture || maleImage}
                          alt="profile picture"
                          className="w-16 h-16 object-cover mb-4 md:mb-0 rounded-full "
                        />
                      )}

                      <div className="flex flex-1 w-full py-5 flex-col justify-center items-center border border-gray-400 rounded-xl">
                        <label
                          htmlFor="file"
                          className="flex items-center gap-2 cursor-pointer"
                        >
                          <span className="w-[40px] h-[40px] bg-gray-50 ">
                            <div className="text-center bg-gray-100 w-5 h-5 rounded-full">
                              <LuUploadCloud
                                className="text-gray-600 "
                                size={27}
                              />
                            </div>
                          </span>
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept="image/*"
                            className="hidden"
                            onChange={handleFileChange}
                          />
                        </label>

                        <div className="font-sans text-center">
                          <p className="text-sm text-gray-600  font-[400] leading-5 text-center">
                            <span className="text-primary">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-600 font-[400] leading-[18px] text-center">
                            SVG, PNG or JPG (max. 800x400px)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="">
                  <h4 className="font-montserrat text-xs leading-4 mt-4 mb-2">
                    Your Highest Level of Education
                  </h4>

                  <div className="flex items-center gap-3 input-base  ">
                    <Listbox value={selected} onChange={setSelected}>
                      {({ open }) => (
                        <div className="w-full flex gap-3 items-center">
                          <div className="relative flex-1 cursor-pointer">
                            <Listbox.Button className="flex items-center relative w-full rounded-md bg-white pl-3 pr-10 text-left text-gray-900 shadow-sm  sm:text-sm sm:leading-6">
                              <span
                                className={`block truncate font-montserrat font-light text-xs leading-4 text-gray-700 ${
                                  selected.value === "" && "text-gray-400 "
                                }`}>
                                {selected.label}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0">
                              <Listbox.Options className="absolute right-3 z-10 mt-3 min-w-[14.125rem]  max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {eduLevel.map((type) => (
                                  <Listbox.Option
                                    key={type.id}
                                    className={({ selected, active }) =>
                                      classNames(
                                        selected || active
                                          ? "bg-success-light text-gray-900"
                                          : "text-gray-900",
                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                      )
                                    }
                                    value={type}>
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold "
                                              : "font-normal",
                                            "block truncate"
                                          )}>
                                          {type.label}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              "text-primary absolute inset-y-0 right-0 flex items-center pr-4"
                                            )}>
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </div>
                      )}
                    </Listbox>
                  </div>
                </div> */}
                  <div className="mb-6">
                    <h4 className="font-montserrat text-xs leading-4 mt-4">
                      What best describes your skills?
                    </h4>
                    <p className="font-montserrat text-xs leading-4 mb-2 text-gray-400">
                      Select up to 3
                    </p>

                    <div className="flex items-center gap-3 flex-wrap ">
                      <div className="w-full multi-select relative inline-block text-left ">
                        <div>
                          <span className="rounded-md shadow-sm">
                            <button
                              type="button"
                              className="inline-flex justify-between items-center w-full rounded-md 
                             px-4 py-2 bg-white text-sm font-medium text-gray-700
                             focus:outline-none  focus:ring-offset-gray-100
                              input-base overflow-hidden
                             "
                              onClick={handleToggle}
                            >
                              {selectedOptions.length > 0 ? (
                                <div className="flex items-center space-x-2 overflow-x-scroll overflow-y-hidden ">
                                  {selectedOptions.map((selected) => (
                                    <div
                                      key={selected.id}
                                      className="flex items-center border border-gray-300 rounded-full px-2 py-1"
                                    >
                                      <span className="mr-1 whitespace-nowrap font-montserrat font-light text-xs leading-4 text-gray-700">
                                        {selected.label}
                                      </span>
                                      <button
                                        type="button"
                                        className="text-primary hover:text-lime-600 font-light  focus:outline-none"
                                        onClick={() => removeOption(selected)}
                                      >
                                        <IoIosClose className="w-6 h-6" />
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <span className="font-montserrat font-light text-xs leading-4 text-gray-400">
                                  Select
                                </span>
                              )}

                              <RiArrowDropDownLine className="w-8 h-8 text-gray-400" />
                            </button>
                          </span>
                        </div>

                        {isOpen && (
                          <div
                            className="w-[20rem] h-[20rem] overflow-y-scroll
                         origin-top-right absolute right-0 mt-1  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                          >
                            <div className="py-1">
                              {jobOptions.map((option) => (
                                <div
                                  key={option.id}
                                  className="flex items-center px-4 py-2"
                                >
                                  <input
                                    type="checkbox"
                                    className="form-checkbox border-gray-300 h-4 w-4 text-primary bg-primary"
                                    checked={selectedOptions.some(
                                      (selected) => selected.id === option.id
                                    )}
                                    onChange={() => toggleOption(option)}
                                  />
                                  <span className="ml-2 font-montserrat text-sm leading-5 text-gray-700">
                                    {option.label}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full py-6 md:max-w-[85.185%] flex items-center justify-end">
            <Button
              className="!w-[10.813rem] h-[3.125rem]"
              onClick={handleOnSubmit}
            >
              {onboarding ? <ButtonLoader title="Submitting..." /> : "Continue"}
            </Button>
          </div>
        </form>
      </section>
      <OnBoardingModal
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};

export default Onboarding;
