import { Link, Navigate, Outlet } from "react-location";
import logo from "@/assets/images/logo.png";
import { FC } from "react";
import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import { PROD_LANDING_URL, TEST_LANDING_URL } from "@/constants/page-path";

const AuthLayout: FC = () => {
  const currentUser = useCurrentUser();
  const currentToken = useCurrentToken();

  // if (currentToken && currentUser?.id) {
  //   return <Navigate to={'/'} replace />;
  // }
  return (
    <>
      <div className="relative pt-20">
        <nav className="absolute top-0 right-0 left-0 w-full bg-white py-4 sm:border-b border-grey-300">
          <div className="flex justify-center items-center mt-8 sm:mt-0 sm:block sm:max-w-[76%] mx-auto">
            <Link to={
                        import.meta.env.VITE_NODE_ENV === "development"
                          ? `/`
                          : import.meta.env.VITE_NODE_ENV === "production"
                          ? `${PROD_LANDING_URL}`
                          : import.meta.env.VITE_NODE_ENV === "test"
                          ? `${TEST_LANDING_URL}`
                          : "/nolink"
                      }>
              <img
                src={logo}
                alt="logo"
                className="max-w-[133.1px] max-h-[45.14px] object-contain"
              />
            </Link>
          </div>
        </nav>
        <div className={`w-full h-full bg-white`}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
