// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD3FypuBTSSqOf9xpwXj_ZG97oGYKMuras",
  authDomain: "talent-adjuma.firebaseapp.com",
  projectId: "talent-adjuma",
  storageBucket: "talent-adjuma.appspot.com",
  messagingSenderId: "248403092037",
  appId: "1:248403092037:web:ef30f14f8544dff272b92c",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { app, auth, GoogleAuthProvider, signInWithPopup };
