import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { currentUserVar, setMe } from "@/apollo/cache/auth";

import { CiEdit } from "react-icons/ci";
import male from "../../../../assets/images/male.jpeg";
import { CiTrash } from "react-icons/ci";
import ProfileModal from "../modals/profile";
import AboutMe from "../modals/about-me";

import toast from "react-hot-toast";
import JobLoader from "@/components/loaders/job";
import ImageLoader from "@/components/loaders/ImageLoader";
import { GET_PROFILE } from "@/apollo/graphql/queries/profile";
import { UPDATE_ABOUT_ME } from "@/apollo/graphql/mutations/profile";
import Avatar from "@/components/core/avatar";
import AutoCompleteInput from "../input-autocomplete";

export default function Profile() {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAboutMeModal, setShowAboutMeModal] = useState(false);

  // const user = currentUserVar()
  // console.log(user);

  const { data, refetch, loading } = useQuery(GET_PROFILE);
  const [updateABoutMe, { loading: updating }] = useMutation(UPDATE_ABOUT_ME);

  const handleAboutDelete = async () => {
    const response = await updateABoutMe({
      variables: {
        profileSummary: "",
      },
    });
    if (response?.data?.updateAboutMe) {
      refetch();
      toast(
        JSON.stringify({
          type: "success",
          title: `About successfully deleted`,
        })
      );
    }
  };

  // console.log(data?.jobSeeker);

  useEffect(() => {
    refetch();
    if (data?.jobSeeker) {
      setMe(data?.jobSeeker);
    }
  }, [data]);

  const user = data?.jobSeeker;

  if (loading) return <ImageLoader />;

  return (
    <div className="w-full">
      <h4 className="font-montserrat font-semibold mobile:text-lg text-base mobile:text-gray-900 text-black">
        Profile Information
      </h4>

      <div className="font-montserrat w-full mobile:p-4 p-6 border border-gray-300  rounded-xl mt-6 ">
        <div className="flex mobile:flex-col justify-between items-start">
          <div className="flex items-start mobile:gap-10 gap-8">
            {/* <div className="mobile:w-[3.125rem] mobile:h-[3.125rem] w-[6.25rem]  h-[6.25rem] rounded-full  mobile:shadow-md bg-gray-300"> */}
            {/* <img
                src={user?.avatar || male}
                alt="profile picture"
                className="w-full h-full object-cover rounded-full "
              /> */}
            <Avatar
              src={`${user?.avatar || ""}`}
              alt={`${user?.fullName || ""}`}
              size="xl"
            />
            {/* </div> */}

            <div className="">
              <div className="text-sm mb-4">
                <p className="text-gray-600 mb-1">Full Name</p>
                <h6 className="font-medium text-black ">
                  {" "}
                  {user?.fullName || "N/A"}
                </h6>
              </div>
              <div className="text-sm mb-4">
                <p className="text-gray-600 mb-1">Country</p>
                <h6 className="font-medium text-black ">
                  {user?.address || "N/A"}
                </h6>
              </div>
              <div className="text-sm ">
                <p className="text-gray-600 mb-1">Phone Number</p>
                <h6 className="font-medium text-black ">
                  {" "}
                  {user?.phoneNumber || "N/A"}
                </h6>
              </div>
            </div>
          </div>
          <div className="mobile:w-full mobile:border-t border-gray-300 mobile:flex justify-end mobile:pt-5 mobile:mt-5">
            <div
              onClick={() => {
                setShowProfileModal(true);
              }}
              className="w-fit font-montserrat flex items-center gap-2 px-3.5 py-2 rounded-lg cursor-pointer ring-1 ring-[#D0D5DD] shadow"
            >
              <CiEdit className="w-5 h-5 text-[#292D32] " aria-hidden="true" />
              <span className="font-medium text-sm text-black">Edit</span>
            </div>
          </div>
        </div>
      </div>

      {user?.profileSummary === "" ? (
        <div className="font-montserrat w-full p-10 border border-gray-300 rounded-xl mt-6 ">
          <p className="w-full max-w-80 mx-auto text-center">
            You have not provided any information about yourself yet,{" "}
            <button
              type="button"
              onClick={() => {
                setShowAboutMeModal(true);
              }}
              className="text-primary underline font-semibold"
            >
              Click here
            </button>{" "}
            to add details about yourself.
          </p>
        </div>
      ) : (
        <div className="font-montserrat w-full p-6 border border-gray-300 rounded-xl mt-6 ">
          <div className="flex mobile:flex-col justify-between items-start">
            <div className="">
              <h5 className="font-medium text-base text-black mb-3">
                About Me
              </h5>
              <p className="text-gray-600 text-sm max-w-[32.375rem]">
                {user?.profileSummary ?? "N/A"}
              </p>
            </div>
            <div className="mobile:w-full mobile:border-t border-gray-300 mobile:flex justify-end mobile:pt-5 mobile:mt-5">
              <div className="flex items-center mobile:gap-4 gap-2 px-3.5 py-2 rounded-lg ring-1 ring-[#D0D5DD] shadow">
                <button
                  onClick={() => {
                    setShowAboutMeModal(true);
                  }}
                  className="bg-white rounded-md text-gray-400  hover:text-gray-500 
               focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  <CiEdit
                    className="w-5 h-5 text-[#292D32] cursor-pointer "
                    aria-hidden="true"
                  />
                </button>
                <button
                  disabled={user?.profileSummary === "" || updating}
                  onClick={handleAboutDelete}
                  className="bg-white rounded-md text-gray-400  hover:text-gray-500 
               focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  <CiTrash
                    className="w-5 h-5 text-[#292D32] "
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ProfileModal
        visible={showProfileModal}
        data={user}
        refetch={refetch}
        onClose={() => {
          setShowProfileModal(false);
        }}
      />
      <AboutMe
        visible={showAboutMeModal}
        data={user}
        refetch={refetch}
        onClose={() => {
          setShowAboutMeModal(false);
        }}
      />
    </div>
  );
}
