export const jobTypeFilter = [
  {
    id: "all_jobs",
    name: "all_jobs",
    title: "All Job Types",
  },
  {
    id: "Full Time",
    name: "Full Time",
    title: "Full-time",
  },
  {
    id: "Part Time",
    name: "Part Time",
    title: "Part Time",
  },
  {
    id: "Contract",
    name: "Contract",
    title: "Contract",
  },
  {
    id: "Internship",
    name: "Internship",
    title: "Internship",
  },
  {
    id: "Temporary",
    name: "Temporary",
    title: "Temporary",
  },
];

export const datePostedFilter = [
  {
    id: "any",
    title: "Any",
  },
  {
    id: "today",
    title: "Today",
  },
  {
    id: "thisWeek",
    title: "This Week",
  },
  {
    id: "lastWeek",
    title: "Last Week",
  },
  {
    id: "lastTwoWeeks",
    title: "Last 2 Weeks",
  },
  {
    id: "lastMonth",
    title: "Last Month",
  },
];

export const locationFilter = [
  {
    id: "All",
    title: "All",
  },
  {
    id: "OnSite",
    title: "Onsite",
  },
  {
    id: "Remote",
    title: "Remote",
  },
  {
    id: "Hybrid",
    title: "Hybrid",
  },
];
