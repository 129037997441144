import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import JobComponent from "@/components/job-wrapper/components/job-component";
import ImageLoader from "@/components/loaders/ImageLoader";
import { NetworkStatus, useQuery } from "@apollo/client";
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import EmptyState from "./empty-state";
import Wrapper from "@/components/wrapper";
import Show from "@/components/core/show";
import UncompleteBanner from "@/components/job-wrapper/components/uncomplete-banner";
import useWidth from "@/hooks/useWidth";
import { useFormik } from "formik";
import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import JobDetailComponent from "@/components/job-wrapper/components/job-detail-component";
import { Navigate, useNavigate, useSearch } from "react-location";
import emailFooter from "@/assets/images/email-footer.png";
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from "@/constants/socials";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import DetailSliderContainer from "@/components/job-wrapper/components/detail-slider-container";
import FilterModalContainer from "@/components/job-wrapper/components/filter-modal";
import { LocationGenerics } from "@/router/location";
import { GET_SAVED_JOBS } from "@/apollo/graphql/queries/job";

import _ from "lodash";
import MiniFooter from "@/components/job-wrapper/components/mini-footer";
import {
  GetAllSavedJobsQuery,
  GetAllSavedJobsQueryVariables,
  Sort,
} from "@/apollo/graphql/generated/types";

type Props = {};

const SavedJobsPage: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    document.title = "Talent | Saved Jobs";
  }, []);
  const user = useCurrentUser();
  const navigate = useNavigate();
  const search = useSearch<LocationGenerics>();

  const { data, loading, error, refetch, networkStatus } = useQuery<
    GetAllSavedJobsQuery,
    GetAllSavedJobsQueryVariables
  >(GET_SAVED_JOBS, {
    variables: {
      candidateId: user?.id,
      pagination: {
        limit: 50,
        offset: 0,
      },
      // filter: {},
      sort: {},
    },
  });

  const [selectedJobId, setSelectedJobId] = useState<any>(null);
  const [selectedJob, setSelectedJob] = useState<any>(null);
  const [jobs, setJobs] = useState<any[]>([]);
  const [dataFromFilter, setDataFromFilter] = useState([]);

  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [results, setResults] = useState<any>(
    data?.getAllSavedJobs?.rows ?? []
  );

  const showUncompletedBanner = false;

  useEffect(() => {
    if (data) {
      setResults(data?.getAllSavedJobs?.rows ?? []);
    }
  }, [data]);

  const handleSearch = () => {
    if (!jobTitle && !location) {
      setResults(data?.getAllSavedJobs?.rows ?? []);
      return;
    }

    const filteredResults = data?.getAllSavedJobs?.rows?.filter((job: any) => {
      const matchesJobTitle = jobTitle
        ? job.savedJob.title.toLowerCase().includes(jobTitle.toLowerCase())
        : true;
      const matchesLocation = location
        ? job.savedJob.location.toLowerCase().includes(location.toLowerCase())
        : true;
      return matchesJobTitle && matchesLocation;
    });

    setResults(filteredResults);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const [toggleApply, setToggleApply] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [withdraw, setWithdraw] = useState<boolean>(false);
  const [mobile] = useWidth();
  const applyForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      cv: "",
      linkedIn: "",
      portfolio: "",
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    if (data) {
      let initialJob = data?.getAllSavedJobs?.rows?.[0];
      if (!initialJob) {
        initialJob = data?.getAllSavedJobs?.rows?.[0];
      }
      if (initialJob) {
        setSelectedJobId(initialJob.id);
        setSelectedJob(initialJob.savedJob);
        // navigate({ search: { id: initialJob.id } }); // Update the URL with the job ID
      }
    }
  }, [data, search, navigate]);

  const handleDataFromFilter = (data: any) => {
    setDataFromFilter(data);
  };

  const handleSubmit = useCallback(() => {
    if (_.includes(dataFromFilter[0], "all_jobs")) {
      refetch({
        pagination: {
          limit: 50,
          offset: 0,
        },

        sort: {
          savedAt: Sort.Desc,
        },
      });
    }
    //  else {
    //   refetch({
    //     pagination: {
    //       limit: 50,
    //       offset: 0,
    //     },
    //     filter: {
    //       type: {
    //         in: dataFromFilter[0],
    //       },
    //       mode: {
    //         in: dataFromFilter[2],
    //       },
    //     },
    //   });
    // }

    setOpenFilter(false);
  }, [dataFromFilter, refetch]);

  const handleJobClick = (job: any) => {
    setSelectedJobId(job.id);
    setSelectedJob(job.savedJob);
    // navigate({ search: { id: job.id } }); // Update the URL with the job ID
  };

  const handleUnsaveJob = (jobId: string) => {
    setJobs(jobs.filter((job) => job.id !== jobId));
  };

  const token = useCurrentToken();

  const isLoggedIn = user && token;

  if (!isLoggedIn) return <Navigate to="/login" replace />;

  if (loading || networkStatus === NetworkStatus.refetch)
    return <ImageLoader />;
  if (error) return <p>Error loading jobs</p>;

  return (
    <>
      <Wrapper>
        <div className="relative w-full flex justify-center items-start h-full ">
          {/* left side */}
          <div className="md:w-1/2 w-full h-full border-r border-gray-300 overflow-scroll overflow-y-scroll no-scrollbar">
            <Show if={showUncompletedBanner}>
              <UncompleteBanner />
            </Show>
            {/* Search Component */}

            <div
              className={`sticky ${
                showUncompletedBanner ? "top-16" : "top-0"
              }  z-10  bg-white flex justify-center items-center border-b border-gray-300 w-full h-16 py-2.5 px-5`}
            >
              <div className="w-full border border-gray-300 rounded-full h-full flex justify-between items-center ">
                <input
                  type="text"
                  className="h-full w-2/5 border-none focus:ring-0 outline-none rounded-l-full text-sm text-gray-800 placeholder:text-xs placeholder:text-gray-500 placeholder:font-light"
                  placeholder="Job Title, Skill, Company"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  onKeyPress={handleKeyPress}
                />{" "}
                <span className="border-r h-full" />
                <label htmlFor="city">
                  <MapPinIcon className="ml-1 h-4 w-4 text-primary-500" />
                </label>
                <input
                  type="text"
                  id="city"
                  placeholder="City, State or Zip"
                  className="h-full w-2/5 text-left border-none focus:ring-0 outline-none text-sm text-gray-800 placeholder:text-xs placeholder:text-gray-500 placeholder:font-light rounded-r-full"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <button
                  onClick={handleSearch}
                  className="w-7 h-7 rounded-full bg-primary mr-1 flex justify-center items-center"
                >
                  <MagnifyingGlassIcon className="h-[1.125rem] w-[1.125rem] text-white" />
                </button>
              </div>
            </div>
            {/* Search Results */}
            <div
              className={`sticky ${
                showUncompletedBanner ? "top-32" : "top-16"
              } z-10 bg-white w-full h-14 border-b flex-between px-5 gap-2`}
            >
              <Show if={true}>
                <div className="flex-1 ">
                  <p className="text-base font-medium">My Saved Jobs</p>
                  <p className="font-medium text-sm text-gray-400 ">
                    <span>{results?.length}</span>
                    <span>{results?.length > 1 ? " Jobs." : " Job."}</span>
                  </p>
                </div>
              </Show>
              <Show if={false}>
                <div className="flex-1 ">
                  <p className="text-sm font-medium">
                    {/* <span className="text-primary-500 text-sm">{title}</span> */}
                  </p>
                  <p className="font-medium text-sm text-gray-400 ">
                    {results?.length} Results Found
                  </p>
                </div>
              </Show>
              <button
                disabled
                onClick={() => setOpenFilter(true)}
                className="h-8 w-8 rounded bg-gray-100 flex-center"
              >
                <AdjustmentsHorizontalIcon className="h-5 w-5" />
              </button>
            </div>

            <div className="w-full flex flex-col items-center px-6 py-5 pb-16 mobile:pb-24">
              {results?.length > 0 ? (
                results?.map((job: any) => (
                  <JobComponent
                    key={job?.savedJob?.id}
                    id={job?.id}
                    job={job?.savedJob}
                    selected={selectedJobId === job?.id}
                    onClick={() => handleJobClick(job?.savedJob)}
                    handleUnsave={() => handleUnsaveJob(job?.savedJob?.id)}
                  />
                ))
              ) : (
                <EmptyState />
              )}
            </div>
          </div>
          {/* Right side */}
          <div className="flex-1 w-1/2 h-full overflow-scroll overflow-y-scroll no-scrollbar ">
            {/* job description details */}
            <div className="w-full px-5">
              <Show if={true}>
                <>
                  {/* {results?.length > 0 && selectedJob && (
                    <JobDetailComponent job={selectedJob} />
                  )} */}
                  {children}

                  <div className=" ">
                    <MiniFooter isFull={true} />
                  </div>
                </>
              </Show>
            </div>
          </div>
        </div>

        {/* <Show if={mobile}>
          <DetailSliderContainer
            job={selectedJob}
            open={false}
            setOpen={function (value: boolean): void {
              throw new Error("Function not implemented.");
            }}
            withdraw={withdraw}
            setWithdraw={setWithdraw}
            toggleApply={toggleApply}
            setToggleApply={setToggleApply}
            title={"Saved Jobs"}
            applyForm={applyForm}
          />
        </Show> */}
      </Wrapper>
      <FilterModalContainer
        setOpen={setOpenFilter}
        open={openFilter}
        onData={handleDataFromFilter}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default SavedJobsPage;
