import { ChangeEvent, useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import { Link, useNavigate, useSearch } from "react-location";
import Button from "@/components/buttons/button";
import { useMutation } from "@apollo/client";
import { setAuth, useAppNaviagte, useAppSearch } from "@/apollo/cache/auth";

import toast from "react-hot-toast";
import { GoogleAuthProvider, auth, signInWithPopup } from "@/config/firebase";
import digitalContractImage from "@/assets/images/bro.png";
import Show from "@/components/core/show";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BarLoader } from "react-spinners";
import ButtonLoader from "@/components/loaders/button";
import { LOGIN_USER, LOGIN_USER_SOCIAL } from "@/apollo/graphql/mutations/auth";
import { LocationGenerics } from "@/router/location";
import {
  AuthType,
  CandidateLoginMutation,
  CandidateLoginMutationVariables,
  SocialAuthLoginMutation,
  SocialAuthLoginMutationVariables,
} from "@/apollo/graphql/generated/types";
import { IoArrowBackOutline } from "react-icons/io5";

const Signin = () => {
  useEffect(() => {
    document.title = "Talent | Sign in";
  }, []);
  
  const [eyeClick, setEyeClick] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [socialAuthLoading, setSocialAuthLoading] = useState(false);

  const search = useSearch<LocationGenerics>();
  const navigate = useAppNaviagte();

  const [signin, { loading }] = useMutation<
    CandidateLoginMutation,
    CandidateLoginMutationVariables
  >(LOGIN_USER);
  const [socialAuth, { loading: authenticating }] = useMutation<
    SocialAuthLoginMutation,
    SocialAuthLoginMutationVariables
  >(LOGIN_USER_SOCIAL);

  const { handleSubmit, ...siginForm } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be 8 characters or more")
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      await signin({
        variables: {
          email: values.email,
          password: values.password,
        },
      })
        .then(({ data }) => {
          if (data?.candidateLogin?.token) {
            const { user, token } = data.candidateLogin;
            setAuth({
              token,
              user: user,
            });
            toast(
              JSON.stringify({
                type: "success",
                title: `Welcome Back ${user?.fullName?.split(" ")[0]}`,
              })
            );
            const redirectTo = search?.redirect ?? "/";

            navigate({ replace: true, to: redirectTo });
          }
        })
        .catch((error) => {
          toast(
            JSON.stringify({
              type: "error",
              title:
                error?.message || "An error occurred while trying to log in",
            })
          );
        });
    },
  });

  const handleSocialAuth = async (authType: string) => {
    try {
      setSocialAuthLoading(true);

      const provider: any =
        authType === "Google" ? new GoogleAuthProvider() : null;

      const googleUser: any = await signInWithPopup(auth, provider);
      console.log(googleUser);

      await socialAuth({
        variables: {
          input: {
            email: googleUser.user.email,
            password: googleUser.user.uid,
            fullName: googleUser.user.displayName,
            authType: AuthType.Google,
            acceptedTermsAndConditions: true,
            avatar: googleUser.user.photoURL,
          },
        },
      })
        .then(({ data }) => {
          setSocialAuthLoading(false);
          if (data?.socialAuthLogin?.token) {
            const { user, token } = data.socialAuthLogin;
            setAuth({
              token,
              user: user,
            });
            toast(
              JSON.stringify({
                type: "success",
                title: `Welcome Back ${user?.fullName?.split(" ")[0]}`,
              })
            );
            const redirectTo = search?.redirect ?? "/";

            navigate({ replace: true, to: redirectTo });
          }
        })
        .catch((err) => {
          setSocialAuthLoading(false);
          toast(
            JSON.stringify({
              type: "error",
              title:
                err?.message ||
                "An error occurred while signing in with Google",
            })
          );
        });
    } catch (e) {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred while signing up with Google",
        })
      );
      setSocialAuthLoading(false);
    }
  };

  return (
    <section className="h-full w-full select-none">
      <div className="h-full xl:max-w-[75%] mx-auto xl:mt-8 flex px-5 py-2 justify-between items-start gap-x-[4rem]">
        <Show if={socialAuthLoading}>
          <div className="h-full max-w-[29.875rem] mx-auto flex flex-1 flex-col justify-center items-center  ">
            <BarLoader className="#02733e" />
            <p className="text-center">Sigining with google </p>
          </div>
        </Show>
        <Show if={!socialAuthLoading}>
          <div className="max-w-[32rem] mx-auto flex flex-1 items-start  gap-5">
            <Link
              to={"/"}
              className="mobile:hidden w-12 h-12 bg-[#F5F6F9] rounded-full flex justify-center cursor-pointer items-center hover:shadow-md transition-all duration-150 ease-in-out"
            >
              <IoArrowBackOutline className="" aria-hidden="true" />
            </Link>
            <div className="w-full flex-1 mobile:mt-5">
              <div className="text-center xl:text-left w-full">
                <h1 className="font-montserrat font-semibold text-2xl xl:text-3xl text-black tracking-[0.006rem] mb-2">
                  Welcome Back, <span className="text-primary">Talent</span>
                </h1>
                <p className="font-montserrat text-xs xl:text-base text-gray-600 leading-6">
                  Please enter your credentials below to continue
                </p>
              </div>

              <div className="mt-8 space-y-4">
                <div>
                  <label
                    className="text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006px]"
                    htmlFor="email"
                  >
                    Email
                  </label>

                  <input
                    id="email"
                    placeholder="Enter your email"
                    name="email"
                    type="email"
                    value={siginForm.values.email}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      siginForm.setFieldValue("email", e.target.value)
                    }
                    autoComplete="email"
                    className="input-base text-gray-900"
                  />
                  {siginForm.errors.email && (
                    <p className="text-xs mt-2 text-red-500">
                      {siginForm.errors.email}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    className="text-[12px] font-[400] text-black-2 capitalize leading-[14.4px] tracking-[0.1px]"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <div className="flex items-center justify-between w-full input-base overflow-hidden">
                    <input
                      id="password"
                      type={eyeClick ? "text" : "password"}
                      placeholder="Enter your password"
                      name="password"
                      value={siginForm.values.password}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        siginForm.setFieldValue("password", e.target.value)
                      }
                      autoComplete="current-password"
                      className="border-none outline-none bg-transparent w-full input-base text-gray-900"
                    />

                    <div
                      className="cursor-pointer"
                      onClick={() => setEyeClick(!eyeClick)}
                    >
                      {eyeClick ? (
                        <LuEye className="w-4 h-4" />
                      ) : (
                        <AiOutlineEyeInvisible className="w-4 h-4" />
                      )}
                    </div>
                  </div>
                  {siginForm.errors.password && (
                    <p className="text-xs mt-2 text-red-500">
                      {siginForm.errors.password}
                    </p>
                  )}
                  <div className="mt-3">
                    <p className="font-bold font-manrope text-sm">
                      Must be at least 8 characters
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex items-center mt-6 mb-6">
                    <input
                      onChange={() => {
                        setTermsChecked(!termsChecked);
                      }}
                      type="checkbox"
                      checked={termsChecked}
                      className="peer w-4 h-4 mr-2 cursor-pointer rounded-[4px] border border-gray-300 bg-white outline-primary-500 focus:ring-primary-500 text-primary-500"
                    />
                    <label
                      className="text-black text-[14px] leading-[20px] font-manrope font-[400]"
                      htmlFor="remember"
                    >
                      Remember for 30 days
                    </label>
                  </div>

                  <Link
                    to="/password"
                    className="text-primary-500 text-[14px] leading-[20px] font-manrope font-[400]"
                  >
                    Forgot password ?
                  </Link>
                </div>
                <Button
                  className="h-[3rem] rounded-lg"
                  width="full"
                  size="lg"
                  onClick={handleSubmit}
                >
                  {loading ? <ButtonLoader title="Signing in..." /> : "Sign in"}
                </Button>
                <div className="w-full flex items-center gap-x-2 my-2">
                  <div
                    style={{ content: "" }}
                    className="w-full h-[1px] bg-gray-200"
                  />
                  <p className="text-gray-600 font-[400] font-manrope text-sm">
                    OR
                  </p>
                  <div
                    style={{ content: "" }}
                    className="w-full h-[1px] bg-gray-200"
                  />
                </div>
                <div className="w-full mt-6 mb-6">
                  <button
                    type="button"
                    onClick={() => handleSocialAuth("Google")}
                    className="w-full font-manrope flex justify-center items-center gap-x-2 shadow-sm rounded-lg border bg-white
                 border-gray-300 py-3 text-base font-[400] text-gray-700 leading-6"
                  >
                    <FcGoogle className="w-6 h-6 text-center" />
                    Sign in with Google
                  </button>
                </div>
                <p className="text-center text-gray-600 text-sm leading-5 font-[400] font-manrope">
                  Don't have an account?{" "}
                  <Link to="/signup" className="text-primary">
                    Sign up
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Show>

        <div className="hidden xl:flex flex-1 flex-col items-center w-[23.313rem]">
          <div className="text-center mb-6">
            <h1 className="font-montserrat font-medium text-3xl text-center text-black tracking-[0.1px] mb-2">
              Access Your Account for Seamless Navigation
            </h1>
            <p className="font-montserrat text-base text-gray-600 leading-6">
              Your Gateway to Success - Log in Now
            </p>
          </div>

          <img
            src={digitalContractImage}
            alt="front picture"
            className="object-contain w-full h-full"
            aria-hidden="true"
          />
        </div>
      </div>
    </section>
  );
};

export default Signin;
