import { GET_MESSAGES } from "@/apollo/messages";
import {
  GetMessagesQuery,
  GetMessagesQueryVariables,
} from "@/apollo/messages/generated/types";
import { useQuery, QueryHookOptions } from "@apollo/client";

export const useMessages = (
  options?: QueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables>
) => {
  const { data, ...rest } = useQuery<
    GetMessagesQuery,
    GetMessagesQueryVariables
  >(GET_MESSAGES, {
    ...options,
    context: { apiName: "message" },
  });

  return {
    messages: data?.getMessages,
    ...rest,
  };
};
