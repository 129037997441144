import Modal from "@/components/layouts/modal";
import { FC, useEffect, useState } from "react";
import manImage from "@/assets/images/male.jpeg";
import {
  BanknotesIcon,
  BriefcaseIcon,
  FaceSmileIcon,
  MapPinIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/20/solid";
import CommentItem from "./comment-item";
import LogoLize from "@/assets/images/logoLize.webp";
import moment from "moment";
import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import { useMutation, useQuery } from "@apollo/client";

import toast from "react-hot-toast";
import Show from "@/components/core/show";
import { GET_JOB } from "@/apollo/graphql/queries/job";
import { COMMENT_JOB } from "@/apollo/graphql/mutations/job";
import { wrapClick } from "@/utils";
import { useNavigate } from "react-location";
import Avatar from "@/components/core/avatar";

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  job: any;
};

const CommentModalContainer: FC<Props> = ({ open, setOpen, job }) => {
  const user = useCurrentUser();
  const token = useCurrentToken();
  const [commentText, setCommentText] = useState("");
  const [localComments, setLocalComments] = useState(job?.comments || []);

  const navigate = useNavigate();

  const isLoggedIn = token && user?.id;

  const { data, refetch, loading } = useQuery(GET_JOB, {
    variables: {
      jobId: job?.id,
    },
    skip: !open, // Skip query execution if modal is not open
    fetchPolicy: "network-only", // Ensure fresh data is fetched
  });

  useEffect(() => {
    if (data?.getJob) {
      setLocalComments(data.getJob.comments || []);
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  const [addComment, { loading: commenting }] = useMutation(COMMENT_JOB, {
    onCompleted: () => {
      setCommentText("");
      refetch();
      // toast(
      //   JSON.stringify({
      //     type: "success",
      //     title: `Comment Successfully added`,
      //   })
      // );
    },
    onError: (error) => {
      toast(
        JSON.stringify({
          type: "error",
          title: error?.message || "Failed to comment",
        })
      );
    },
  });

  const handleComment = async () => {
    if (!commentText.trim()) {
      return;
    }

    const newComment = {
      id: Date.now().toString(),
      comment: commentText,
      userId: user?.id,
      jobId: job?.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      __typename: "Comment",
    };

    setLocalComments((prevComments: any) => [...prevComments, newComment]);

    await addComment({
      variables: {
        jobId: job?.id,
        userId: user?.id,
        comment: commentText,
      },
    });
  };

  return (
    <Modal
      description="Comments"
      hideActions={true}
      open={open}
      setOpen={setOpen}
    >
      <div className="w-full px-5">
        <div className="border border-gray-300 rounded-xl px-5 py-4 w-full cursor-pointer mt-3">
          <div className="w-full">
            <div className="flex items-center gap-2 mb-2">
              <div className="w-10 h-10 rounded flex-center bg-white">
                <img
                  src={job?.company?.companyLogo ?? LogoLize}
                  alt="company-logo"
                  className="w-full h-full object-cover "
                />
              </div>
              <h1 className="flex-1 text-base text-black sm:text-lg font-semibold truncate">
                {job?.title ?? "N/A🚫"}
              </h1>
            </div>

            <div className="flex justify-start gap-2.5 items-center font-medium text-xs text-gray-500 ">
              <p className="truncate">
                {job?.company?.name ?? "Company's Name N/A"}
              </p>
              <p className="flex items-center gap-1 truncate ">
                <span className="">
                  <MapPinIcon className="h-4 w-4" />
                </span>{" "}
                <span className="flex-1">{job?.location ?? "N/A🚫"}</span>
              </p>
              <p className="flex items-center gap-1 text-nowrap">
                <span className="h-1 w-1 border border-b rounded-full bg-gray-500"></span>
                <span className="flex-1 text-primary-500 text-center">
                  {/* {moment(job?.createdAt, "YYYY-MM-DD HH:mm:ss").fromNow() ??
                    "N/A🚫"} */}
                  {/* {moment(job?.createdAt).startOf("day").fromNow() ?? "N/A"} */}
                  {moment(job?.createdAt).fromNow()}
                </span>
              </p>
            </div>
            <div className="flex justify-start  mobile:justify-start items-center flex-wrap gap-2 font-medium text-xs text-black mt-3">
              <span className="bg-gray-100 px-2 flex gap-1 justify-start items-center py-1 rounded">
                <BanknotesIcon className="h-4 w-4" />{" "}
                <span className="text-center text-nowrap">
                  {!job?.salary || job?.salary?.maximum === "0" ? (
                    "Negotiable"
                  ) : (
                    <>
                      {job?.salary?.currency ?? "N/A"}{" "}
                      {job?.salary?.minimum ?? "N/A"} -{" "}
                      {job?.salary?.maximum ?? "N/A"} Per Year
                    </>
                  )}
                </span>
              </span>
              <span className="bg-gray-100 px-2 flex gap-1 justify-start items-center py-1 rounded">
                {" "}
                <BriefcaseIcon className="h-4 w-4" />
                <span className="whitespace-nowrap text-nowrap">
                  {job?.mode ?? "N/A🚫"}
                </span>
              </span>
              <span className="bg-gray-100 px-2 flex gap-1 justify-start items-center py-1 rounded text-nowrap">
                {" "}
                <BanknotesIcon className="h-4 w-4" /> {job?.type ?? "N/A🚫"}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-5 pt-5 border-t border-gray-300">
          <div className="sticky -top-2 bg-white py-5 flex items-center justify-between gap-2">
            <div className="w-10 h-10 rounded-full bg-gray-300 overflow-hidden">
              <img
                src={user?.avatar ?? manImage}
                alt="profile"
                className="h-full w-full bg-cover object-cover"
              />
            </div>

            {/* Comment input */}
            <div className="flex-1 flex justify-between items-center rounded-full border border-gray-300 h-10">
              <div className="flex items-center justify-start">
                <span className="h-full flex items-center justify-center gap-1 px-2">
                  <button>
                    <PhotoIcon className="h-4 w-4 text-gray-500" />
                  </button>
                  <button>
                    <FaceSmileIcon className="h-4 w-4 text-gray-500" />
                  </button>
                </span>
                <span className="h-4 border-r " />
              </div>
              <input
                type="text"
                placeholder="Add a comment"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                className="h-full text-xs text-gray-700 bg-transparent border-none outline-none focus:ring-0 flex-1"
              />
              <button
                onClick={wrapClick(
                  !isLoggedIn ? () => navigate({ to: "/login" }) : handleComment
                )}
                disabled={commenting}
                className="mr-1 rounded-full h-6 w-6 flex justify-center items-center bg-primary-500"
              >
                <PaperAirplaneIcon className="h-4 w-4 text-white" />
              </button>
            </div>
          </div>

          {/* Comments */}
          <div className="mt-5">
            <h1 className="text-sm text-black">
              {localComments?.length > 0 ? "All Comments" : "No Comments"}{" "}
            </h1>
            <div className="mt-3 space-y-3 pb-16">
              {localComments?.map((comment: any) => (
                <div
                  key={comment.id}
                  className="w-full flex justify-between items-center gap-2 pl-8"
                >
                  <div className="w-10 h-10 rounded-full bg-gray-300 overflow-hidden">
                    {/* <img
                      src={comment?.talent?.avatar ?? manImage}
                      alt="profile"
                      className="h-full w-full object-cover"
                    /> */}
                    <Avatar
                      src={`${comment?.talent?.avatar || ""}`}
                      alt={`${comment?.talent?.fullName || ""}`}
                      size="sm"
                    />
                  </div>

                  <div className="flex-1">
                    <div className="bg-[#F5F6F9] rounded-r-lg rounded-ss-lg p-3 text-[#171C1B]">
                      <div className="flex items-center justify-between mb-2 ">
                        <h5 className="w-full max-w-40 text-sm font-semibold text-nowrap truncate">
                          {comment?.talent?.fullName ?? "Loading..."}
                        </h5>
                        <p className="text-xs font-medium text-gray-500 text-nowrap">
                          {/* {moment(comment?.createdAt).startOf("hour").fromNow()} */}
                          {moment(comment?.createdAt).fromNow()}
                        </p>
                      </div>
                      <p className="text-sm  ">{comment?.comment ?? "N/A"}</p>
                      {/* <Show if={true}>
                        <button className="w-full flex items-center justify-end">
                          <p className="text-[10px] text-primary-500 underline">
                            Read more
                          </p>
                        </button>
                      </Show> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CommentModalContainer;
