import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { currentUserVar, setMe, useCurrentUser } from "@/apollo/cache/auth";

import { CiEdit, CiTrash } from "react-icons/ci";
import AchievementModal from "../modals/achievement";
import PortfolioModal from "../modals/portfolio";

import JobLoader from "@/components/loaders/job";
import ImageLoader from "@/components/loaders/ImageLoader";

import toast from "react-hot-toast";
import { GET_PROFILE } from "@/apollo/graphql/queries/profile";
import { DELETE_PORTFOLIO } from "@/apollo/graphql/mutations/profile";

export default function Portfolio() {
  const [showPortfolio, setShowPortfolio] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const { data, refetch, loading } = useQuery(GET_PROFILE);

  const [deletePortfolio, { loading: deleting }] =
    useMutation(DELETE_PORTFOLIO);

  useEffect(() => {
    refetch();
    if (data?.jobSeeker) {
      setMe(data?.jobSeeker);
    }
  }, [data]);

  const user = data?.jobSeeker;

  const handleEditClick = (datum: any) => {
    setSelectedData(datum);
    setShowPortfolio(true);
  };

  const handleDeleteClick = async (id: string) => {
    try {
      await deletePortfolio({
        variables: {
          deletePortfolioId: id,
        },
      })
        .then(({ data }) => {
          if (data) {
            refetch();
            toast(
              JSON.stringify({
                type: "success",
                title: `Portfolio successfully deleted`,
              })
            );
          }
        })
        .catch((error) => {
          toast(
            JSON.stringify({
              type: "error",
              title:
                error?.message ||
                "An error occurred while trying to delete portfolio",
            })
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddNewClick = () => {
    setSelectedData(null);
    setShowPortfolio(true);
  };

  if (loading) return <ImageLoader />;

  return (
    <>
      {user?.portfolio?.map((datum: any, index: number) => (
        <div key={index} className="w-full">
          <div className="flex justify-between items-center">
            <h4 className="font-montserrat font-semibold text-base text-black">
              Portfolio
            </h4>

            <div
              onClick={
                user?.portfolio?.length > 0
                  ? () => handleEditClick(datum)
                  : handleAddNewClick
              }
              className="font-montserrat flex items-center gap-2 px-3.5 py-2 rounded-lg cursor-pointer border border-[#D0D5DD] shadow"
            >
              <CiEdit className="w-5 h-5 text-[#292D32] " aria-hidden="true" />
              <span className="font-medium text-sm text-black">Edit</span>
            </div>
          </div>

          <div className="font-montserrat w-full p-6 border border-gray-300 rounded-xl mt-6 ">
            <div className="flex justify-between items-start">
              <div className="lg:w-[36.063rem]">
                <div className="font-montserrat flex flex-col gap-y-5">
                  <div className="">
                    <h4 className="text-sm tracking-[0.1px] text-gray-400 mb-2">
                      Website
                    </h4>
                    <a
                      href={`${datum?.website}`}
                      target="_blank"
                      className="text-sm tracking-[0.1px] text-black hover:text-primary-700 hover:underline transition-all duration-150  ease-in-out"
                    >
                      {datum?.website ?? "No website"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="font-montserrat w-full p-6 border border-gray-300 rounded-xl mt-6 ">
            <div className="flex justify-between items-center">
              <div className="">
                <h5 className="text-sm text-gray-600">CV/ Resume</h5>
                <p className="text-[10px] text-gray-400">
                  Supported formats:{" "}
                  <span className="text-primary-700">PDF only</span>
                </p>
              </div>
              <div className="flex mobile:hidden items-center gap-2 px-3.5 py-2 rounded-lg ring-1 ring-[#D0D5DD] shadow">
                <button
                  disabled={deleting}
                  onClick={() => handleDeleteClick(datum.id)}
                  className="bg-white rounded-sm text-gray-400  hover:text-gray-500 
               focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  <CiTrash
                    className="w-5 h-5 text-[#292D32] cursor-pointer"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>

            <div className="font-montserrat mt-8">
              <div className="w-full flex flex-col lg:flex-row lg:justify-between gap-y-5 lg:gap-y-0 items-start gap-x-5">
                <a
                  href={user?.portfolio[0]?.resume}
                  target="_blank"
                  className="w-full "
                >
                  <div className="w-full p-4 border border-gray-300 rounded-xl">
                    <div className="flex justify-between items-start">
                      <div className="">
                        <p className="font-medium text-sm text-gray-700 ">
                          {user?.fullName?.split(" ")[0]}'s Resume
                        </p>
                        <p className="text-sm text-gray-700">4.2 MB</p>
                      </div>

                      <div
                        className="w-4 h-4 p-2 rounded-full border border-primary
                   bg-primary flex justify-center items-center"
                      >
                        <span className="text-white text-xs font-bold ">
                          &#10003;
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div className="mobile:w-full mobile:border-t md:hidden border-gray-300 mobile:flex justify-end mobile:pt-5 mobile:mt-5">
              <div className="flex items-center gap-2 px-3.5 py-2 rounded-lg ring-1 ring-[#D0D5DD] shadow">
                <button
                  disabled={deleting}
                  onClick={() => handleDeleteClick(datum.id)}
                  className="bg-white rounded-sm text-gray-400  hover:text-gray-500 
               focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  <CiTrash
                    className="w-5 h-5 text-[#292D32] cursor-pointer"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
      {user?.portfolio === null ||
        (user?.portfolio?.length === 0 && (
          <>
            <h4 className="font-montserrat font-semibold text-base text-black mb-5">
              Portfolio
            </h4>
            <div className="border border-gray-300 flex justify-center items-center py-14 w-full rounded-xl">
              <h4 className="max-w-80 text-center text-black text-sm">
                You have not added any portfolio information yet. Kindly{" "}
                <button
                  onClick={handleAddNewClick}
                  className="text-primary underline"
                >
                  click here
                </button>{" "}
                to add your website and CV/Resume
              </h4>
            </div>
          </>
        ))}
      <PortfolioModal
        visible={showPortfolio}
        data={selectedData}
        refetch={refetch}
        onClose={() => {
          setShowPortfolio(false);
        }}
      />
    </>
  );
}
