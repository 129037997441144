import { Fragment, Suspense, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { TbLogout } from "react-icons/tb";
import logo from "@/assets/images/logo.png";
import maleImage from "@/assets/images/male.jpeg";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "@/utils";
import { Link, Navigate, Outlet, useNavigate } from "react-location";
import SignoutModal from "../components/signout";
import { setMe, useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import routes from "@/router/routes";
import { LocationGenerics } from "@/router/location";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from "@/constants/socials";
import LoadingComponent from "@/components/loaders/top-loader";
import Show from "@/components/core/show";
import { IoIosArrowForward } from "react-icons/io";
import { useQuery } from "@apollo/client";
import ImageLoader from "@/components/loaders/ImageLoader";
import { GET_PROFILE } from "@/apollo/graphql/queries/profile";
import { GET_SAVED_JOBS } from "@/apollo/graphql/queries/job";
import { GET_NOTIFICATIONS } from "@/apollo/graphql/queries/notification";
import _ from "lodash";
import { useNetworkState } from "@uidotdev/usehooks";
import Avatar from "@/components/core/avatar";
import toast from "react-hot-toast";
import {
  DEV_EMPLOYER_URL,
  DEV_LANDING_URL,
  PROD_EMPLOYER_URL,
  PROD_LANDING_URL,
  TEST_EMPLOYER_URL,
  TEST_LANDING_URL,
} from "@/constants/page-path";

export default function AppLayout() {
  const network = useNetworkState();

  const navigate = useNavigate<LocationGenerics>();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const currentUser = useCurrentUser();
  const currentToken = useCurrentToken();

  const isLoggedIn = currentToken && currentUser?.id;

  const { data, refetch, loading } = useQuery(GET_PROFILE);

  const user = data?.jobSeeker;

  const { data: savedJobsData, refetch: refetchSavedJobs } = useQuery(
    GET_SAVED_JOBS,
    {
      variables: {
        candidateId: user?.id,
        pagination: {
          limit: 50,
          offset: 0,
        },
        sort: {},
      },
    }
  );

  const { data: notificationData, refetch: refetchNotifications } = useQuery(
    GET_NOTIFICATIONS,
    {
      variables: {
        pagination: {
          limit: 20,
          offset: 0,
        },
        filter: {
          userId: {
            eq: user?.id,
          },
        },
      },
    }
  );

  useEffect(() => {
    if (!network.online) {
      toast(
        JSON.stringify({
          type: "error",
          title:
            "Network Connection Issue: It appears you are experiencing a weak network connection.",
        })
      );
    }
    refetch();
    refetchSavedJobs();
    refetchNotifications();
    if (data?.jobSeeker) {
      setMe(data?.jobSeeker);
    }
  }, [data, savedJobsData, notificationData, network]);

  const numberOfSavedJobs = savedJobsData?.getAllSavedJobs?.count;

  const unreadNotificationsCount =
    notificationData?.getNotifications?.rows?.filter(
      (notification: any) => notification?.read === false
    ).length ?? 0;

  if (loading) return <ImageLoader />;

  return (
    <>
      <div className="w-full max-w-[76rem] mx-auto">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <Link
                    to={"/"}
                      // href={
                      //   import.meta.env.VITE_NODE_ENV === "development"
                      //     ? `/`
                      //     : import.meta.env.VITE_NODE_ENV === "production"
                      //     ? `${PROD_LANDING_URL}`
                      //     : import.meta.env.VITE_NODE_ENV === "test"
                      //     ? `${TEST_LANDING_URL}`
                      //     : "/nolink"
                      // }
                      className="flex h-16 shrink-0 items-center"
                    >
                      <img
                        className="h-7 w-auto"
                        src={logo}
                        alt="Adjuma Logo"
                      />
                    </Link>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {routes
                              .filter(
                                (route) =>
                                  route.meta?.layout === "App" &&
                                  !_.isEmpty(route.sidebar?.label)
                              )
                              .map((item) => (
                                <li key={item.sidebar?.label}>
                                  <div
                                    onClick={() => {
                                      navigate({
                                        to: `${
                                          item.path !== "/"
                                            ? isLoggedIn
                                              ? `/${item.path}`
                                              : "/login"
                                            : "/"
                                        }`,
                                      });
                                      setSidebarOpen(false);
                                    }}
                                    className={classNames(
                                      item.path ===
                                        (item.path !== "/"
                                          ? location.pathname.slice(1)
                                          : location.pathname)
                                        ? "bg-gray-50 text-primary-600"
                                        : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    {item.sidebar && (
                                      <span className="relative">
                                        <Show
                                          if={
                                            item.sidebar?.label ===
                                              "Saved Jobs" ||
                                            item.sidebar?.label ===
                                              "Messages" ||
                                            item.sidebar?.label ===
                                              "Notifications"
                                          }
                                        >
                                          <span className="rounded-full absolute -top-1 -right-1  text-[9px] truncate bg-primary text-white border-[0.4px] border-white h-4 w-4 flex-center">
                                            {item.sidebar?.label ===
                                            "Saved Jobs"
                                              ? numberOfSavedJobs
                                              : item.sidebar?.label ===
                                                "Messages"
                                              ? 0
                                              : unreadNotificationsCount}
                                          </span>
                                        </Show>
                                        <item.sidebar.icon
                                          className={classNames(
                                            item.path ===
                                              (item.path !== "/"
                                                ? location.pathname.slice(1)
                                                : location.pathname)
                                              ? "text-primary-600"
                                              : "text-gray-400 group-hover:text-primary-600",
                                            "h-6 w-6 shrink-0"
                                          )}
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                    {item.sidebar?.label}
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </li>

                        {!isLoggedIn && (
                          <div className="mt-5 space-y-1">
                            <a
                              href={
                                import.meta.env.VITE_NODE_ENV === "development"
                                  ? `${DEV_EMPLOYER_URL}/signup`
                                  : import.meta.env.VITE_NODE_ENV ===
                                    "production"
                                  ? `${PROD_EMPLOYER_URL}/signup`
                                  : import.meta.env.VITE_NODE_ENV === "test"
                                  ? `${TEST_EMPLOYER_URL}/signup`
                                  : "/nolink"
                              }
                              target="_blank"
                              className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                            >
                              Join as Employer
                            </a>
                            <Link
                              to={"/login"}
                              className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                            >
                              Log in
                            </Link>
                            <Link
                              to={"/signup"}
                              className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                            >
                              Sign up
                            </Link>
                          </div>
                        )}

                        <li className="mt-auto border-t pt-5 flex flex-col ">
                          <img src={logo} alt="" className="w-14 m-auto" />
                          <a
                            href={
                              import.meta.env.VITE_NODE_ENV === "development"
                                ? `${DEV_LANDING_URL}/contact`
                                : import.meta.env.VITE_NODE_ENV === "production"
                                ? `${PROD_LANDING_URL}/contact`
                                : import.meta.env.VITE_NODE_ENV === "test"
                                ? `${TEST_LANDING_URL}/contact`
                                : "/nolink"
                            }
                            target="_blank"
                            className="w-full text-xs text-gray-500 text-center my-2"
                          >
                            Contact Support
                          </a>
                          <div className="flex justify-center items-center w-full gap-3 mt-2 mb-5">
                            <a href={FACEBOOK}>
                              <BiLogoFacebookCircle className="text-gray-600 w-4 h-4" />
                            </a>
                            <a href={TWITTER}>
                              <FaXTwitter className="text-gray-600 w-4 h-4" />
                            </a>
                            <a href={INSTAGRAM}>
                              <FaInstagram className="text-gray-600 w-4 h-4" />
                            </a>
                            <a href={LINKEDIN}>
                              <FaLinkedin className="text-gray-600 w-4 h-4" />
                            </a>
                          </div>
                          <div className="w-full text-center text-xs text-gray-500 ">
                            <p className="">&copy; 2024</p>
                            <p className="font-medium">Adjuma.io</p>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:z-40 md:flex md:w-16 lg:w-48 md:flex-col xl:w-64 ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-300 bg-white  pb-4 md:pt-16 lg:pt-0">
            <Link
              to={"/"}
              // href={
              //   import.meta.env.VITE_NODE_ENV === "development"
              //     ? "/"
              //     : import.meta.env.VITE_NODE_ENV === "production"
              //     ? `${PROD_LANDING_URL}`
              //     : import.meta.env.VITE_NODE_ENV === "test"
              //     ? `${TEST_LANDING_URL}`
              //     : "/nolink"
              // }
              className="flex h-16 shrink-0 items-center border-b border-gray-300 lg:flex md:hidden px-6"
            >
              <img className="h-7 w-auto" src={logo} alt="Adjuma Logo" />
            </Link>
            <nav className="flex flex-1 flex-col px-6">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-2.5">
                    {routes
                      .filter(
                        (route) =>
                          route.meta?.layout === "App" &&
                          !_.isEmpty(route.sidebar?.label)
                      )
                      .map((item) => (
                        <li key={item.sidebar?.label}>
                          <Link
                            to={`${
                              item.path !== "/"
                                ? isLoggedIn
                                  ? `/${item.path}`
                                  : "/login"
                                : "/"
                            }`}
                            className={classNames(
                              item.path ===
                                (item.path !== "/"
                                  ? location.pathname.slice(1)
                                  : location.pathname)
                                ? "bg-gray-50 text-primary-600"
                                : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            {item.sidebar && (
                              <span className="relative ">
                                <Show
                                  if={
                                    item.sidebar?.label === "Saved Jobs" ||
                                    item.sidebar?.label === "Messages" ||
                                    item.sidebar?.label === "Notifications"
                                  }
                                >
                                  <span className="rounded-full absolute -top-1 -right-1  text-[9px] truncate bg-primary text-white border-[0.4px] border-white h-4 w-4 flex-center">
                                    {item.sidebar?.label === "Saved Jobs"
                                      ? numberOfSavedJobs
                                      : item.sidebar?.label === "Messages"
                                      ? 0
                                      : unreadNotificationsCount}
                                  </span>
                                </Show>
                                <item.sidebar.icon
                                  className={classNames(
                                    item.path ===
                                      (item.path !== "/"
                                        ? location.pathname.slice(1)
                                        : location.pathname)
                                      ? "text-primary-600"
                                      : "text-gray-400 group-hover:text-primary-600",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                            <span className="md:hidden lg:flex">
                              {item.sidebar?.label}
                            </span>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>

                <li className=" mt-auto border-t pt-5 md:hidden lg:block font-medium">
                  <img src={logo} alt="logo" className="w-14 m-auto" />
                  <p className="w-full flex justify-center items-center my-2">
                    <a
                      href={
                        import.meta.env.VITE_NODE_ENV === "development"
                          ? `${DEV_LANDING_URL}/contact`
                          : import.meta.env.VITE_NODE_ENV === "production"
                          ? `${PROD_LANDING_URL}/contact`
                          : import.meta.env.VITE_NODE_ENV === "test"
                          ? `${TEST_LANDING_URL}/contact`
                          : "/nolink"
                      }
                      target="_blank"
                      className=" text-xs text-gray-500  text-center"
                    >
                      Contact Support
                    </a>
                  </p>

                  <div className="flex justify-center items-center w-full gap-3 mt-2 mb-5">
                    <a href={FACEBOOK}>
                      <BiLogoFacebookCircle className="text-gray-600 w-4 h-4" />
                    </a>
                    <a href={TWITTER}>
                      <FaXTwitter className="text-gray-600 w-4 h-4" />
                    </a>
                    <a href={INSTAGRAM}>
                      <FaInstagram className="text-gray-600 w-4 h-4" />
                    </a>
                    <a href={LINKEDIN}>
                      <FaLinkedin className="text-gray-600 w-4 h-4" />
                    </a>
                  </div>
                  <div className="w-full text-center text-xs text-gray-500 select-none">
                    <p className="">&copy; 2024</p>
                    <p className="font-medium">Adjuma.io</p>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="md:pl-16 lg:pl-48 xl:pl-64 ">
          <div className="fixed  top-0 right-0 left-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-300 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6">
            <div className="w-full max-w-[76rem] mx-auto flex items-center">
              <div className="flex justify-start items-center space-x-2">
                {" "}
                <Link
                  to={"/"}
                  // href={
                  //   import.meta.env.VITE_NODE_ENV === "development"
                  //     ? `/`
                  //     : import.meta.env.VITE_NODE_ENV === "production"
                  //     ? `${PROD_LANDING_URL}`
                  //     : import.meta.env.VITE_NODE_ENV === "test"
                  //     ? `${TEST_LANDING_URL}`
                  //     : "/nolink"
                  // }
                  className="flex h-16 shrink-0 items-center  xl:pl-6 "
                >
                  <img className="h-7 w-auto " src={logo} alt="Adjuma Logo" />
                </Link>
              </div>

              <div className="flex flex-1 gap-x-4 justify-end md:gap-x-6">
                <div className="flex items-center gap-x-4 md:gap-x-6 ">
                  {/* Profile dropdown */}
                  {isLoggedIn ? (
                    <Menu as="div" className="relative">
                      <Menu.Button className="-m-1.5 flex items-center py-1.5">
                        <span className="sr-only">Open user menu</span>
                        <div className="w-10 h-10 rounded-full overflow-hidden bg-gray-50">
                          <img
                            className="h-full w-full rounded-full object-cover"
                            src={`${user?.avatar || maleImage}`}
                            alt="profile-image"
                          />
                          {/* <Avatar
                            src={`${user?.avatar || ""}`}
                            alt={`${user?.fullName || ""}`}
                            size="sm"
                          /> */}
                        </div>
                        {/*  */}
                        <span className="hidden md:flex md:items-center">
                          <ChevronDownIcon
                            className="ml-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-56  origin-top-right rounded-md bg-white py-2 shadow-xl ring-1 ring-gray-900/5 focus:outline-none">
                          <Menu.Item>
                            {() => (
                              <div
                                className={classNames(
                                  "block px-3 py-1 text-sm leading-6 text-gray-900"
                                )}
                              >
                                <div className="flex flex-col items-center gap-2 py-2.5">
                                  <div className="w-10 h-10 rounded-full overflow-hidden bg-gray-50">
                                    <img
                                      className="w-full h-full object-cover"
                                      src={`${user?.avatar || maleImage}`}
                                      alt="profile-image"
                                    />
                                  </div>
                                  <div className="flex-1 text-center">
                                    <h4 className="font-montserrat font-medium text-base/4 w-full  text-neutral-600 tracking-[-0.5%] truncate max-w-[12rem] ">
                                      {user?.fullName ?? "Full Name"}
                                    </h4>
                                    <p className="font-montserrat font-medium text-neutral-400 text-sm tracking-[-0.5%] truncate max-w-[12rem]">
                                      {user?.email ?? "example@gmail.com"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/profile"
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block  text-xs  text-gray-900 px-4 border-t border-b border-[#CBD5E1]"
                                )}
                              >
                                <div className="flex cursor-pointer  justify-between items-center py-2.5">
                                  <span className="font-montserrat text-sm text-black ">
                                    View Profile
                                  </span>
                                  <IoIosArrowForward
                                    className="text-[#CBD5E1] w-6 h-6"
                                    aria-hidden="true"
                                  />
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                className={classNames(
                                  active ? "bg-gray-50" : "",
                                  "block  text-xs  text-gray-900 px-4"
                                )}
                              >
                                <div
                                  onClick={() => setModalVisible(true)}
                                  className="flex text-red-500 cursor-pointer items-center space-x-3 py-2.5"
                                >
                                  <TbLogout
                                    className="w-5 h-5 "
                                    aria-hidden="true"
                                  />
                                  <span className="font-montserrat text-base">
                                    Sign out
                                  </span>
                                </div>
                              </div>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : (
                    <div className="hidden sm:ml-6 sm:flex  sm:space-x-4 lg:space-x-8  sm:items-center">
                      <a
                        href={
                          import.meta.env.VITE_NODE_ENV === "development"
                            ? `${DEV_EMPLOYER_URL}/signup`
                            : import.meta.env.VITE_NODE_ENV === "production"
                            ? `${PROD_EMPLOYER_URL}/signup`
                            : import.meta.env.VITE_NODE_ENV === "test"
                            ? `${TEST_EMPLOYER_URL}/signup`
                            : "/nolink"
                        }
                        target="_blank"
                        className="text-xs lg:text-sm font-semibold  text-nowrap  leading-4 text-primary hidden md:block"
                      >
                        Join as Employer
                      </a>
                      <Link
                        to={"/login"}
                        className="w-[4rem] lg:w-[5.375rem] h-[2.5rem] lg:h-[2.875rem] md:flex justify-center items-center rounded-lg p-[10px]
                     border bg-white border-primary text-primary text-center
                      font-montserrat text-xs lg:text-sm text-nowrap leading-4 font-semibold hidden "
                        type="button"
                      >
                        Log in
                      </Link>
                      <Link
                        to={"/signup"}
                        className="w-[6rem] lg:w-[9.5rem] h-[2.5rem] lg:h-[2.875rem] md:flex justify-center items-center rounded-lg p-[10px]
                     border bg-primary border-primary text-white text-center
                      font-montserrat text-xs lg:text-sm text-nowrap leading-4 font-semibold hidden"
                        type="button"
                      >
                        Sign up
                      </Link>
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-700 md:hidden"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon
                    className="h-6 w-6 text-[#344054]"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
          <main className="w-full">
            <div className="w-full relative">
              <Suspense fallback={LoadingComponent()}>
                <Outlet />
              </Suspense>
            </div>
          </main>
        </div>
      </div>
      <SignoutModal
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
}
