import React from "react";
import rafiki from "@/assets/images/rafiki.png";

const EmptyState = () => {
  return (
    <div className="flex flex-col  items-center">
      <img
        src={rafiki}
        alt="company-logo"
        className="w-64 h-auto object-contain py-5 "
      />
      <h4 className="font-bold text-center text-black py-">
        No jobs available at the moment
      </h4>
      <p className=" text-sm text-gray-400 text-center">
        You will be notified when the next job post is live
      </p>
    </div>
  );
};

export default EmptyState;
