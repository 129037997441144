import { useCurrentUser } from "@/apollo/cache/auth";
import Show from "@/components/core/show";
import { getFormattedDate } from "@/helpers/date";
import {
  BookmarkSlashIcon,
  ClockIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import React, { FC, useState } from "react";
import DescriptionList from "./description-list";
import { HiMiniCalendarDays } from "react-icons/hi2";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { WITHDRAW_APPLICATION } from "@/apollo/graphql/mutations/job";

interface Job {
  id: string;
  title: string;
  location: string;
  postedDate: string;
  salary: {
    maximum: number;
    minimum: number;
    currency: string;
  };
  type: string;
  mode: string;
  description: string;
  likes: number;
  comments: number;
  shares: number;
  activelyHiring: boolean;
  status: string;
  about: string;
  responsibilities: string;
  requirements: string;
  skills: string;
  benefits: string;
  company: {
    id: string;
    name: string;
    about: string;
    companyLogo: string;
  };
}

interface JobComponentProps {
  appliedJob: Job;
  appliedAt: string;
  candidateId: string;
  companyId: string;
  createdAt: string;
  id: string;
}

interface AppliedJobComponentProps {
  job: JobComponentProps;
  refetch: () => void;
  handleWithdrawJob: (jobId: string) => void;
}

const AppliedJobDetails: FC<AppliedJobComponentProps> = ({
  job,
  refetch,
  handleWithdrawJob,
}) => {
  const user = useCurrentUser();
  const [withdraw, setWithdraw] = useState<boolean>(false);

  const [withdrawJob, { loading }] = useMutation(WITHDRAW_APPLICATION);

  const handleWithdraw = async () => {
    try {
      await withdrawJob({
        variables: {
          applicationId: job?.id,
          jobId: job?.appliedJob?.id,
        },
      })
        .then(({ data }) => {
          if (data?.withdrawApplication) {
            refetch();
            handleWithdrawJob(job.id);
            toast(
              JSON.stringify({
                type: "success",
                title: `Job successfully withdrawn`,
              })
            );
          }
        })
        .catch((error) => {
          toast(
            JSON.stringify({
              type: "error",
              title:
                error?.message || "An error occurred while trying to withdraw ",
            })
          );
        });
    } catch (error) {
      console.log(error);
    }
  };
  //
  return (
    <div className="sm:ring-1 sm:ring-gray-300 rounded-xl p-5 mb-4">
      <div className="flex-between w-full border-b border-gray-300 pb-4">
        <p className="text-sm font-medium">
          <p className="flex items-center gap-1 justify-start">
            <HiMiniCalendarDays className="h-4 w-4 " /> Date Applied
          </p>
          <p className="text-primary-500  mt-1">
            {moment(job?.appliedAt).format("ll")}
          </p>
        </p>
        <button
          onClick={() => setWithdraw(!withdraw)}
          className="relative px-3 py-1.5 border border-primary-500 text-primary-500 text-[11px] rounded-md "
        >
          Withdraw
          <Show if={withdraw}>
            <div className="absolute  top-8 right-0 shadow-md drop-shadow flex flex-col items-center justify-start pb-5 pt-6 px-3 bg-white w-60 rounded-md border border-black/10 text-gray-700">
              <span className="h-6 w-6 border border-primary-500 rounded-full flex-center">
                <BookmarkSlashIcon className="text-white bg-primary-500 h-3 w-3 rounded-full" />
              </span>
              <button className="absolute top-3 right-3 border border-white hover:border-gray-200 rounded-full">
                <XMarkIcon className="h-4 w-4 " />
              </button>
              <h1 className="mt-2 text-sm font-bold">
                Withdraw your application
              </h1>
              <p className="text-[11px] text-gray-500  mb-3 mt-1">
                This action can not be reversed. Your application will be
                withdrawn from the list of applicant
              </p>

              <div className="flex-center w-full gap-3">
                <button className="px-2 py-1 rounded border ">Cancel</button>
                <button
                  onClick={handleWithdraw}
                  className="px-2 py-1 bg-primary-500 text-white rounded"
                >
                  Withdraw
                </button>
              </div>
            </div>
          </Show>
        </button>
      </div>

      <DescriptionList
        list_data={[job?.appliedJob?.about]}
        title={"Job Description"}
      />

      <DescriptionList
        list_data={[job?.appliedJob?.requirements]}
        title={"Requirements/Qualifications"}
        list_disc={true}
      />
      <DescriptionList
        list_data={[job?.appliedJob?.responsibilities]}
        title={"Responsibilities"}
        list_disc={true}
      />
      {job && job?.appliedJob?.benefits !== "" && (
        <DescriptionList
          list_data={[job?.appliedJob?.benefits]}
          title={"Benefits"}
          list_disc={true}
        />
      )}
      <DescriptionList
        list_data={[
          job?.appliedJob?.company?.about ??
            "Company's description not available🚫",
        ]}
        title={"Company Description"}
      />
    </div>
  );
};

export default AppliedJobDetails;
