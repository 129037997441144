import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ClearChat({ open, setOpen }: Props) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-xl
               bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 w-full max-w-xs p-6"
              >
                <div>
                  <div className="text-center mt-5">
                    <Dialog.Title
                      as="h3"
                      className="font-montserrat text-sm font-semibold text-black"
                    >
                      Clear Chat History
                    </Dialog.Title>
                    <div className="mt-5">
                      <p className="font-montserrat text-xs/5 text-black ">
                        Are you sure you want to clear <br /> this chat history
                        ?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-8 grid grid-cols-2 gap-3">
                  <button
                    type="button"
                    className="font-montserrat inline-flex w-full justify-center rounded-md bg-white px-3 py-3 text-sm text-gray-700 shadow-sm 
                    ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="font-montserrat inline-flex w-full justify-center rounded-md bg-primary-500 px-3 py-3 text-sm
                     text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                      focus-visible:outline-primary-600 "
                    onClick={() => setOpen(false)}
                  >
                    Yes, Clear
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
