import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";


import {
  useAppNaviagte,
} from "@/apollo/cache/auth";
import { useSearch } from "react-location";
import { LocationGenerics } from "@/router/location";
import { wrapClick } from "@/utils";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function RedirectModal({ open, setOpen }: Props) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useAppNaviagte();

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-xl
               bg-white px-6 pb-8 pt-5 text-left shadow-xl transition-all my-8 w-full max-w-sm p-6"
              >
                <div>
                  <div className="flex justify-end">
                    <div
                      onClick={() => setOpen(false)}
                      className="cursor-pointer"
                    >
                      <IoCloseCircleOutline className="w-5 h-5  text-[#51526C]" />
                    </div>
                  </div>
                  <div className="flex justify-center">
                        <div className="w-8 h-8 border-2 border-[#DB0909] flex justify-center items-center rounded-lg">
                        <IoClose className="w-4 h-4 text-[#DB0909] font-bold" />
                        </div>
                  </div>
                  <div className="text-center mt-5">
                    <Dialog.Title
                      as="h3"
                      className="font-montserrat text-lg font-semibold text-black"
                    >
                      You can not apply for this job
                    </Dialog.Title>
                    <div className="mt-3">
                      <p className="font-montserrat text-sm/5 text-black ">
                        Sign in or create account to apply for this job
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-8 grid grid-cols-2 gap-3">
                  <button
                    type="button"
                    className="font-montserrat inline-flex w-full justify-center rounded-md bg-white px-3 py-3 text-sm text-gray-700 shadow-sm 
                    ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
                    onClick={wrapClick(() =>
                      navigate({
                        to: `/login?redirect=${window.location.pathname}?id=${searchParams.id}`,
                      })
                    )}
                  >
                    Sign in
                  </button>
                  <button
                    type="button"
                    className="font-montserrat inline-flex w-full justify-center rounded-md bg-primary-500 px-3 py-3 text-sm
                     text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                      focus-visible:outline-primary-600 "
                    onClick={wrapClick(() =>
                      navigate({
                        to: `/signup?redirect=${window.location.pathname}?id=${searchParams.id}`,
                      })
                    )}
                  >
                    Create Account
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
