import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { CiEdit, CiTrash } from "react-icons/ci";
import EducationModal from "../modals/education";

import toast from "react-hot-toast";
import moment from "moment";
import ImageLoader from "@/components/loaders/ImageLoader";
import { setMe } from "@/apollo/cache/auth";
import { GET_PROFILE } from "@/apollo/graphql/queries/profile";
import { DELETE_EDUCATION } from "@/apollo/graphql/mutations/profile";

export default function Education() {
  const [showEducation, setShowEducation] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const { data, refetch, loading } = useQuery(GET_PROFILE);

  const [deleteEdu, { loading: deleting }] = useMutation(DELETE_EDUCATION);

  useEffect(() => {
    if (data?.jobSeeker) {
      setMe(data?.jobSeeker);
    }
  }, [data]);

  const user = data?.jobSeeker;

  const handleEditClick = (datum: any) => {
    setSelectedData(datum);
    setShowEducation(true);
  };

  const handleDeleteClick = async (id: string) => {
    try {
      await deleteEdu({
        variables: {
          deleteEducationalBackgroundId: id,
        },
      })
        .then(({ data }) => {
          if (data?.deleteEducationalBackground) {
            refetch();
            toast(
              JSON.stringify({
                type: "success",
                title: `Educational background successfully deleted`,
              })
            );
          }
        })
        .catch((error) => {
          toast(
            JSON.stringify({
              type: "error",
              title: error?.message || "Error while deleting Profile",
            })
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddNewClick = () => {
    setSelectedData(null);
    setShowEducation(true);
  };

  if (loading) return <ImageLoader />;

  return (
    <div className="w-full">
      <div className="flex mobile:flex-col mobile:items-start mobile:gap-3 justify-between items-center">
        <h4 className="font-montserrat font-semibold text-base text-black">
          Educational Background
        </h4>

        <button
          type="button"
          onClick={handleAddNewClick}
          className="font-montserrat font-semibold w-[7.938rem] h-[2.5rem] rounded-lg hover:bg-primary-600 transition-all duration-100 ease-in-out flex items-center justify-center text-sm text-white bg-primary-500 borer border-primary-500"
        >
          <span className="text-2xl font-normal mr-2">+</span>Add New
        </button>
      </div>

      {user?.education?.length === 0 ? (
        <div className="font-montserrat w-full p-10 border border-gray-300 rounded-xl mt-6 ">
          <p className="w-full max-w-80 mx-auto text-center">
            You have not provided any educational information yet.{" "}
            <button
              type="button"
              onClick={handleAddNewClick}
              className="text-primary underline font-semibold"
            >
              Click here
            </button>{" "}
            to add your educational details
          </p>
        </div>
      ) : (
        user?.education?.map(
          (
            datum: {
              id: string;
              programme: string;
              school: string;
              startDate: any;
              endDate: any;
              isCurrentlyStudying: any;
            },
            index: React.Key | null | undefined
          ) => (
            <div
              key={index}
              className="font-montserrat w-full p-6 border border-gray-300 rounded-xl mt-6"
            >
              <div className="flex mobile:flex-col justify-between items-start">
                <div className="lg:w-[36.063rem]">
                  <div className="font-montserrat flex flex-col gap-y-5">
                    <div className="">
                      <h4 className="text-sm tracking-[0.1px] text-gray-400 mb-2">
                        Program
                      </h4>
                      <p className="text-sm tracking-[0.1px] text-black">
                        {datum?.programme ?? "N/A"}
                      </p>
                    </div>
                    <div className="">
                      <h4 className="text-sm tracking-[0.1px] text-gray-400 mb-2">
                        School
                      </h4>
                      <p className="text-sm tracking-[0.1px] text-black">
                        {datum?.school ?? "N/A"}
                      </p>
                    </div>
                    <div className="">
                      <h4 className="text-sm tracking-[0.1px] text-gray-400 mb-2">
                        Start Date
                      </h4>
                      <p className="text-sm tracking-[0.1px] text-black">
                        {datum?.startDate
                          ? moment(datum?.startDate).format("MMMM Do YYYY")
                          : "N/A"}
                      </p>
                    </div>
                    <div className="">
                      <h4 className="text-sm tracking-[0.1px] text-gray-400 mb-2">
                        End Date
                      </h4>
                      <p className="text-sm tracking-[0.1px] text-black">
                        {datum?.isCurrentlyStudying
                          ? "Present"
                          : datum?.endDate
                          ? moment(datum.endDate).format("MMMM Do YYYY")
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mobile:w-full mobile:border-t border-gray-300 mobile:flex justify-end mobile:pt-5 mobile:mt-5">
                  <div className="flex items-center mobile:gap-4 gap-2 px-3.5 py-2 rounded-lg ring-1 ring-[#D0D5DD] shadow">
                    <button
                      onClick={() => handleEditClick(datum)}
                      className="bg-white rounded-sm text-gray-400  hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      <CiEdit
                        className="w-5 h-5 text-[#292D32] cursor-pointer"
                        aria-hidden="true"
                      />
                    </button>
                    <button
                      disabled={deleting}
                      onClick={() => handleDeleteClick(datum.id)}
                      className="bg-white rounded-sm text-gray-400  hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      <CiTrash
                        className="w-5 h-5 text-[#292D32] cursor-pointer"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        )
      )}
      <EducationModal
        visible={showEducation}
        data={selectedData}
        refetch={refetch}
        onClose={() => {
          setShowEducation(false);
        }}
      />
    </div>
  );
}
