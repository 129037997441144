/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation as useMutationTanstack } from "@tanstack/react-query";
import Select from "@/components/core/select";

import { useEffect, useState } from "react";
import { regions } from "@/constants/regions";
import { LuUploadCloud } from "react-icons/lu";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMutation } from "@apollo/client";

import toast from "react-hot-toast";
import { UPDATE_USER } from "@/apollo/graphql/mutations/auth";
import { UPLOAD_FILE } from "@/helpers/upload-file";
import { setMe } from "@/apollo/cache/auth";
import Modal from "@/components/layouts/modal";
import { useFormik } from "formik";
import male from "@/assets/images/male.jpeg";

import { ClipLoader } from "react-spinners";
import ButtonLoader from "@/components/loaders/button";
import { UPDATE_TALENT_INFO } from "@/apollo/graphql/mutations/profile";
import CountryList from "@/pages/onboarding/components/countryInput";

const ProfileModal = ({ visible, onClose, refetch, data }: any) => {
  const [valid, setValid] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState<string>(
    data?.address ?? "Select a country"
  );

  const [updateTalentInfo, { data: updatedInfo, loading, error }] =
    useMutation(UPDATE_TALENT_INFO);

  const handleCountrySelect = (country: string) => {
    setSelectedCountry(country);
    form.setFieldValue("address", country);
  };

  const form = useFormik({
    initialValues: {
      fullName: data?.fullName,
      address: data?.address,
      phoneNumber: data?.phoneNumber,
      avatar: data?.avatar,
    },
    onSubmit: async (values) => {
      // setLoading(true);
      const response = await updateTalentInfo({
        variables: {
          input: {
            fullName: values.fullName,
            address: values.address,
            phoneNumber: values.phoneNumber,
            avatar: values.avatar,
          },
        },
      });

      if (response?.data?.updateTalentInfo) {
        refetch();
        onClose();
        toast(
          JSON.stringify({
            type: "success",
            title: `Profile successfully updated`,
          })
        );
      }
    },
  });

  useEffect(() => {
    form.setValues({
      fullName: data?.fullName || "",
      address: data?.address || "",
      phoneNumber: data?.phoneNumber || "",
      avatar: data?.avatar || "",
    });
  }, [data]);

  const { isLoading, mutate: uploadFile } = useMutationTanstack({
    mutationKey: ["uploadFile"],
    mutationFn: UPLOAD_FILE,
  });

  const handleUpload = (file: any) => {
    const data = new FormData();
    data.append("file", file);
    uploadFile(data, {
      onSuccess: (data) => {
        //console.log(data.data.companyLogo[0].url);
        form.setFieldValue("avatar", data?.url);
      },
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      handleUpload(selectedFile);
    }
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneNumberPattern = /^\d{12}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  if (!visible) return null;

  return (
    <Modal
      open={visible}
      setOpen={() => {
        onClose();
      }}
      hideActions={false}
      description="Profile Information"
      renderActions={() => (
        <>
          <button
            disabled={loading}
            onClick={() => {
              form.handleSubmit();
            }}
            className="w-[8rem] h-[2.5rem] flex justify-center items-center font-montserrat font-normal
                 text-sm capitalize leading-5 text-center
                  bg-primary rounded-lg text-white
                  border border-primary "
            type="button"
          >
            {loading ? <ButtonLoader title="Saving..." /> : " Save changes"}
          </button>
        </>
      )}
    >
      {!data?._id ? (
        <>
          <div className="mt-3">
            <div>
              <div className="mb-3">
                <label
                  className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]"
                  htmlFor="fullname"
                >
                  Full name
                </label>

                <input
                  id="fullName"
                  {...form.getFieldProps("fullName")}
                  type="text"
                  placeholder="Enter your full name"
                  className="input-base mt-2"
                />
              </div>
              <div className="mb-3 lg:mb-2">
                <label
                  className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]"
                  htmlFor="region"
                >
                  Country
                </label>
                <div className="input-base mt-2 flex justify-center items-center">
                  {/* <Select
                    options={regions}
                    onChange={(value: any) =>
                      form.setFieldValue("address", value)
                    }
                    defaultValue={data?.address || "N/A"}
                  /> */}
                  <CountryList
                    selectedCountry={selectedCountry}
                    onCountrySelect={handleCountrySelect}
                  />
                </div>
              </div>

              <div className="mb-3 lg:mb-2">
                <label
                  className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]"
                  htmlFor="phoneNumber"
                >
                  phone number
                </label>

                <PhoneInput
                  country={"gh"}
                  placeholder={"Mobile Number"}
                  value={form.values.phoneNumber}
                  onChange={(value: any) =>
                    form.setFieldValue("phoneNumber", value)
                  }
                  countryCodeEditable={false}
                  enableSearch={true}
                  inputProps={{
                    required: true,
                    title: "Enter your phone number here",
                    className: "input-base mt-2",
                  }}
                  inputStyle={{
                    width: "80%",
                    marginLeft: "20%",
                  }}
                  buttonStyle={{
                    width: "18%",
                    borderRadius: "0.5rem",
                    borderColor: "rgb(156 163 175)",
                    paddingInline: "0.5rem",
                    marginTop: "0.5rem",
                  }}
                  dropdownStyle={{
                    borderRadius: "0.5rem",
                    borderColor: "rgb(156 163 175)",
                    paddingInline: "1rem",
                  }}
                  searchStyle={{
                    width: "90%",
                    borderRadius: "0.5rem",
                    borderColor: "rgb(156 163 175)",
                    paddingInline: "1rem",
                  }}
                />
              </div>
              <div className="">
                <label
                  className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.1px]"
                  htmlFor="phoneNumber"
                >
                  profile image
                </label>

                <div className="flex items-center gap-4 mt-2">
                  {isLoading ? (
                    <div
                      className={` flex justify-center items-center w-[64px] h-[64px] md:h-[58px] rounded-full overflow-hidden  mb-4 md:mb-0`}
                    >
                      <ClipLoader
                        color={"#02733E"}
                        loading={true}
                        size={30}
                        //cssOverride={{ width: "30%" }}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  ) : form?.values?.avatar ? (
                    <img
                      src={form.values.avatar}
                      alt="profile picture"
                      className="w-[64px] h-[64px] object-cover mb-4 md:mb-0 rounded-full"
                    />
                  ) : (
                    <img
                      src={male}
                      alt="profile picture"
                      className="w-[64px] h-[64px] object-cover mb-4 md:mb-0 rounded-full "
                    />
                  )}

                  <div className="flex flex-1 w-full py-5 flex-col justify-center items-center border border-gray-400 rounded-xl">
                    <label
                      htmlFor="file"
                      className="flex items-center gap-2 cursor-pointer"
                    >
                      <span className="w-[40px] h-[40px] flex justify-center items-center bg-grey-50 rounded-full">
                        <div className="flex justify-center items-center bg-grey-100 w-5 h-5 rounded-full">
                          <LuUploadCloud className="text-grey-600 " size={27} />
                        </div>
                      </span>
                      <input
                        type="file"
                        name="file"
                        id="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>

                    <div className="font-montserrat text-center">
                      <p className="text-sm text-gray-600 leading-5 text-center">
                        <span className="text-primary">Click to upload</span> or
                        drag and drop
                      </p>
                      <p className="text-xs text-gray-600 leading-[18px] text-center">
                        SVG, PNG or JPG (max. 800x400px)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex-1 w-full max-h-[65vh] overflow-y-auto  sm:p-6">
          <div>
            <h1>NO DATA</h1>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ProfileModal;
