import Show from "@/components/core/show";
import Slideover from "@/components/layouts/slide-over";
import { FC } from "react";
import AppliedJobDetails from "./applied-job-detail";
import emailFooter from "@/assets/images/email-footer.png";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from "@/constants/socials";

type Props = {
  open: boolean;
  refetch: () => void;
  handleWithdrawJob?: (jobId: string) => void;
  setOpen: (value: boolean) => void;
  job: any;
};

const DetailSliderContainer: FC<Props> = ({
  open,
  setOpen,
  job,
  handleWithdrawJob = () => {},
  refetch,
}) => {
  return (
    <Slideover open={open} setOpen={setOpen}>
      <div className="w-full h-full overflow-scroll overflow-y-scroll no-scrollbar">
        <div className="w-full">
          <AppliedJobDetails
            refetch={refetch}
            job={job}
            handleWithdrawJob={handleWithdrawJob}
          />
          <div className="mt-8">
            <div className="w-full rounded-xl border border-gray-300 p-5 mb-14">
              <img src={emailFooter} alt="email-footer" />
              <div className="flex justify-start items-center w-full gap-3 mt-4">
                <a href={FACEBOOK}>
                  <BiLogoFacebookCircle className="text-gray-400 w-6 h-6" />
                </a>
                <a href={TWITTER}>
                  <FaXTwitter className="text-gray-400 w-5 h-5" />
                </a>
                <a href={INSTAGRAM}>
                  <FaInstagram className="text-gray-400 w-5 h-5" />
                </a>
                <a href={LINKEDIN}>
                  <FaLinkedin className="text-gray-400 w-5 h-5" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slideover>
  );
};

export default DetailSliderContainer;
