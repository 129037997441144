// src/api.ts
export interface Country {
    name: {
      common: string;
    };
  }
  
  export const getAllCountries = async (): Promise<string[]> => {
    try {
      const response = await fetch('https://restcountries.com/v3.1/all');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: Country[] = await response.json();
      return data.map(country => country.name.common);
    } catch (error) {
      console.error('Error fetching countries:', error);
      return [];
    }
  };
  