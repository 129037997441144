import React, { useState, FC, useEffect } from "react";

import ChatDetails from "./components/details";

import emailFooter from "@/assets/images/email-footer.png";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from "@/constants/socials";

import { FiSearch } from "react-icons/fi";
import Wrapper from "@/components/wrapper";
import { useUrlState } from "@/utils";
import _ from "lodash";
import ChatList from "./components/list";
import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import { Navigate } from "react-location";

interface Chat {
  id: number;
  name: string;
  lastMessage: string;
  messages: { id: number; text: string; sender: string; timestamp: string }[];
}

// Sample data for demonstration
const sampleChats: Chat[] = [
  {
    id: 1,
    name: "James",
    lastMessage: "Apply to the Adjuma job postings",
    messages: [
      {
        id: 1,
        text: "Hi, I saw your profile and I think you would be a great fit for our company.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:30 PM",
      },
      {
        id: 2,
        text: "Hello James, thank you for reaching out. I am interested in learning more.",
        sender: "You",
        timestamp: "Apr 12, 2024, 7:45 PM",
      },
      {
        id: 3,
        text: "Great! I will send you the job description and we can schedule an interview.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:50 PM",
      },
      {
        id: 1,
        text: "Hi, I saw your profile and I think you would be a great fit for our company.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:30 PM",
      },
      {
        id: 2,
        text: "Hello James, thank you for reaching out. I am interested in learning more.",
        sender: "You",
        timestamp: "Apr 12, 2024, 7:45 PM",
      },
      {
        id: 3,
        text: "Great! I will send you the job description and we can schedule an interview.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:50 PM",
      },
      {
        id: 1,
        text: "Hi, I saw your profile and I think you would be a great fit for our company.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:30 PM",
      },
      {
        id: 2,
        text: "Hello James, thank you for reaching out. I am interested in learning more.",
        sender: "You",
        timestamp: "Apr 12, 2024, 7:45 PM",
      },
      {
        id: 3,
        text: "Great! I will send you the job description and we can schedule an interview.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:50 PM",
      },
    ],
  },
  {
    id: 2,
    name: "Isaac",
    lastMessage: "Meeting rescheduled to 2 PM tomorrow",
    messages: [
      {
        id: 1,
        text: "Could we possibly look into rescheduling our meeting?",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:00 AM",
      },
      {
        id: 2,
        text: "Sure, how does 2 PM tomorrow sound?",
        sender: "Isaac",
        timestamp: "Apr 11, 2024, 10:15 AM",
      },
      {
        id: 3,
        text: "That works for me. Thank you, Isaac!",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:17 AM",
      },
    ],
  },
  {
    id: 3,
    name: "Priscilla",
    lastMessage: "Your appointment has been confirmed for April 15th.",
    messages: [
      {
        id: 1,
        text: "I am confirming your appointment for April 15th at 10 AM.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:30 PM",
      },
      {
        id: 2,
        text: "Thank you for the confirmation, Priscilla.",
        sender: "You",
        timestamp: "Apr 9, 2024, 3:45 PM",
      },
      {
        id: 3,
        text: "You are welcome! Let us know if there is anything else you need.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:50 PM",
      },
    ],
  },
  {
    id: 4,
    name: "James",
    lastMessage: "Apply to the Adjuma job postings",
    messages: [
      {
        id: 1,
        text: "Hi, I saw your profile and I think you would be a great fit for our company.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:30 PM",
      },
      {
        id: 2,
        text: "Hello James, thank you for reaching out. I am interested in learning more.",
        sender: "You",
        timestamp: "Apr 12, 2024, 7:45 PM",
      },
      {
        id: 3,
        text: "Great! I will send you the job description and we can schedule an interview.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:50 PM",
      },
    ],
  },
  {
    id: 5,
    name: "Isaac",
    lastMessage: "Meeting rescheduled to 2 PM tomorrow",
    messages: [
      {
        id: 1,
        text: "Could we possibly look into rescheduling our meeting?",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:00 AM",
      },
      {
        id: 2,
        text: "Sure, how does 2 PM tomorrow sound?",
        sender: "Isaac",
        timestamp: "Apr 11, 2024, 10:15 AM",
      },
      {
        id: 3,
        text: "That works for me. Thank you, Isaac!",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:17 AM",
      },
    ],
  },
  {
    id: 6,
    name: "Priscilla",
    lastMessage: "Your appointment has been confirmed for April 15th.",
    messages: [
      {
        id: 1,
        text: "I am confirming your appointment for April 15th at 10 AM.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:30 PM",
      },
      {
        id: 2,
        text: "Thank you for the confirmation, Priscilla.",
        sender: "You",
        timestamp: "Apr 9, 2024, 3:45 PM",
      },
      {
        id: 3,
        text: "You are welcome! Let us know if there is anything else you need.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:50 PM",
      },
    ],
  },
  {
    id: 7,
    name: "James",
    lastMessage: "Apply to the Adjuma job postings",
    messages: [
      {
        id: 1,
        text: "Hi, I saw your profile and I think you would be a great fit for our company.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:30 PM",
      },
      {
        id: 2,
        text: "Hello James, thank you for reaching out. I am interested in learning more.",
        sender: "You",
        timestamp: "Apr 12, 2024, 7:45 PM",
      },
      {
        id: 3,
        text: "Great! I will send you the job description and we can schedule an interview.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:50 PM",
      },
    ],
  },
  {
    id: 8,
    name: "Isaac",
    lastMessage: "Meeting rescheduled to 2 PM tomorrow",
    messages: [
      {
        id: 1,
        text: "Could we possibly look into rescheduling our meeting?",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:00 AM",
      },
      {
        id: 2,
        text: "Sure, how does 2 PM tomorrow sound?",
        sender: "Isaac",
        timestamp: "Apr 11, 2024, 10:15 AM",
      },
      {
        id: 3,
        text: "That works for me. Thank you, Isaac!",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:17 AM",
      },
    ],
  },
  {
    id: 9,
    name: "Priscilla",
    lastMessage: "Your appointment has been confirmed for April 15th.",
    messages: [
      {
        id: 1,
        text: "I am confirming your appointment for April 15th at 10 AM.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:30 PM",
      },
      {
        id: 2,
        text: "Thank you for the confirmation, Priscilla.",
        sender: "You",
        timestamp: "Apr 9, 2024, 3:45 PM",
      },
      {
        id: 3,
        text: "You are welcome! Let us know if there is anything else you need.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:50 PM",
      },
    ],
  },
  {
    id: 10,
    name: "James",
    lastMessage: "Apply to the Adjuma job postings",
    messages: [
      {
        id: 1,
        text: "Hi, I saw your profile and I think you would be a great fit for our company.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:30 PM",
      },
      {
        id: 2,
        text: "Hello James, thank you for reaching out. I am interested in learning more.",
        sender: "You",
        timestamp: "Apr 12, 2024, 7:45 PM",
      },
      {
        id: 3,
        text: "Great! I will send you the job description and we can schedule an interview.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:50 PM",
      },
    ],
  },
  {
    id: 11,
    name: "Isaac",
    lastMessage: "Meeting rescheduled to 2 PM tomorrow",
    messages: [
      {
        id: 1,
        text: "Could we possibly look into rescheduling our meeting?",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:00 AM",
      },
      {
        id: 2,
        text: "Sure, how does 2 PM tomorrow sound?",
        sender: "Isaac",
        timestamp: "Apr 11, 2024, 10:15 AM",
      },
      {
        id: 3,
        text: "That works for me. Thank you, Isaac!",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:17 AM",
      },
    ],
  },
  {
    id: 12,
    name: "Priscilla",
    lastMessage: "Your appointment has been confirmed for April 15th.",
    messages: [
      {
        id: 1,
        text: "I am confirming your appointment for April 15th at 10 AM.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:30 PM",
      },
      {
        id: 2,
        text: "Thank you for the confirmation, Priscilla.",
        sender: "You",
        timestamp: "Apr 9, 2024, 3:45 PM",
      },
      {
        id: 3,
        text: "You are welcome! Let us know if there is anything else you need.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:50 PM",
      },
    ],
  },
  {
    id: 13,
    name: "James",
    lastMessage: "Apply to the Adjuma job postings",
    messages: [
      {
        id: 1,
        text: "Hi, I saw your profile and I think you would be a great fit for our company.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:30 PM",
      },
      {
        id: 2,
        text: "Hello James, thank you for reaching out. I am interested in learning more.",
        sender: "You",
        timestamp: "Apr 12, 2024, 7:45 PM",
      },
      {
        id: 3,
        text: "Great! I will send you the job description and we can schedule an interview.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:50 PM",
      },
    ],
  },
  {
    id: 14,
    name: "Isaac",
    lastMessage: "Meeting rescheduled to 2 PM tomorrow",
    messages: [
      {
        id: 1,
        text: "Could we possibly look into rescheduling our meeting?",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:00 AM",
      },
      {
        id: 2,
        text: "Sure, how does 2 PM tomorrow sound?",
        sender: "Isaac",
        timestamp: "Apr 11, 2024, 10:15 AM",
      },
      {
        id: 3,
        text: "That works for me. Thank you, Isaac!",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:17 AM",
      },
    ],
  },
  {
    id: 15,
    name: "Priscilla",
    lastMessage: "Your appointment has been confirmed for April 15th.",
    messages: [
      {
        id: 1,
        text: "I am confirming your appointment for April 15th at 10 AM.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:30 PM",
      },
      {
        id: 2,
        text: "Thank you for the confirmation, Priscilla.",
        sender: "You",
        timestamp: "Apr 9, 2024, 3:45 PM",
      },
      {
        id: 3,
        text: "You are welcome! Let us know if there is anything else you need.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:50 PM",
      },
    ],
  },
  {
    id: 16,
    name: "James",
    lastMessage: "Apply to the Adjuma job postings",
    messages: [
      {
        id: 1,
        text: "Hi, I saw your profile and I think you would be a great fit for our company.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:30 PM",
      },
      {
        id: 2,
        text: "Hello James, thank you for reaching out. I am interested in learning more.",
        sender: "You",
        timestamp: "Apr 12, 2024, 7:45 PM",
      },
      {
        id: 3,
        text: "Great! I will send you the job description and we can schedule an interview.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:50 PM",
      },
    ],
  },
  {
    id: 17,
    name: "Isaac",
    lastMessage: "Meeting rescheduled to 2 PM tomorrow",
    messages: [
      {
        id: 1,
        text: "Could we possibly look into rescheduling our meeting?",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:00 AM",
      },
      {
        id: 2,
        text: "Sure, how does 2 PM tomorrow sound?",
        sender: "Isaac",
        timestamp: "Apr 11, 2024, 10:15 AM",
      },
      {
        id: 3,
        text: "That works for me. Thank you, Isaac!",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:17 AM",
      },
    ],
  },
  {
    id: 18,
    name: "Priscilla",
    lastMessage: "Your appointment has been confirmed for April 15th.",
    messages: [
      {
        id: 1,
        text: "I am confirming your appointment for April 15th at 10 AM.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:30 PM",
      },
      {
        id: 2,
        text: "Thank you for the confirmation, Priscilla.",
        sender: "You",
        timestamp: "Apr 9, 2024, 3:45 PM",
      },
      {
        id: 3,
        text: "You are welcome! Let us know if there is anything else you need.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:50 PM",
      },
    ],
  },
  {
    id: 19,
    name: "James",
    lastMessage: "Apply to the Adjuma job postings",
    messages: [
      {
        id: 1,
        text: "Hi, I saw your profile and I think you would be a great fit for our company.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:30 PM",
      },
      {
        id: 2,
        text: "Hello James, thank you for reaching out. I am interested in learning more.",
        sender: "You",
        timestamp: "Apr 12, 2024, 7:45 PM",
      },
      {
        id: 3,
        text: "Great! I will send you the job description and we can schedule an interview.",
        sender: "James",
        timestamp: "Apr 12, 2024, 7:50 PM",
      },
    ],
  },
  {
    id: 20,
    name: "Isaac",
    lastMessage: "Meeting rescheduled to 2 PM tomorrow",
    messages: [
      {
        id: 1,
        text: "Could we possibly look into rescheduling our meeting?",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:00 AM",
      },
      {
        id: 2,
        text: "Sure, how does 2 PM tomorrow sound?",
        sender: "Isaac",
        timestamp: "Apr 11, 2024, 10:15 AM",
      },
      {
        id: 3,
        text: "That works for me. Thank you, Isaac!",
        sender: "You",
        timestamp: "Apr 11, 2024, 10:17 AM",
      },
    ],
  },
  {
    id: 21,
    name: "Priscilla",
    lastMessage: "Your appointment has been confirmed for April 15th.",
    messages: [
      {
        id: 1,
        text: "I am confirming your appointment for April 15th at 10 AM.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:30 PM",
      },
      {
        id: 2,
        text: "Thank you for the confirmation, Priscilla.",
        sender: "You",
        timestamp: "Apr 9, 2024, 3:45 PM",
      },
      {
        id: 3,
        text: "You are welcome! Let us know if there is anything else you need.",
        sender: "Priscilla",
        timestamp: "Apr 9, 2024, 3:50 PM",
      },
    ],
  },
  // Add more chats as needed
];

type Props = {};

const MessagePage: FC<Props> = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedChatId, setSelectedChatId] = useUrlState("conversation_id");
  const [visible, setVisible] = useState(false);

  const isEmpty = true;

  // useEffect(() => {
  //   const handleEsc = (event: { key: string }) => {
  //     if (event.key === "Escape") {
  //       setSelectedChat(null);
  //       setSelectedChatId(-1);
  //     }
  //   };

  //   window.addEventListener("keydown", handleEsc);

  //   return () => {
  //     window.removeEventListener("keydown", handleEsc);
  //   };
  // }, []);

  // const handleSelectChat = (chat: Chat) => {
  //   setSelectedChat(chat);
  //   setSelectedChatId(chat.id);
  //   setVisible(true);
  // };

  // Filter chats based on search term
  // const filteredChats = sampleChats.filter(
  //   (chat) =>
  //     chat.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     chat.lastMessage.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const user = useCurrentUser();
  const token = useCurrentToken();

  // const navigate = useNavigate();
  const isLoggedIn = user && token;

  // if (!isLoggedIn) return navigate({ to: "/login" });
  if (!isLoggedIn) return <Navigate to="/login" replace />;

  return (
    <Wrapper>
      <div className="relative w-full h-full flex justify-start items-start md:pr-10">
        <div className="md:w-1/2 w-full border-r h-full  border-gray-300 overflow-scroll overflow-y-scroll no-scrollbar">
          <div className=" flex flex-col grow justify-start  mobile:mt-0 ">
            <div className="hidden w-full sticky top-0 bg-white  md:block">
              <div className="px-5 py-5 border-b border-gray-200 ">
                <h1 className="font-semibold text-lg text-black mb-3">
                  Messages
                </h1>

                <div className="w-full h-11 ring-1 ring-gray-300 rounded-full flex justify-between items-center pl-3 pr-2">
                  <input
                    type="search"
                    className="select-no-outline flex-1 border-none outline-none focus:border-none
                 focus:outline-none text-sm tracking-[0.1px] placeholder:text-gray-400 placeholder:font-medium "
                    placeholder="Search Messages"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button className="w-8 h-8 rounded-full bg-primary-500 flex justify-center items-center">
                    <FiSearch className="w-[18px] h-[18px] text-white" />
                  </button>
                </div>
              </div>
            </div>
            {!visible && (
              <div className="mobile:block hidden w-full sticky md:top-4 top-0 z-10 bg-white ">
                <div className="px-5 py-5 border-b border-gray-200 ">
                  <h1 className="font-semibold text-lg text-black mb-3">
                    Messages
                  </h1>

                  <div className="w-full h-11 ring-1 ring-gray-300 rounded-full flex justify-between items-center pl-3 pr-2">
                    <input
                      type="search"
                      className="select-no-outline flex-1 border-none outline-none focus:border-none focus:outline-none text-sm tracking-[0.1px] placeholder:text-gray-400 placeholder:font-medium "
                      placeholder="Search Messages"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                    />
                    <button className="w-8 h-8 rounded-full bg-primary-500 flex justify-center items-center">
                      <FiSearch className="w-[18px] h-[18px] text-white" />
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="md:hidden block ">
              {!_.isEmpty(selectedChatId) ? (
                <ChatDetails />
              ) : visible ? (
                <div className=" mt-5 px-5 absolute inset-0 w-full flex flex-col justify-between">
                  <div className="flex-1  flex justify-center items-center flex-col">
                    <h2 className="font-montserrat font-semibold text-base text-center text-black mb-2">
                      Choose a message
                    </h2>
                    <p className="text-center text-gray-600 text-sm">
                      Select from existing messages to continue chatting.
                    </p>
                  </div>
                  <div className="w-full rounded-xl ring-1 ring-gray-300 p-5 mb-14">
                    <img src={emailFooter} alt="email-footer" />

                    <div className="flex justify-start items-center w-full gap-3 mt-4 ">
                      <a href={FACEBOOK}>
                        <BiLogoFacebookCircle className="text-gray-400 w-7 h-7" />
                      </a>
                      <a href={TWITTER}>
                        <FaXTwitter className="text-gray-400 w-6 h-6" />
                      </a>
                      <a href={INSTAGRAM}>
                        <FaInstagram className="text-gray-400 w-6 h-6" />
                      </a>
                      <a href={LINKEDIN}>
                        <FaLinkedin className="text-gray-400 w-6 h-6" />
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <ChatList />
              )}
            </div>
            <div className="hidden md:block w-full  ">
              <ChatList />
            </div>
          </div>
        </div>
        {!_.isEmpty(selectedChatId) && (
          <div className="hidden md:block md:w-1/2 w-full border-r border-gray-300 h-full overflow-hidden">
            <ChatDetails />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default MessagePage;
