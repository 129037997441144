import React from "react";
import rafmoney from "@/assets/images/rafmoney.png";
import { Link } from "react-location";

const EmptyState = () => {
  return (
    <div className="flex flex-col  items-center">
      <img src={rafmoney} alt="company-logo" className="w-64 h-auto object-contain py-5 " />
      <h4 className="font-bold text-center text-black py-3">
        No jobs saved yet
      </h4>
      <p className="text-sm text-gray-400 text-center">
        Save jobs on the{" "}
        <Link className="text-primary underline font-medium" to={"/"}>
          Job Hunt page
        </Link>{" "}
        to filter jobs here
      </p>
    </div>
  );
};

export default EmptyState;
