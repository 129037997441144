import emailFooter from "@/assets/images/email-footer.png";
import logo from "../../assets/images/logo.png";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER } from "@/constants/socials";
import { BiArrowBack } from "react-icons/bi";
import { Navigate, useNavigate, useSearch } from "react-location";
import Wrapper from "@/components/wrapper";
import { LocationGenerics } from "@/router/location";

import { useQuery } from "@apollo/client";
import ImageLoader from "@/components/loaders/ImageLoader";
import moment from "moment";
import { GET_NOTIFICATION } from "@/apollo/graphql/queries/notification";
import {
  DEV_LANDING_URL,
  PROD_LANDING_URL,
  TEST_LANDING_URL,
} from "@/constants/page-path";
import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import {
  GetNotificationQuery,
  GetNotificationQueryVariables,
} from "@/apollo/graphql/generated/types";

const NotificationDetail = () => {
  const navigate = useNavigate();
  const searchParams = useSearch<LocationGenerics>();

  const { data, loading, error, refetch } = useQuery<
    GetNotificationQuery,
    GetNotificationQueryVariables
  >(GET_NOTIFICATION, {
    variables: {
      getNotificationId: searchParams.id ?? "",
    },
  });
  const notification = data?.getNotification;

  const token = useCurrentToken();
  const user = useCurrentUser();

  // const navigate = useNavigate();
  const isLoggedIn = user && token;

  // if (!isLoggedIn) return navigate({ to: "/login" });
  if (!isLoggedIn) return <Navigate to="/login" replace />;

  if (loading) return <ImageLoader />;

  return (
    <Wrapper>
      <main className="w-full flex justify-start items-start space-x-5 h-full lg:pr-10 md:pr-5">
        <div className=" bg-white border-r border-gray-200 w-full md:w-1/2 h-full ">
          <div className="font-montserrat flex flex-col grow overflow-y-auto justify-start ">
            <div className="sticky top-5 bg-white ">
              <div className="w-full flex justify-between items-center border-b border-[#D6D6D6] pb-4 px-5">
                <div className="flex items-center gap-x-4">
                  <BiArrowBack
                    onClick={() => navigate({ to: "/notifications" })}
                    className="w-6 h-6 text-black cursor-pointer"
                  />
                  <span className="font-montserrat font-semibold text-base text-black">
                    {notification?.title ?? "No Title"}
                  </span>
                </div>

                <p className="font-montserrat font-medium text-[10px] leading-5 text-gray-400">
                  {moment(
                    notification?.createdAt,
                    "YYYY-MM-DD HH:mm:ss"
                  ).fromNow()}
                </p>
              </div>
            </div>
            <div className="mt-16 mobile:mt-10 px-5">
              <div className="flex items-start gap-x-4">
                <div className="flex justify-center items-center w-[3.125rem] h-[3.125rem] rounded-full bg-gray-50 border border-gray-200 p-1">
                  <img
                    src={logo}
                    alt="notification logo"
                    className="object-contain"
                  />
                </div>
                <div className="flex-1">
                  <p className=" font-montserrat font-medium text-sm text-gray-500">
                    {/* <span className="font-semibold text-gray-900">Adjuma</span>{" "} */}
                    {notification?.body ?? "No Body"} <br />
                    <br />
                    At{" "}
                    <b>
                      {moment(notification?.createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" hidden md:block w-1/2  pt-5">
          <div className=" rounded-xl ring-1 ring-gray-300 p-3">
            <img src={emailFooter} alt="email-footer" />

            <div className="flex justify-start items-center w-full gap-3 mt-4 ">
              <a href={FACEBOOK}>
                <BiLogoFacebookCircle className="text-gray-400 w-7 h-7" />
              </a>
              <a href={TWITTER}>
                <FaXTwitter className="text-gray-400 w-6 h-6" />
              </a>
              <a href={INSTAGRAM}>
                <FaInstagram className="text-gray-400 w-6 h-6" />
              </a>
              <a href={LINKEDIN}>
                <FaLinkedin className="text-gray-400 w-6 h-6" />
              </a>
            </div>
          </div>

          <div className="flex flex-col gap-y-2 items-end py-5 mt-6">
            <img src={logo} alt="logo" className="object-contain h-5 w-20 " />

            <a
              href={
                import.meta.env.VITE_NODE_ENV === "development"
                  ? `${DEV_LANDING_URL}/contact`
                  : import.meta.env.VITE_NODE_ENV === "production"
                  ? `${PROD_LANDING_URL}/contact`
                  : import.meta.env.VITE_NODE_ENV === "test"
                  ? `${TEST_LANDING_URL}/contact`
                  : "/nolink"
              }
              target="_blank"
              className="font-medium text-sm text-gray-400"
            >
              Contact Support
            </a>

            <div className="flex justify-end items-center w-full gap-5">
              <a href={FACEBOOK}>
                <BiLogoFacebookCircle className="text-gray-400 w-5 h-5" />
              </a>
              <a href={TWITTER}>
                <FaXTwitter className="text-gray-400 w-4 h-4" />
              </a>
              <a href={INSTAGRAM}>
                <FaInstagram className="text-gray-400 w-4 h-4" />
              </a>
              <a href={LINKEDIN}>
                <FaLinkedin className="text-gray-400 w-4 h-4" />
              </a>
            </div>

            <p className="font-montserrat font-medium text-sm text-gray-400">
              &copy; Copyright {new Date().getFullYear()}, Adjuma.io
            </p>
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default NotificationDetail;
