import {
  BanknotesIcon,
  BriefcaseIcon,
  ClockIcon,
  MapPinIcon,
  MinusIcon,
} from "@heroicons/react/24/outline";
import { HiMiniCalendarDays } from "react-icons/hi2";
import { FC } from "react";
import LogoLize from "@/assets/images/logoLize.webp";
import moment from "moment";
import { getFormattedDate } from "@/helpers/date";

interface Job {
  id: string;
  title: string;
  location: string;
  postedDate: string;
  salary: {
    maximum: string;
    minimum: string;
    currency: string;
    frequency: string;
  };
  type: string;
  mode: string;
  description: string;
  likes: number;
  comments: number;
  shares: number;
  activelyHiring: boolean;
  status: string;
  about: string;
  company: {
    id: string;
    name: string;
    about: string;
    companyLogo: string;
  };
}

interface JobComponentProps {
  appliedJob: Job;
  appliedAt: string;
  candidateId: string;
  companyId: string;
  createdAt: string;
  id: string;
}

interface AppliedJobComponentProps {
  job: JobComponentProps;
  selected: boolean;
  onClick: () => void;
  handleWithdrawJob: (jobId: string) => void;
}

const AltJobComponent: FC<AppliedJobComponentProps> = ({
  job,
  selected,
  onClick,
  handleWithdrawJob,
}) => {
  return (
    <div
      onClick={onClick}
      className={` border  ${
        selected ? "border-primary" : "border-gray-300"
      } rounded-lg p-5 w-full cursor-pointer mb-5 `}
    >
      <div className="w-full">
        <div className="flex items-center justify-start gap-2">
          <div className="w-8 h-8 rounded">
            <img
              src={LogoLize}
              alt="company-logo"
              className="w-full h-full object-cover"
            />
          </div>

          <h1 className="text-base text-black font-semibold w-full truncate">
            {job?.appliedJob?.title ?? "N/A"}
          </h1>
        </div>

        <div className="flex justify-start gap-3 items-center text-xs font-medium text-gray-500 my-3">
          <p className="">{job?.appliedJob?.company?.name ?? "N/A"}</p>
          <span className="flex-1 flex justify-start items-center gap-1 truncate">
            <span>
              <MapPinIcon className="h-4 w-4" />
            </span>{" "}
            <span>{job?.appliedJob?.location ?? "N/A"}</span>
          </span>
        </div>
        <div className="flex justify-start gap-2 text-[10px] font-medium text-black my-3">
          <span className="bg-gray-100 px-2 flex gap-1 justify-start items-center py-1 rounded">
            <BanknotesIcon className="h-3 w-3" />{" "}
            {!job?.appliedJob?.salary ||
            job?.appliedJob?.salary?.maximum === "0" ? (
              "Negotiable"
            ) : (
              <>
                {job?.appliedJob?.salary?.currency ?? "N/A"}{" "}
                {job?.appliedJob?.salary?.minimum ?? "N/A"} -{" "}
                {job?.appliedJob?.salary?.maximum ?? "N/A"}{" "}
                {job?.appliedJob?.salary?.frequency ?? "N/A"}
              </>
            )}
          </span>
          <span className="bg-gray-100 px-2 flex gap-1 justify-start items-center py-1 rounded">
            {" "}
            <BriefcaseIcon className="h-3 w-3" />
            {job?.appliedJob?.type ?? "N/A"}
          </span>
          <span className="bg-gray-100 px-2 flex gap-1 justify-start items-center py-1 rounded">
            {" "}
            <BanknotesIcon className="h-3 w-3" />{" "}
            {job?.appliedJob?.mode ?? "N/A"}
          </span>
        </div>
        <div className="font-medium flex items-center justify-start gap-1 mt-5 pr-1 sm:mt-10 text-black text-sm">
          <h5 className="flex items-center justify-start  space-x-1">
            <HiMiniCalendarDays className="h-4 w-4 mr-2" /> Date Applied
          </h5>
          <div className="h-1 w-1 rounded-full bg-gray-500 " />
          <h6 className="text-primary-500 pl-1">
            {moment(job?.appliedAt).format("ll")}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default AltJobComponent;
