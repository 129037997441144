/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation } from "@apollo/client";
import { useCurrentUser } from "@/apollo/cache/auth";
import toast from "react-hot-toast";
import Modal from "@/components/layouts/modal";
import { IoCalendarClearOutline } from "react-icons/io5";
import { useFormik } from "formik";
import { parseISO, isValid } from "date-fns";

import ButtonLoader from "@/components/loaders/button";
import {
  ADD_EDUCATION,
  UPDATE_EDUCATION,
} from "@/apollo/graphql/mutations/profile";
import {
  AddEducationalBackgroundMutation,
  AddEducationalBackgroundMutationVariables,
  UpdateEducationalBackgroundMutation,
  UpdateEducationalBackgroundMutationVariables,
} from "@/apollo/graphql/generated/types";

//delete

const EducationModal = ({ visible, onClose, refetch, data }: any) => {
  const currentUser = useCurrentUser();
  const [loadingModal, setLoadingModal] = useState(false);
  const [currentStudying, setCurrentStudying] = useState(false);
  const startDatePickerRef = useRef<HTMLInputElement>(null);
  const endDatePickerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (data) {
      setCurrentStudying(data.isCurrentlyStudying);
      const start = data?.startDate
        ? new Date(data.startDate).toISOString().split("T")[0]
        : "";
      const end = data?.endDate
        ? new Date(data.endDate).toISOString().split("T")[0]
        : "";
      form.setValues({
        programme: data?.programme || "",
        school: data?.school || "",
        startDate: start,
        endDate: end,
      });
    } else {
      form.resetForm();
    }
  }, [data]);

  useEffect(() => {
    if (currentStudying) {
      form.setFieldValue("endDate", "");
    }
  }, [currentStudying]);

  const [addEdu, { loading }] = useMutation<
    AddEducationalBackgroundMutation,
    AddEducationalBackgroundMutationVariables
  >(ADD_EDUCATION);
  const [updateEdu, { loading: updateLoading }] = useMutation<
    UpdateEducationalBackgroundMutation,
    UpdateEducationalBackgroundMutationVariables
  >(UPDATE_EDUCATION);

  interface ProfessionalFormData {
    programme: string;
    school: string;
    startDate: string;
    endDate: string;
  }

  const form = useFormik({
    initialValues: {
      programme: "",
      school: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: async (values: ProfessionalFormData) => {
      const input = {
        programme: values.programme,
        endDate: currentStudying
          ? new Date().toISOString().split("T")[0]
          : values.endDate,
        school: values.school,
        startDate: values.startDate,
        isCurrentlyStudying: currentStudying,
      };

      if (data) {
        await updateEdu({
          variables: {
            input: {
              ...input,
              id: data?.id,
            },
          },
        })
          .then(() => {
            onClose();
            refetch();
            toast(
              JSON.stringify({
                type: "success",
                title: `Profile successfully updated`,
              })
            );
          })
          .catch((error) => {
            toast(
              JSON.stringify({
                type: "error",
                title: error?.message || "Error while updating Profile",
              })
            );
          });
      } else {
        await addEdu({
          variables: {
            input,
          },
        })
          .then(() => {
            form.resetForm();
            onClose();
            refetch();
            toast(
              JSON.stringify({
                type: "success",
                title: `Profile successfully updated`,
              })
            );
          })
          .catch((error) => {
            toast(
              JSON.stringify({
                type: "error",
                title: error?.message || "Error while updating Profile",
              })
            );
          });
      }
    },
  });

  if (!visible) return null;

  return (
    <Modal
      open={visible}
      setOpen={() => {
        onClose();
      }}
      loading={loadingModal}
      hideActions={false}
      description="Educational Background"
      renderActions={() => (
        <>
          <button
            disabled={loading || updateLoading}
            className="w-[8rem] h-[2.5rem] flex justify-center items-center font-montserrat font-normal text-sm capitalize leading-5 text-center bg-primary rounded-lg text-white border border-primary"
            type="button"
            onClick={() => {
              form.handleSubmit();
            }}
          >
            {loading || updateLoading ? (
              <ButtonLoader title="Saving..." />
            ) : (
              " Save changes"
            )}
          </button>
        </>
      )}
    >
      {currentUser?.id ? (
        <>
          <div className="relative bg-white mt-3">
            <div className="w-full">
              <div className="mb-3">
                <label
                  className="font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.006rem]"
                  htmlFor="program"
                >
                  Program
                </label>

                <input
                  id="programme"
                  name="programme"
                  type="text"
                  onChange={form.handleChange}
                  value={form.values?.programme || ""}
                  className="input-base"
                  placeholder="Enter your program"
                />
              </div>
              <div className="mb-3">
                <label
                  className="font-montserrat text-xs font-[400] text-black-2 capitalize leading-[0.9rem] tracking-[0.006rem]"
                  htmlFor="school"
                >
                  School
                </label>

                <input
                  id="school"
                  name="school"
                  type="text"
                  onChange={form.handleChange}
                  value={form.values?.school || ""}
                  className="input-base"
                  placeholder="Enter your school"
                />
              </div>

              <div className="mb-3 flex mobile:flex-col mobile:gap-4 justify-between items-center gap-x-6">
                <div className="w-full flex-1">
                  <label
                    className="w-full font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.006rem]"
                    htmlFor="startDate"
                  >
                    Start Date
                  </label>

                  <div className="flex items-center justify-between input-base">
                    <input
                      type="date"
                      name="startDate"
                      id="startDate"
                      value={form.values.startDate}
                      onChange={form.handleChange}
                      className="select-no-outline border-none cursor-pointer outline-none w-full text-xs leading-[0.9rem] tracking-[0.006rem]"
                      ref={startDatePickerRef}
                    />
                    {/* <IoCalendarClearOutline
                      onClick={() => startDatePickerRef.current?.setFocus()}
                      className="text-gray-400 w-5 h-5 cursor-pointer"
                    /> */}
                  </div>
                </div>

                <div className="w-full flex-1">
                  <label
                    className="w-full font-montserrat text-xs text-black capitalize leading-[0.9rem] tracking-[0.006rem]"
                    htmlFor="endDate"
                  >
                    End Date
                  </label>

                  <div className="flex items-center justify-between flex-1 input-base ">
                    <input
                      disabled={currentStudying}
                      type="date"
                      name="endDate"
                      id="endDate"
                      value={form.values.endDate}
                      onChange={form.handleChange}
                      className="select-no-outline border-none cursor-pointer outline-none w-full text-xs leading-[0.9rem] tracking-[0.006rem]"
                      ref={endDatePickerRef}
                    />
                    {/* <IoCalendarClearOutline
                      onClick={() => endDatePickerRef.current?.setFocus()}
                      className=" text-gray-400 w-5 h-5 cursor-pointer"
                    /> */}
                  </div>
                </div>
              </div>

              <div className="flex items-center mt-3 mb-5">
                <input
                  onChange={() => {
                    setCurrentStudying(!currentStudying);
                  }}
                  type="checkbox"
                  checked={currentStudying}
                  required
                  className="peer mr-2 cursor-pointer text-primary border border-gray-500 focus:ring-0 rounded"
                />
                <label
                  className="text-gray-800 text-sm  font-medium"
                  htmlFor="remember"
                >
                  I am currently studying here
                </label>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex-1 w-full max-h-[65vh] overflow-y-auto sm:p-6">
          <div>
            <h1>NO DATA</h1>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default EducationModal;
