import packageJson from "./../../package.json";

// if (import.meta.env.VITE_NODE_ENV === "production") {
//   const variables = [
//     "VITE_PROD_API_URL",
//     // "REACT_APP_SENTRY_DSN"
//   ];
//   for (let variable of variables) {
//     if (!process.env[variable]) {
//       throw new Error(`Kindly Provide Variable ${variable} In Env`);
//     }
//   }
// }..

export const BASE_URL =
  import.meta.env.VITE_NODE_ENV === "development"
    ? import.meta.env.VITE_DEV_API_URL
    : import.meta.env.VITE_NODE_ENV === "test"
    ? import.meta.env.VITE_TEST_API_URL
    : import.meta.env.VITE_NODE_ENV === "production"
    ? import.meta.env.VITE_PROD_API_URL
    : null;

interface Config {
  env: "production" | "staging" | "sandbox" | "test" | "development";
  name: string;
  asset: {
    uri: string;
  };
  message: {
    uri: string;
    ws: string;
  };
  apollo: {
    uri: string;
    name: string;
    version: string;
  };
  sentry: {
    dsn: string;
    env: "production" | "staging" | "sandbox" | "test";
  };
  cookies: {
    domain: string;
    secure: boolean;
    sameSite: "strict" | "Strict" | "lax" | "Lax" | "none" | "None";
  };
  googlePlaces: {
    api_key: string;
  };

  constants: {
    page: number;
    pageSize: number;
    dateFormat: string;
  };
}

const config: Config = {
  env: (import.meta.env.VITE_NODE_ENV as any) ?? "development",

  name: packageJson.name,
  asset: {
    uri: `${BASE_URL}/assets/files`,
  },
  message: {
    // uri: import.meta.env.VITE_DEV_MESSAGE_URL,
    // ws: import.meta.env.VITE_MESSAGE_WS,
    uri:
      import.meta.env.VITE_NODE_ENV === "production"
        ? import.meta.env.VITE_PROD_MESSAGE_URL
        : import.meta.env.VITE_DEV_MESSAGE_URL,
    ws:
      import.meta.env.VITE_NODE_ENV === "production"
        ? import.meta.env.VITE_PROD_MESSAGE_WS
        : import.meta.env.VITE_MESSAGE_WS,
  },
  apollo: {
    uri: `${BASE_URL}`,
    name: packageJson.name,
    version: packageJson.version,
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN ?? "",
    env: import.meta.env.VITE_NODE_ENV as any,
  },
  googlePlaces: {
    api_key: import.meta.env.VITE_GOOGLE_PLACES_API_KEY,
  },
  cookies: {
    domain: window.location.hostname?.split(".")?.slice(1)?.join("."),
    secure: true,
    sameSite: "strict",
  },

  constants: {
    page: 1,
    pageSize: 10,
    dateFormat: "",
  },
};

export default config;
